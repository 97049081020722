window.ITALIAN_CITIES_BY_PROVINCE = {
  TO: [
    ['Agliè', 'A074'],
    ['Airasca', 'A109'],
    ['Ala di Stura', 'A117'],
    ["Albiano d'Ivrea", 'A157'],
    ['Almese', 'A218'],
    ['Alpette', 'A221'],
    ['Alpignano', 'A222'],
    ['Andezeno', 'A275'],
    ['Andrate', 'A282'],
    ['Angrogna', 'A295'],
    ['Arignano', 'A405'],
    ['Avigliana', 'A518'],
    ['Azeglio', 'A525'],
    ['Bairo', 'A584'],
    ['Balangero', 'A587'],
    ['Baldissero Canavese', 'A590'],
    ['Baldissero Torinese', 'A591'],
    ['Balme', 'A599'],
    ['Banchette', 'A607'],
    ['Barbania', 'A625'],
    ['Bardonecchia', 'A651'],
    ['Barone Canavese', 'A673'],
    ['Beinasco', 'A734'],
    ['Bibiana', 'A853'],
    ['Bobbio Pellice', 'A910'],
    ['Bollengo', 'A941'],
    ['Borgaro Torinese', 'A990'],
    ['Borgiallo', 'B003'],
    ["Borgofranco d'Ivrea", 'B015'],
    ['Borgomasino', 'B021'],
    ['Borgone Susa', 'B024'],
    ['Bosconero', 'B075'],
    ['Brandizzo', 'B121'],
    ['Bricherasio', 'B171'],
    ['Brosso', 'B205'],
    ['Brozolo', 'B209'],
    ['Bruino', 'B216'],
    ['Brusasco', 'B225'],
    ['Bruzolo', 'B232'],
    ['Buriasco', 'B278'],
    ['Burolo', 'B279'],
    ['Busano', 'B284'],
    ['Bussoleno', 'B297'],
    ['Buttigliera Alta', 'B305'],
    ['Cafasse', 'B350'],
    ['Caluso', 'B435'],
    ['Cambiano', 'B462'],
    ['Campiglione Fenile', 'B512'],
    ['Candia Canavese', 'B588'],
    ['Candiolo', 'B592'],
    ['Canischio', 'B605'],
    ['Cantalupa', 'B628'],
    ['Cantoira', 'B637'],
    ['Caprie', 'B705'],
    ['Caravino', 'B733'],
    ['Carema', 'B762'],
    ['Carignano', 'B777'],
    ['Carmagnola', 'B791'],
    ['Casalborgone', 'B867'],
    ["Cascinette d'Ivrea", 'B953'],
    ['Caselette', 'B955'],
    ['Caselle Torinese', 'B960'],
    ['Castagneto Po', 'C045'],
    ['Castagnole Piemonte', 'C048'],
    ['Castellamonte', 'C133'],
    ['Castelnuovo Nigra', 'C241'],
    ['Castiglione Torinese', 'C307'],
    ['Cavagnolo', 'C369'],
    ['Cavour', 'C404'],
    ['Cercenasco', 'C487'],
    ['Ceres', 'C497'],
    ['Ceresole Reale', 'C505'],
    ['Cesana Torinese', 'C564'],
    ['Chialamberto', 'C604'],
    ['Chianocco', 'C610'],
    ['Chiaverano', 'C624'],
    ['Chieri', 'C627'],
    ['Chiesanuova', 'C629'],
    ['Chiomonte', 'C639'],
    ['Chiusa di San Michele', 'C655'],
    ['Chivasso', 'C665'],
    ['Ciconio', 'C679'],
    ['Cintano', 'C711'],
    ['Cinzano', 'C715'],
    ['Ciriè', 'C722'],
    ['Claviere', 'C793'],
    ['Coassolo Torinese', 'C801'],
    ['Coazze', 'C803'],
    ['Collegno', 'C860'],
    ['Colleretto Castelnuovo', 'C867'],
    ['Colleretto Giacosa', 'C868'],
    ['Condove', 'C955'],
    ['Corio', 'D008'],
    ['Cossano Canavese', 'D092'],
    ['Cuceglio', 'D197'],
    ['Cumiana', 'D202'],
    ['Cuorgnè', 'D208'],
    ['Druento', 'D373'],
    ['Exilles', 'D433'],
    ['Favria', 'D520'],
    ['Feletto', 'D524'],
    ['Fenestrelle', 'D532'],
    ['Fiano', 'D562'],
    ['Fiorano Canavese', 'D608'],
    ['Foglizzo', 'D646'],
    ['Forno Canavese', 'D725'],
    ['Frassinetto', 'D781'],
    ['Front', 'D805'],
    ['Frossasco', 'D812'],
    ['Garzigliana', 'D931'],
    ['Gassino Torinese', 'D933'],
    ['Germagnano', 'D983'],
    ['Giaglione', 'E009'],
    ['Giaveno', 'E020'],
    ['Givoletto', 'E067'],
    ['Gravere', 'E154'],
    ['Groscavallo', 'E199'],
    ['Grosso', 'E203'],
    ['Grugliasco', 'E216'],
    ['Ingria', 'E301'],
    ['Inverso Pinasca', 'E311'],
    ['Isolabella', 'E345'],
    ['Issiglio', 'E368'],
    ['Ivrea', 'E379'],
    ['La Cassa', 'E394'],
    ['La Loggia', 'E423'],
    ['Lanzo Torinese', 'E445'],
    ['Lauriano', 'E484'],
    ['Leini', 'E518'],
    ['Lemie', 'E520'],
    ['Lessolo', 'E551'],
    ['Levone', 'E566'],
    ['Locana', 'E635'],
    ['Lombardore', 'E660'],
    ['Lombriasco', 'E661'],
    ['Loranzè', 'E683'],
    ['Luserna San Giovanni', 'E758'],
    ['Lusernetta', 'E759'],
    ['Lusigliè', 'E763'],
    ['Macello', 'E782'],
    ['Maglione', 'E817'],
    ['Marentino', 'E941'],
    ['Massello', 'F041'],
    ['Mathi', 'F053'],
    ['Mattie', 'F058'],
    ['Mazzè', 'F067'],
    ['Meana di Susa', 'F074'],
    ['Mercenasco', 'F140'],
    ['Mezzenile', 'F182'],
    ['Mombello di Torino', 'F315'],
    ['Mompantero', 'F318'],
    ['Monastero di Lanzo', 'F327'],
    ['Moncalieri', 'F335'],
    ['Moncenisio', 'D553'],
    ['Montaldo Torinese', 'F407'],
    ['Montalenghe', 'F411'],
    ['Montalto Dora', 'F420'],
    ['Montanaro', 'F422'],
    ['Monteu da Po', 'F651'],
    ['Moriondo Torinese', 'F733'],
    ['Nichelino', 'F889'],
    ['Noasca', 'F906'],
    ['Nole', 'F925'],
    ['Nomaglio', 'F927'],
    ['None', 'F931'],
    ['Novalesa', 'F948'],
    ['Oglianico', 'G010'],
    ['Orbassano', 'G087'],
    ['Orio Canavese', 'G109'],
    ['Osasco', 'G151'],
    ['Osasio', 'G152'],
    ['Oulx', 'G196'],
    ['Ozegna', 'G202'],
    ['Palazzo Canavese', 'G262'],
    ['Pancalieri', 'G303'],
    ['Parella', 'G330'],
    ['Pavarolo', 'G387'],
    ['Pavone Canavese', 'G392'],
    ['Pecetto Torinese', 'G398'],
    ['Perosa Argentina', 'G463'],
    ['Perosa Canavese', 'G462'],
    ['Perrero', 'G465'],
    ['Pertusio', 'G477'],
    ['Pessinetto', 'G505'],
    ['Pianezza', 'G559'],
    ['Pinasca', 'G672'],
    ['Pinerolo', 'G674'],
    ['Pino Torinese', 'G678'],
    ['Piobesi Torinese', 'G684'],
    ['Piossasco', 'G691'],
    ['Piscina', 'G705'],
    ['Piverone', 'G719'],
    ['Poirino', 'G777'],
    ['Pomaretto', 'G805'],
    ['Pont-Canavese', 'G826'],
    ['Porte', 'G900'],
    ['Pragelato', 'G973'],
    ['Prali', 'G978'],
    ['Pralormo', 'G979'],
    ['Pramollo', 'G982'],
    ['Prarostino', 'G986'],
    ['Prascorsano', 'G988'],
    ['Pratiglione', 'G997'],
    ['Quagliuzzo', 'H100'],
    ['Quassolo', 'H120'],
    ['Quincinetto', 'H127'],
    ['Reano', 'H207'],
    ['Ribordone', 'H270'],
    ['Rivalba', 'H333'],
    ['Rivalta di Torino', 'H335'],
    ['Riva presso Chieri', 'H337'],
    ['Rivara', 'H338'],
    ['Rivarolo Canavese', 'H340'],
    ['Rivarossa', 'H344'],
    ['Rivoli', 'H355'],
    ['Robassomero', 'H367'],
    ['Rocca Canavese', 'H386'],
    ['Roletto', 'H498'],
    ['Romano Canavese', 'H511'],
    ['Ronco Canavese', 'H539'],
    ['Rondissone', 'H547'],
    ['Rorà', 'H554'],
    ['Roure', 'H555'],
    ['Rosta', 'H583'],
    ['Rubiana', 'H627'],
    ['Rueglio', 'H631'],
    ['Salassa', 'H691'],
    ['Salbertrand', 'H684'],
    ['Salerano Canavese', 'H702'],
    ['Salza di Pinerolo', 'H734'],
    ['Samone', 'H753'],
    ['San Benigno Canavese', 'H775'],
    ['San Carlo Canavese', 'H789'],
    ['San Colombano Belmonte', 'H804'],
    ['San Didero', 'H820'],
    ['San Francesco al Campo', 'H847'],
    ['Sangano', 'H855'],
    ['San Germano Chisone', 'H862'],
    ['San Gillio', 'H873'],
    ['San Giorgio Canavese', 'H890'],
    ['San Giorio di Susa', 'H900'],
    ['San Giusto Canavese', 'H936'],
    ['San Martino Canavese', 'H997'],
    ['San Maurizio Canavese', 'I024'],
    ['San Mauro Torinese', 'I030'],
    ['San Pietro Val Lemina', 'I090'],
    ['San Ponso', 'I126'],
    ['San Raffaele Cimena', 'I137'],
    ['San Sebastiano da Po', 'I152'],
    ['San Secondo di Pinerolo', 'I154'],
    ["Sant'Ambrogio di Torino", 'I258'],
    ["Sant'Antonino di Susa", 'I296'],
    ['Santena', 'I327'],
    ['Sauze di Cesana', 'I465'],
    ["Sauze d'Oulx", 'I466'],
    ['Scalenghe', 'I490'],
    ['Scarmagno', 'I511'],
    ['Sciolze', 'I539'],
    ['Sestriere', 'I692'],
    ['Settimo Rottaro', 'I701'],
    ['Settimo Torinese', 'I703'],
    ['Settimo Vittone', 'I702'],
    ['Sparone', 'I886'],
    ['Strambinello', 'I969'],
    ['Strambino', 'I970'],
    ['Susa', 'L013'],
    ['Tavagnasco', 'L066'],
    ['Torino', 'L219'],
    ['Torrazza Piemonte', 'L238'],
    ['Torre Canavese', 'L247'],
    ['Torre Pellice', 'L277'],
    ['Trana', 'L327'],
    ['Traversella', 'L345'],
    ['Traves', 'L340'],
    ['Trofarello', 'L445'],
    ['Usseaux', 'L515'],
    ['Usseglio', 'L516'],
    ['Vaie', 'L538'],
    ['Val della Torre', 'L555'],
    ['Valgioie', 'L578'],
    ['Vallo Torinese', 'L629'],
    ['Valperga', 'L644'],
    ['Valprato Soana', 'B510'],
    ['Varisella', 'L685'],
    ['Vauda Canavese', 'L698'],
    ['Venaus', 'L726'],
    ['Venaria Reale', 'L727'],
    ['Verolengo', 'L779'],
    ['Verrua Savoia', 'L787'],
    ['Vestignè', 'L811'],
    ['Vialfrè', 'L830'],
    ['Vidracco', 'L857'],
    ['Vigone', 'L898'],
    ['Villafranca Piemonte', 'L948'],
    ['Villanova Canavese', 'L982'],
    ['Villarbasse', 'M002'],
    ['Villar Dora', 'L999'],
    ['Villareggia', 'M004'],
    ['Villar Focchiardo', 'M007'],
    ['Villar Pellice', 'M013'],
    ['Villar Perosa', 'M014'],
    ['Villastellone', 'M027'],
    ['Vinovo', 'M060'],
    ['Virle Piemonte', 'M069'],
    ['Vische', 'M071'],
    ['Vistrorio', 'M080'],
    ['Viù', 'M094'],
    ['Volpiano', 'M122'],
    ['Volvera', 'M133'],
    ['Mappano', 'M316'],
    ['Val di Chy', 'M405'],
    ['Valchiusa', 'M415'],
  ],
  VC: [
    ['Alagna Valsesia', 'A119'],
    ['Albano Vercellese', 'A130'],
    ['Alice Castello', 'A198'],
    ['Arborio', 'A358'],
    ['Asigliano Vercellese', 'A466'],
    ['Balmuccia', 'A600'],
    ['Balocco', 'A601'],
    ['Bianzè', 'A847'],
    ['Boccioleto', 'A914'],
    ["Borgo d'Ale", 'B009'],
    ['Borgosesia', 'B041'],
    ['Borgo Vercelli', 'B046'],
    ['Buronzo', 'B280'],
    ['Campertogno', 'B505'],
    ['Carcoforo', 'B752'],
    ['Caresana', 'B767'],
    ['Caresanablot', 'B768'],
    ['Carisio', 'B782'],
    ['Casanova Elvo', 'B928'],
    ['San Giacomo Vercellese', 'B952'],
    ['Cervatto', 'C548'],
    ['Cigliano', 'C680'],
    ['Civiasco', 'C757'],
    ['Collobiano', 'C884'],
    ['Costanzana', 'D113'],
    ['Cravagliana', 'D132'],
    ['Crescentino', 'D154'],
    ['Crova', 'D187'],
    ['Desana', 'D281'],
    ['Fobello', 'D641'],
    ['Fontanetto Po', 'D676'],
    ['Formigliana', 'D712'],
    ['Gattinara', 'D938'],
    ['Ghislarengo', 'E007'],
    ['Greggio', 'E163'],
    ['Guardabosone', 'E237'],
    ['Lamporo', 'E433'],
    ['Lenta', 'E528'],
    ['Lignana', 'E583'],
    ['Livorno Ferraris', 'E626'],
    ['Lozzolo', 'E711'],
    ['Mollia', 'F297'],
    ['Moncrivello', 'F342'],
    ["Motta de' Conti", 'F774'],
    ['Olcenengo', 'G016'],
    ['Oldenico', 'G018'],
    ['Palazzolo Vercellese', 'G266'],
    ['Pertengo', 'G471'],
    ['Pezzana', 'G528'],
    ['Pila', 'G666'],
    ['Piode', 'G685'],
    ['Postua', 'G940'],
    ['Prarolo', 'G985'],
    ['Quarona', 'H108'],
    ['Quinto Vercellese', 'H132'],
    ['Rassa', 'H188'],
    ['Rimella', 'H293'],
    ['Rive', 'H346'],
    ['Roasio', 'H365'],
    ['Ronsecco', 'H549'],
    ['Rossa', 'H577'],
    ['Rovasenda', 'H364'],
    ['Salasco', 'H690'],
    ['Sali Vercellese', 'H707'],
    ['Saluggia', 'H725'],
    ['San Germano Vercellese', 'H861'],
    ['Santhià', 'I337'],
    ['Scopa', 'I544'],
    ['Scopello', 'I545'],
    ['Serravalle Sesia', 'I663'],
    ['Stroppiana', 'I984'],
    ['Tricerro', 'L420'],
    ['Trino', 'L429'],
    ['Tronzano Vercellese', 'L451'],
    ['Valduggia', 'L566'],
    ['Varallo', 'L669'],
    ['Vercelli', 'L750'],
    ['Villarboit', 'M003'],
    ['Villata', 'M028'],
    ['Vocca', 'M106'],
    ['Alto Sermenza', 'M389'],
    ['Cellio con Breia', 'M398'],
  ],
  NO: [
    ['Agrate Conturbia', 'A088'],
    ['Ameno', 'A264'],
    ['Armeno', 'A414'],
    ['Arona', 'A429'],
    ['Barengo', 'A653'],
    ['Bellinzago Novarese', 'A752'],
    ['Biandrate', 'A844'],
    ['Boca', 'A911'],
    ['Bogogno', 'A929'],
    ['Bolzano Novarese', 'A953'],
    ['Borgolavezzaro', 'B016'],
    ['Borgomanero', 'B019'],
    ['Borgo Ticino', 'B043'],
    ['Briga Novarese', 'B176'],
    ['Briona', 'B183'],
    ['Caltignaga', 'B431'],
    ['Cameri', 'B473'],
    ['Carpignano Sesia', 'B823'],
    ['Casalbeltrame', 'B864'],
    ['Casaleggio Novara', 'B883'],
    ['Casalino', 'B897'],
    ['Casalvolone', 'B920'],
    ['Castellazzo Novarese', 'C149'],
    ['Castelletto sopra Ticino', 'C166'],
    ['Cavaglietto', 'C364'],
    ["Cavaglio d'Agogna", 'C365'],
    ['Cavallirio', 'C378'],
    ['Cerano', 'C483'],
    ['Colazza', 'C829'],
    ['Comignago', 'C926'],
    ['Cressa', 'D162'],
    ['Cureggio', 'D216'],
    ['Divignano', 'D309'],
    ['Dormelletto', 'D347'],
    ['Fara Novarese', 'D492'],
    ["Fontaneto d'Agogna", 'D675'],
    ['Galliate', 'D872'],
    ['Garbagna Novarese', 'D911'],
    ['Gargallo', 'D921'],
    ['Ghemme', 'E001'],
    ['Gozzano', 'E120'],
    ['Granozzo con Monticello', 'E143'],
    ['Grignasco', 'E177'],
    ['Invorio', 'E314'],
    ['Landiona', 'E436'],
    ['Lesa', 'E544'],
    ['Maggiora', 'E803'],
    ['Mandello Vitta', 'E880'],
    ['Marano Ticino', 'E907'],
    ['Massino Visconti', 'F047'],
    ['Meina', 'F093'],
    ['Mezzomerico', 'F188'],
    ['Miasino', 'F191'],
    ['Momo', 'F317'],
    ['Nebbiuno', 'F859'],
    ['Nibbiola', 'F886'],
    ['Novara', 'F952'],
    ['Oleggio', 'G019'],
    ['Oleggio Castello', 'G020'],
    ['Orta San Giulio', 'G134'],
    ['Paruzzaro', 'G349'],
    ['Pella', 'G421'],
    ['Pettenasco', 'G520'],
    ['Pisano', 'G703'],
    ['Pogno', 'G775'],
    ['Pombia', 'G809'],
    ['Prato Sesia', 'H001'],
    ['Recetto', 'H213'],
    ['Romagnano Sesia', 'H502'],
    ['Romentino', 'H518'],
    ["San Maurizio d'Opaglio", 'I025'],
    ['San Nazzaro Sesia', 'I052'],
    ['San Pietro Mosezzo', 'I116'],
    ['Sillavengo', 'I736'],
    ['Sizzano', 'I767'],
    ['Soriso', 'I857'],
    ['Sozzago', 'I880'],
    ['Suno', 'L007'],
    ['Terdobbiate', 'L104'],
    ['Tornaco', 'L223'],
    ['Trecate', 'L356'],
    ["Vaprio d'Agogna", 'L668'],
    ['Varallo Pombia', 'L670'],
    ['Vespolate', 'L808'],
    ['Vicolungo', 'L847'],
    ['Vinzaglio', 'M062'],
    ['Gattico-Veruno', 'M416'],
  ],
  CN: [
    ['Acceglio', 'A016'],
    ['Aisone', 'A113'],
    ['Alba', 'A124'],
    ['Albaretto della Torre', 'A139'],
    ['Alto', 'A238'],
    ['Argentera', 'A394'],
    ['Arguello', 'A396'],
    ['Bagnasco', 'A555'],
    ['Bagnolo Piemonte', 'A571'],
    ["Baldissero d'Alba", 'A589'],
    ['Barbaresco', 'A629'],
    ['Barge', 'A660'],
    ['Barolo', 'A671'],
    ['Bastia Mondovì', 'A709'],
    ['Battifollo', 'A716'],
    ['Beinette', 'A735'],
    ['Bellino', 'A750'],
    ['Belvedere Langhe', 'A774'],
    ['Bene Vagienna', 'A779'],
    ['Benevello', 'A782'],
    ['Bergolo', 'A798'],
    ['Bernezzo', 'A805'],
    ['Bonvicino', 'A979'],
    ['Borgomale', 'B018'],
    ['Borgo San Dalmazzo', 'B033'],
    ['Bosia', 'B079'],
    ['Bossolasco', 'B084'],
    ['Boves', 'B101'],
    ['Bra', 'B111'],
    ['Briaglia', 'B167'],
    ['Briga Alta', 'B175'],
    ['Brondello', 'B200'],
    ['Brossasco', 'B204'],
    ['Busca', 'B285'],
    ['Camerana', 'B467'],
    ['Canale', 'B573'],
    ['Canosio', 'B621'],
    ['Caprauna', 'B692'],
    ['Caraglio', 'B719'],
    ['Caramagna Piemonte', 'B720'],
    ['Cardè', 'B755'],
    ['Carrù', 'B841'],
    ['Cartignano', 'B845'],
    ['Casalgrasso', 'B894'],
    ['Castagnito', 'C046'],
    ['Casteldelfino', 'C081'],
    ['Castelletto Stura', 'C165'],
    ['Castelletto Uzzone', 'C167'],
    ["Castellinaldo d'Alba", 'C173'],
    ['Castellino Tanaro', 'C176'],
    ['Castelmagno', 'C205'],
    ['Castelnuovo di Ceva', 'C214'],
    ['Castiglione Falletto', 'C314'],
    ['Castiglione Tinella', 'C317'],
    ['Castino', 'C323'],
    ['Cavallerleone', 'C375'],
    ['Cavallermaggiore', 'C376'],
    ['Celle di Macra', 'C441'],
    ['Centallo', 'C466'],
    ['Ceresole Alba', 'C504'],
    ['Cerretto Langhe', 'C530'],
    ['Cervasca', 'C547'],
    ['Cervere', 'C550'],
    ['Ceva', 'C589'],
    ['Cherasco', 'C599'],
    ['Chiusa di Pesio', 'C653'],
    ['Cigliè', 'C681'],
    ['Cissone', 'C738'],
    ['Clavesana', 'C792'],
    ["Corneliano d'Alba", 'D022'],
    ['Cortemilia', 'D062'],
    ['Cossano Belbo', 'D093'],
    ['Costigliole Saluzzo', 'D120'],
    ['Cravanzana', 'D133'],
    ['Crissolo', 'D172'],
    ['Cuneo', 'D205'],
    ['Demonte', 'D271'],
    ["Diano d'Alba", 'D291'],
    ['Dogliani', 'D314'],
    ['Dronero', 'D372'],
    ['Elva', 'D401'],
    ['Entracque', 'D410'],
    ['Envie', 'D412'],
    ['Farigliano', 'D499'],
    ['Faule', 'D511'],
    ['Feisoglio', 'D523'],
    ['Fossano', 'D742'],
    ['Frabosa Soprana', 'D751'],
    ['Frabosa Sottana', 'D752'],
    ['Frassino', 'D782'],
    ['Gaiola', 'D856'],
    ['Gambasca', 'D894'],
    ['Garessio', 'D920'],
    ['Genola', 'D967'],
    ['Gorzegno', 'E111'],
    ['Gottasecca', 'E115'],
    ['Govone', 'E118'],
    ['Grinzane Cavour', 'E182'],
    ['Guarene', 'E251'],
    ['Igliano', 'E282'],
    ['Isasca', 'E327'],
    ['Lagnasco', 'E406'],
    ['La Morra', 'E430'],
    ['Lequio Berria', 'E540'],
    ['Lequio Tanaro', 'E539'],
    ['Lesegno', 'E546'],
    ['Levice', 'E564'],
    ['Limone Piemonte', 'E597'],
    ['Lisio', 'E615'],
    ['Macra', 'E789'],
    ['Magliano Alfieri', 'E809'],
    ['Magliano Alpi', 'E808'],
    ['Mango', 'E887'],
    ['Manta', 'E894'],
    ['Marene', 'E939'],
    ['Margarita', 'E945'],
    ['Marmora', 'E963'],
    ['Marsaglia', 'E973'],
    ['Martiniana Po', 'E988'],
    ['Melle', 'F114'],
    ['Moiola', 'F279'],
    ['Mombarcaro', 'F309'],
    ['Mombasiglio', 'F312'],
    ['Monastero di Vasco', 'F326'],
    ['Monasterolo Casotto', 'F329'],
    ['Monasterolo di Savigliano', 'F330'],
    ['Monchiero', 'F338'],
    ['Mondovì', 'F351'],
    ['Monesiglio', 'F355'],
    ["Monforte d'Alba", 'F358'],
    ['Montà', 'F385'],
    ['Montaldo di Mondovì', 'F405'],
    ['Montaldo Roero', 'F408'],
    ['Montanera', 'F424'],
    ['Montelupo Albese', 'F550'],
    ['Montemale di Cuneo', 'F558'],
    ['Monterosso Grana', 'F608'],
    ['Monteu Roero', 'F654'],
    ['Montezemolo', 'F666'],
    ["Monticello d'Alba", 'F669'],
    ['Moretta', 'F723'],
    ['Morozzo', 'F743'],
    ['Murazzano', 'F809'],
    ['Murello', 'F811'],
    ['Narzole', 'F846'],
    ['Neive', 'F863'],
    ['Neviglie', 'F883'],
    ['Niella Belbo', 'F894'],
    ['Niella Tanaro', 'F895'],
    ['Novello', 'F961'],
    ['Nucetto', 'F972'],
    ['Oncino', 'G066'],
    ['Ormea', 'G114'],
    ['Ostana', 'G183'],
    ['Paesana', 'G228'],
    ['Pagno', 'G240'],
    ['Pamparato', 'G302'],
    ['Paroldo', 'G339'],
    ['Perletto', 'G457'],
    ['Perlo', 'G458'],
    ['Peveragno', 'G526'],
    ['Pezzolo Valle Uzzone', 'G532'],
    ['Pianfei', 'G561'],
    ['Piasco', 'G575'],
    ['Pietraporzio', 'G625'],
    ["Piobesi d'Alba", 'G683'],
    ['Piozzo', 'G697'],
    ['Pocapaglia', 'G742'],
    ['Polonghera', 'G800'],
    ['Pontechianale', 'G837'],
    ['Pradleves', 'G970'],
    ['Prazzo', 'H011'],
    ['Priero', 'H059'],
    ['Priocca', 'H068'],
    ['Priola', 'H069'],
    ['Prunetto', 'H085'],
    ['Racconigi', 'H150'],
    ['Revello', 'H247'],
    ['Rifreddo', 'H285'],
    ['Rittana', 'H326'],
    ['Roaschia', 'H362'],
    ['Roascio', 'H363'],
    ['Robilante', 'H377'],
    ['Roburent', 'H378'],
    ['Roccabruna', 'H385'],
    ['Rocca Cigliè', 'H391'],
    ["Rocca de' Baldi", 'H395'],
    ['Roccaforte Mondovì', 'H407'],
    ['Roccasparvera', 'H447'],
    ['Roccavione', 'H453'],
    ['Rocchetta Belbo', 'H462'],
    ['Roddi', 'H472'],
    ['Roddino', 'H473'],
    ['Rodello', 'H474'],
    ['Rossana', 'H578'],
    ['Ruffia', 'H633'],
    ['Sale delle Langhe', 'H695'],
    ['Sale San Giovanni', 'H704'],
    ['Saliceto', 'H710'],
    ['Salmour', 'H716'],
    ['Saluzzo', 'H727'],
    ['Sambuco', 'H746'],
    ['Sampeyre', 'H755'],
    ['San Benedetto Belbo', 'H770'],
    ['San Damiano Macra', 'H812'],
    ['Sanfrè', 'H851'],
    ['Sanfront', 'H852'],
    ['San Michele Mondovì', 'I037'],
    ["Sant'Albano Stura", 'I210'],
    ["Santa Vittoria d'Alba", 'I316'],
    ['Santo Stefano Belbo', 'I367'],
    ['Santo Stefano Roero', 'I372'],
    ['Savigliano', 'I470'],
    ['Scagnello', 'I484'],
    ['Scarnafigi', 'I512'],
    ["Serralunga d'Alba", 'I646'],
    ['Serravalle Langhe', 'I659'],
    ['Sinio', 'I750'],
    ['Somano', 'I817'],
    ['Sommariva del Bosco', 'I822'],
    ['Sommariva Perno', 'I823'],
    ['Stroppo', 'I985'],
    ['Tarantasca', 'L048'],
    ['Torre Bormida', 'L252'],
    ['Torre Mondovì', 'L241'],
    ['Torre San Giorgio', 'L278'],
    ['Torresina', 'L281'],
    ['Treiso', 'L367'],
    ['Trezzo Tinella', 'L410'],
    ['Trinità', 'L427'],
    ['Valdieri', 'L558'],
    ['Valgrana', 'L580'],
    ['Valloriate', 'L631'],
    ['Venasca', 'L729'],
    ['Verduno', 'L758'],
    ['Vernante', 'L771'],
    ['Verzuolo', 'L804'],
    ["Vezza d'Alba", 'L817'],
    ['Vicoforte', 'L841'],
    ['Vignolo', 'L888'],
    ['Villafalletto', 'L942'],
    ['Villanova Mondovì', 'L974'],
    ['Villanova Solaro', 'L990'],
    ['Villar San Costanzo', 'M015'],
    ['Vinadio', 'M055'],
    ['Viola', 'M063'],
    ['Vottignasco', 'M136'],
  ],
  AT: [
    ['Agliano Terme', 'A072'],
    ['Albugnano', 'A173'],
    ['Antignano', 'A312'],
    ['Aramengo', 'A352'],
    ['Asti', 'A479'],
    ["Azzano d'Asti", 'A527'],
    ["Baldichieri d'Asti", 'A588'],
    ['Belveglio', 'A770'],
    ['Berzano di San Pietro', 'A812'],
    ['Bruno', 'B221'],
    ['Bubbio', 'B236'],
    ["Buttigliera d'Asti", 'B306'],
    ['Calamandrana', 'B376'],
    ['Calliano', 'B418'],
    ['Calosso', 'B425'],
    ['Camerano Casasco', 'B469'],
    ['Canelli', 'B594'],
    ['Cantarana', 'B633'],
    ['Capriglio', 'B707'],
    ['Casorzo', 'B991'],
    ['Cassinasco', 'C022'],
    ['Castagnole delle Lanze', 'C049'],
    ['Castagnole Monferrato', 'C047'],
    ['Castel Boglione', 'C064'],
    ["Castell'Alfero", 'C127'],
    ['Castellero', 'C154'],
    ['Castelletto Molina', 'C161'],
    ['Castello di Annone', 'A300'],
    ['Castelnuovo Belbo', 'C226'],
    ['Castelnuovo Calcea', 'C230'],
    ['Castelnuovo Don Bosco', 'C232'],
    ['Castel Rocchero', 'C253'],
    ['Cellarengo', 'C438'],
    ['Celle Enomondo', 'C440'],
    ["Cerreto d'Asti", 'C528'],
    ['Cerro Tanaro', 'C533'],
    ['Cessole', 'C583'],
    ["Chiusano d'Asti", 'C658'],
    ['Cinaglio', 'C701'],
    ["Cisterna d'Asti", 'C739'],
    ['Coazzolo', 'C804'],
    ['Cocconato', 'C807'],
    ['Corsione', 'D046'],
    ['Cortandone', 'D050'],
    ['Cortanze', 'D051'],
    ['Cortazzone', 'D052'],
    ['Cortiglione', 'D072'],
    ['Cossombrato', 'D101'],
    ["Costigliole d'Asti", 'D119'],
    ['Cunico', 'D207'],
    ['Dusino San Michele', 'D388'],
    ['Ferrere', 'D554'],
    ['Fontanile', 'D678'],
    ['Frinco', 'D802'],
    ['Grana', 'E134'],
    ['Grazzano Badoglio', 'E159'],
    ['Incisa Scapaccino', 'E295'],
    ["Isola d'Asti", 'E338'],
    ['Loazzolo', 'E633'],
    ['Maranzana', 'E917'],
    ['Maretto', 'E944'],
    ['Moasca', 'F254'],
    ['Mombaldone', 'F308'],
    ['Mombaruzzo', 'F311'],
    ['Mombercelli', 'F316'],
    ['Monale', 'F323'],
    ['Monastero Bormida', 'F325'],
    ['Moncalvo', 'F336'],
    ['Moncucco Torinese', 'F343'],
    ['Mongardino', 'F361'],
    ['Montabone', 'F386'],
    ['Montafia', 'F390'],
    ['Montaldo Scarampi', 'F409'],
    ["Montechiaro d'Asti", 'F468'],
    ["Montegrosso d'Asti", 'F527'],
    ['Montemagno', 'F556'],
    ['Moransengo', 'F709'],
    ['Nizza Monferrato', 'F902'],
    ['Olmo Gentile', 'G048'],
    ['Passerano Marmorito', 'G358'],
    ['Penango', 'G430'],
    ['Piea', 'G593'],
    ["Pino d'Asti", 'G676'],
    ['Piovà Massaia', 'G692'],
    ['Portacomaro', 'G894'],
    ['Quaranti', 'H102'],
    ['Refrancore', 'H219'],
    ["Revigliasco d'Asti", 'H250'],
    ['Roatto', 'H366'],
    ['Robella', 'H376'],
    ["Rocca d'Arazzo", 'H392'],
    ['Roccaverano', 'H451'],
    ['Rocchetta Palafea', 'H466'],
    ['Rocchetta Tanaro', 'H468'],
    ["San Damiano d'Asti", 'H811'],
    ['San Giorgio Scarampi', 'H899'],
    ['San Martino Alfieri', 'H987'],
    ['San Marzano Oliveto', 'I017'],
    ['San Paolo Solbrito', 'I076'],
    ['Scurzolengo', 'I555'],
    ['Serole', 'I637'],
    ['Sessame', 'I678'],
    ['Settime', 'I698'],
    ['Soglio', 'I781'],
    ['Tigliole', 'L168'],
    ['Tonco', 'L203'],
    ['Tonengo', 'L204'],
    ['Vaglio Serra', 'L531'],
    ['Valfenera', 'L574'],
    ['Vesime', 'L807'],
    ['Viale', 'L829'],
    ['Viarigi', 'L834'],
    ["Vigliano d'Asti", 'L879'],
    ["Villafranca d'Asti", 'L945'],
    ["Villanova d'Asti", 'L984'],
    ['Villa San Secondo', 'M019'],
    ['Vinchio', 'M058'],
    ['Montiglio Monferrato', 'M302'],
  ],
  AL: [
    ['Acqui Terme', 'A052'],
    ['Albera Ligure', 'A146'],
    ['Alessandria', 'A182'],
    ['Alfiano Natta', 'A189'],
    ['Alice Bel Colle', 'A197'],
    ['Altavilla Monferrato', 'A227'],
    ['Alzano Scrivia', 'A245'],
    ['Arquata Scrivia', 'A436'],
    ['Avolasca', 'A523'],
    ['Balzola', 'A605'],
    ['Basaluzzo', 'A689'],
    ['Bassignana', 'A708'],
    ['Belforte Monferrato', 'A738'],
    ['Bergamasco', 'A793'],
    ['Berzano di Tortona', 'A813'],
    ['Bistagno', 'A889'],
    ['Borghetto di Borbera', 'A998'],
    ['Borgoratto Alessandrino', 'B029'],
    ['Borgo San Martino', 'B037'],
    ['Bosco Marengo', 'B071'],
    ['Bosio', 'B080'],
    ['Bozzole', 'B109'],
    ['Brignano-Frascata', 'B179'],
    ['Cabella Ligure', 'B311'],
    ['Camagna Monferrato', 'B453'],
    ['Camino', 'B482'],
    ['Cantalupo Ligure', 'B629'],
    ["Capriata d'Orba", 'B701'],
    ['Carbonara Scrivia', 'B736'],
    ['Carentino', 'B765'],
    ['Carezzano', 'B769'],
    ['Carpeneto', 'B818'],
    ['Carrega Ligure', 'B836'],
    ['Carrosio', 'B840'],
    ['Cartosio', 'B847'],
    ['Casal Cermelli', 'B870'],
    ['Casaleggio Boiro', 'B882'],
    ['Casale Monferrato', 'B885'],
    ['Casalnoceto', 'B902'],
    ['Casasco', 'B941'],
    ['Cassine', 'C027'],
    ['Cassinelle', 'C030'],
    ['Castellania', 'C137'],
    ['Castellar Guidobono', 'C142'],
    ['Castellazzo Bormida', 'C148'],
    ["Castelletto d'Erro", 'C156'],
    ["Castelletto d'Orba", 'C158'],
    ['Castelletto Merli', 'C160'],
    ['Castelletto Monferrato', 'C162'],
    ['Castelnuovo Bormida', 'C229'],
    ['Castelnuovo Scrivia', 'C243'],
    ['Castelspina', 'C274'],
    ['Cavatore', 'C387'],
    ['Cella Monte', 'C432'],
    ['Cereseto', 'C503'],
    ['Cerreto Grue', 'C507'],
    ['Cerrina Monferrato', 'C531'],
    ['Coniolo', 'C962'],
    ['Conzano', 'C977'],
    ['Costa Vescovato', 'D102'],
    ['Cremolino', 'D149'],
    ['Denice', 'D272'],
    ['Dernice', 'D277'],
    ['Fabbrica Curone', 'D447'],
    ['Felizzano', 'D528'],
    ['Fraconalto', 'D559'],
    ['Francavilla Bisio', 'D759'],
    ['Frascaro', 'D770'],
    ['Frassinello Monferrato', 'D777'],
    ['Frassineto Po', 'D780'],
    ['Fresonara', 'D797'],
    ['Frugarolo', 'D813'],
    ['Fubine Monferrato', 'D814'],
    ['Gabiano', 'D835'],
    ['Gamalero', 'D890'],
    ['Garbagna', 'D910'],
    ['Gavi', 'D944'],
    ['Giarole', 'E015'],
    ['Gremiasco', 'E164'],
    ['Grognardo', 'E188'],
    ['Grondona', 'E191'],
    ['Guazzora', 'E255'],
    ["Isola Sant'Antonio", 'E360'],
    ['Lerma', 'E543'],
    ['Malvicino', 'E870'],
    ['Masio', 'F015'],
    ['Melazzo', 'F096'],
    ['Merana', 'F131'],
    ['Mirabello Monferrato', 'F232'],
    ['Molare', 'F281'],
    ['Molino dei Torti', 'F293'],
    ['Mombello Monferrato', 'F313'],
    ['Momperone', 'F320'],
    ['Moncestino', 'F337'],
    ['Mongiardino Ligure', 'F365'],
    ['Monleale', 'F374'],
    ['Montacuto', 'F387'],
    ['Montaldeo', 'F403'],
    ['Montaldo Bormida', 'F404'],
    ['Montecastello', 'F455'],
    ["Montechiaro d'Acqui", 'F469'],
    ['Montegioco', 'F518'],
    ['Montemarzino', 'F562'],
    ['Morano sul Po', 'F707'],
    ['Morbello', 'F713'],
    ['Mornese', 'F737'],
    ['Morsasco', 'F751'],
    ['Murisengo', 'F814'],
    ['Novi Ligure', 'F965'],
    ['Occimiano', 'F995'],
    ['Odalengo Grande', 'F997'],
    ['Odalengo Piccolo', 'F998'],
    ['Olivola', 'G042'],
    ['Orsara Bormida', 'G124'],
    ['Ottiglio', 'G193'],
    ['Ovada', 'G197'],
    ['Oviglio', 'G199'],
    ['Ozzano Monferrato', 'G204'],
    ['Paderna', 'G215'],
    ['Pareto', 'G334'],
    ['Parodi Ligure', 'G338'],
    ['Pasturana', 'G367'],
    ['Pecetto di Valenza', 'G397'],
    ['Pietra Marazzi', 'G619'],
    ['Pomaro Monferrato', 'G807'],
    ['Pontecurone', 'G839'],
    ['Pontestura', 'G858'],
    ['Ponti', 'G861'],
    ['Ponzano Monferrato', 'G872'],
    ['Ponzone', 'G877'],
    ['Pozzol Groppo', 'G960'],
    ['Pozzolo Formigaro', 'G961'],
    ['Prasco', 'G987'],
    ['Predosa', 'H021'],
    ['Quargnento', 'H104'],
    ['Quattordio', 'H121'],
    ['Ricaldone', 'H272'],
    ['Rivalta Bormida', 'H334'],
    ['Rivarone', 'H343'],
    ['Roccaforte Ligure', 'H406'],
    ['Rocca Grimalda', 'H414'],
    ['Rocchetta Ligure', 'H465'],
    ['Rosignano Monferrato', 'H569'],
    ['Sala Monferrato', 'H677'],
    ['Sale', 'H694'],
    ['San Cristoforo', 'H810'],
    ['San Giorgio Monferrato', 'H878'],
    ['San Salvatore Monferrato', 'I144'],
    ['San Sebastiano Curone', 'I150'],
    ["Sant'Agata Fossili", 'I190'],
    ['Sardigliano', 'I429'],
    ['Sarezzano', 'I432'],
    ['Serralunga di Crea', 'I645'],
    ['Serravalle Scrivia', 'I657'],
    ['Sezzadio', 'I711'],
    ["Silvano d'Orba", 'I738'],
    ['Solero', 'I798'],
    ['Solonghello', 'I808'],
    ['Spigno Monferrato', 'I901'],
    ['Spineto Scrivia', 'I911'],
    ['Stazzano', 'I941'],
    ['Strevi', 'I977'],
    ['Tagliolo Monferrato', 'L027'],
    ['Tassarolo', 'L059'],
    ['Terruggia', 'L139'],
    ['Terzo', 'L143'],
    ['Ticineto', 'L165'],
    ['Tortona', 'L304'],
    ['Treville', 'L403'],
    ['Trisobbio', 'L432'],
    ['Valenza', 'L570'],
    ['Valmacca', 'L633'],
    ['Vignale Monferrato', 'L881'],
    ['Vignole Borbera', 'L887'],
    ['Viguzzolo', 'L904'],
    ['Villadeati', 'L931'],
    ['Villalvernia', 'L963'],
    ['Villamiroglio', 'L970'],
    ['Villanova Monferrato', 'L972'],
    ['Villaromagnano', 'M009'],
    ['Visone', 'M077'],
    ['Volpedo', 'M120'],
    ['Volpeglino', 'M121'],
    ['Voltaggio', 'M123'],
    ['Cassano Spinola', 'M388'],
    ['Alluvioni Piovera', 'M397'],
    ['Lu e Cuccaro Monferrato', 'M420'],
  ],
  BI: [
    ['Ailoche', 'A107'],
    ['Andorno Micca', 'A280'],
    ['Benna', 'A784'],
    ['Biella', 'A859'],
    ['Bioglio', 'A876'],
    ['Borriana', 'B058'],
    ['Brusnengo', 'B229'],
    ['Callabiana', 'B417'],
    ['Camandona', 'B457'],
    ['Camburzano', 'B465'],
    ['Candelo', 'B586'],
    ['Caprile', 'B708'],
    ['Casapinta', 'B933'],
    ['Castelletto Cervo', 'C155'],
    ['Cavaglià', 'C363'],
    ['Cerrione', 'C532'],
    ['Coggiola', 'C819'],
    ['Cossato', 'D094'],
    ['Crevacuore', 'D165'],
    ['Curino', 'D219'],
    ['Donato', 'D339'],
    ['Dorzano', 'D350'],
    ['Gaglianico', 'D848'],
    ['Gifflenga', 'E024'],
    ['Graglia', 'E130'],
    ['Magnano', 'E821'],
    ['Massazza', 'F037'],
    ['Masserano', 'F042'],
    ['Mezzana Mortigliengo', 'F167'],
    ['Miagliano', 'F189'],
    ['Mongrando', 'F369'],
    ['Mottalciata', 'F776'],
    ['Muzzano', 'F833'],
    ['Netro', 'F878'],
    ['Occhieppo Inferiore', 'F992'],
    ['Occhieppo Superiore', 'F993'],
    ['Pettinengo', 'G521'],
    ['Piatto', 'G577'],
    ['Piedicavallo', 'G594'],
    ['Pollone', 'G798'],
    ['Ponderano', 'G820'],
    ['Portula', 'G927'],
    ['Pralungo', 'G980'],
    ['Pray', 'G974'],
    ['Ronco Biellese', 'H538'],
    ['Roppolo', 'H553'],
    ['Rosazza', 'H561'],
    ['Sagliano Micca', 'H662'],
    ['Sala Biellese', 'H681'],
    ['Salussola', 'H726'],
    ['Sandigliano', 'H821'],
    ['Sordevolo', 'I847'],
    ['Sostegno', 'I868'],
    ['Strona', 'I980'],
    ['Tavigliano', 'L075'],
    ['Ternengo', 'L116'],
    ['Tollegno', 'L193'],
    ['Torrazzo', 'L239'],
    ['Valdengo', 'L556'],
    ['Vallanzengo', 'L586'],
    ['Valle San Nicolao', 'L620'],
    ['Veglio', 'L712'],
    ['Verrone', 'L785'],
    ['Vigliano Biellese', 'L880'],
    ['Villa del Bosco', 'L933'],
    ['Villanova Biellese', 'L978'],
    ['Viverone', 'M098'],
    ['Zimone', 'M179'],
    ['Zubiena', 'M196'],
    ['Zumaglia', 'M201'],
    ['Lessona', 'M371'],
    ['Campiglia Cervo', 'M373'],
    ['Quaregna Cerreto', 'M414'],
    ['Valdilana', 'M417'],
  ],
  VB: [
    ['Antrona Schieranco', 'A317'],
    ["Anzola d'Ossola", 'A325'],
    ['Arizzano', 'A409'],
    ['Arola', 'A427'],
    ['Aurano', 'A497'],
    ['Baceno', 'A534'],
    ['Bannio Anzino', 'A610'],
    ['Baveno', 'A725'],
    ['Bee', 'A733'],
    ['Belgirate', 'A742'],
    ['Beura-Cardezza', 'A834'],
    ['Bognanco', 'A925'],
    ['Brovello-Carpugnino', 'B207'],
    ['Calasca-Castiglione', 'B380'],
    ['Cambiasca', 'B463'],
    ['Cannero Riviera', 'B610'],
    ['Cannobio', 'B615'],
    ['Caprezzo', 'B694'],
    ['Casale Corte Cerro', 'B876'],
    ['Ceppo Morelli', 'C478'],
    ['Cesara', 'C567'],
    ['Cossogno', 'D099'],
    ['Craveggia', 'D134'],
    ['Crevoladossola', 'D168'],
    ['Crodo', 'D177'],
    ['Domodossola', 'D332'],
    ['Druogno', 'D374'],
    ['Formazza', 'D706'],
    ['Germagno', 'D984'],
    ['Ghiffa', 'E003'],
    ['Gignese', 'E028'],
    ['Gravellona Toce', 'E153'],
    ['Gurro', 'E269'],
    ['Intragna', 'E304'],
    ['Loreglia', 'E685'],
    ['Macugnaga', 'E790'],
    ['Madonna del Sasso', 'E795'],
    ['Malesco', 'E853'],
    ['Masera', 'F010'],
    ['Massiola', 'F048'],
    ['Mergozzo', 'F146'],
    ['Miazzina', 'F192'],
    ['Montecrestese', 'F483'],
    ['Montescheno', 'F639'],
    ['Nonio', 'F932'],
    ['Oggebbio', 'G007'],
    ['Omegna', 'G062'],
    ['Ornavasso', 'G117'],
    ['Pallanzeno', 'G280'],
    ['Piedimulera', 'G600'],
    ['Pieve Vergonte', 'G658'],
    ['Premeno', 'H030'],
    ['Premia', 'H033'],
    ['Premosello-Chiovenda', 'H037'],
    ['Quarna Sopra', 'H106'],
    ['Quarna Sotto', 'H107'],
    ['Re', 'H203'],
    ['San Bernardino Verbano', 'H777'],
    ['Santa Maria Maggiore', 'I249'],
    ['Stresa', 'I976'],
    ['Toceno', 'L187'],
    ['Trarego Viggiona', 'L333'],
    ['Trasquera', 'L336'],
    ['Trontano', 'L450'],
    ['Valstrona', 'L651'],
    ['Vanzone con San Carlo', 'L666'],
    ['Varzo', 'L691'],
    ['Verbania', 'L746'],
    ['Vignone', 'L889'],
    ['Villadossola', 'L906'],
    ['Villette', 'M042'],
    ['Vogogna', 'M111'],
    ['Borgomezzavalle', 'M370'],
    ['Valle Cannobina', 'M404'],
  ],
  AO: [
    ['Allein', 'A205'],
    ['Antey-Saint-André', 'A305'],
    ['Aosta', 'A326'],
    ['Arnad', 'A424'],
    ['Arvier', 'A452'],
    ['Avise', 'A521'],
    ['Ayas', 'A094'],
    ['Aymavilles', 'A108'],
    ['Bard', 'A643'],
    ['Bionaz', 'A877'],
    ['Brissogne', 'B192'],
    ['Brusson', 'B230'],
    ['Challand-Saint-Anselme', 'C593'],
    ['Challand-Saint-Victor', 'C594'],
    ['Chambave', 'C595'],
    ['Chamois', 'B491'],
    ['Champdepraz', 'C596'],
    ['Champorcher', 'B540'],
    ['Charvensod', 'C598'],
    ['Châtillon', 'C294'],
    ['Cogne', 'C821'],
    ['Courmayeur', 'D012'],
    ['Donnas', 'D338'],
    ['Doues', 'D356'],
    ['Emarèse', 'D402'],
    ['Etroubles', 'D444'],
    ['Fénis', 'D537'],
    ['Fontainemore', 'D666'],
    ['Gaby', 'D839'],
    ['Gignod', 'E029'],
    ['Gressan', 'E165'],
    ['Gressoney-La-Trinité', 'E167'],
    ['Gressoney-Saint-Jean', 'E168'],
    ['Hône', 'E273'],
    ['Introd', 'E306'],
    ['Issime', 'E369'],
    ['Issogne', 'E371'],
    ['Jovençan', 'E391'],
    ['La Magdeleine', 'A308'],
    ['La Salle', 'E458'],
    ['La Thuile', 'E470'],
    ['Lillianes', 'E587'],
    ['Montjovet', 'F367'],
    ['Morgex', 'F726'],
    ['Nus', 'F987'],
    ['Ollomont', 'G045'],
    ['Oyace', 'G012'],
    ['Perloz', 'G459'],
    ['Pollein', 'G794'],
    ['Pontboset', 'G545'],
    ['Pontey', 'G860'],
    ['Pont-Saint-Martin', 'G854'],
    ['Pré-Saint-Didier', 'H042'],
    ['Quart', 'H110'],
    ['Rhêmes-Notre-Dame', 'H262'],
    ['Rhêmes-Saint-Georges', 'H263'],
    ['Roisan', 'H497'],
    ['Saint-Christophe', 'H669'],
    ['Saint-Denis', 'H670'],
    ['Saint-Marcel', 'H671'],
    ['Saint-Nicolas', 'H672'],
    ['Saint-Oyen', 'H673'],
    ['Saint-Pierre', 'H674'],
    ['Saint-Rhémy-en-Bosses', 'H675'],
    ['Saint-Vincent', 'H676'],
    ['Sarre', 'I442'],
    ['Torgnon', 'L217'],
    ['Valgrisenche', 'L582'],
    ['Valpelline', 'L643'],
    ['Valsavarenche', 'L647'],
    ['Valtournenche', 'L654'],
    ['Verrayes', 'L783'],
    ['Verrès', 'C282'],
    ['Villeneuve', 'L981'],
  ],
  VA: [
    ['Agra', 'A085'],
    ['Albizzate', 'A167'],
    ['Angera', 'A290'],
    ['Arcisate', 'A371'],
    ['Arsago Seprio', 'A441'],
    ['Azzate', 'A531'],
    ['Azzio', 'A532'],
    ['Barasso', 'A619'],
    ['Bardello', 'A645'],
    ['Bedero Valcuvia', 'A728'],
    ['Besano', 'A819'],
    ['Besnate', 'A825'],
    ['Besozzo', 'A826'],
    ['Biandronno', 'A845'],
    ['Bisuschio', 'A891'],
    ['Bodio Lomnago', 'A918'],
    ['Brebbia', 'B126'],
    ['Bregano', 'B131'],
    ['Brenta', 'B150'],
    ['Brezzo di Bedero', 'B166'],
    ['Brinzio', 'B182'],
    ['Brissago-Valtravaglia', 'B191'],
    ['Brunello', 'B219'],
    ['Brusimpiano', 'B228'],
    ['Buguggiate', 'B258'],
    ['Busto Arsizio', 'B300'],
    ['Cadegliano-Viconago', 'B326'],
    ['Cairate', 'B368'],
    ['Cantello', 'B634'],
    ['Caravate', 'B732'],
    ['Cardano al Campo', 'B754'],
    ['Carnago', 'B796'],
    ['Caronno Pertusella', 'B805'],
    ['Caronno Varesino', 'B807'],
    ['Casale Litta', 'B875'],
    ['Casalzuigno', 'B921'],
    ['Casciago', 'B949'],
    ['Casorate Sempione', 'B987'],
    ['Cassano Magnago', 'C004'],
    ['Cassano Valcuvia', 'B999'],
    ['Castellanza', 'C139'],
    ['Castello Cabiaglio', 'B312'],
    ['Castelseprio', 'C273'],
    ['Castelveccana', 'C181'],
    ['Castiglione Olona', 'C300'],
    ['Castronno', 'C343'],
    ['Cavaria con Premezzo', 'C382'],
    ['Cazzago Brabbia', 'C409'],
    ['Cislago', 'C732'],
    ['Cittiglio', 'C751'],
    ['Clivio', 'C796'],
    ['Cocquio-Trevisago', 'C810'],
    ['Comabbio', 'C911'],
    ['Comerio', 'C922'],
    ['Cremenaga', 'D144'],
    ['Crosio della Valle', 'D185'],
    ['Cuasso al Monte', 'D192'],
    ['Cugliate-Fabiasco', 'D199'],
    ['Cunardo', 'D204'],
    ['Curiglia con Monteviasco', 'D217'],
    ['Cuveglio', 'D238'],
    ['Cuvio', 'D239'],
    ['Daverio', 'D256'],
    ['Dumenza', 'D384'],
    ['Duno', 'D385'],
    ['Fagnano Olona', 'D467'],
    ['Ferno', 'D543'],
    ['Ferrera di Varese', 'D551'],
    ['Gallarate', 'D869'],
    ['Galliate Lombardo', 'D871'],
    ['Gavirate', 'D946'],
    ['Gazzada Schianno', 'D951'],
    ['Gemonio', 'D963'],
    ['Gerenzano', 'D981'],
    ['Germignaga', 'D987'],
    ['Golasecca', 'E079'],
    ['Gorla Maggiore', 'E101'],
    ['Gorla Minore', 'E102'],
    ['Gornate Olona', 'E104'],
    ['Grantola', 'E144'],
    ['Inarzo', 'E292'],
    ['Induno Olona', 'E299'],
    ['Ispra', 'E367'],
    ['Jerago con Orago', 'E386'],
    ['Lavena Ponte Tresa', 'E494'],
    ['Laveno-Mombello', 'E496'],
    ['Leggiuno', 'E510'],
    ['Lonate Ceppino', 'E665'],
    ['Lonate Pozzolo', 'E666'],
    ['Lozza', 'E707'],
    ['Luino', 'E734'],
    ['Luvinate', 'E769'],
    ['Malgesso', 'E856'],
    ['Malnate', 'E863'],
    ['Marchirolo', 'E929'],
    ['Marnate', 'E965'],
    ['Marzio', 'F002'],
    ['Masciago Primo', 'F007'],
    ['Mercallo', 'F134'],
    ['Mesenzana', 'F154'],
    ['Montegrino Valtravaglia', 'F526'],
    ['Monvalle', 'F703'],
    ['Morazzone', 'F711'],
    ['Mornago', 'F736'],
    ['Oggiona con Santo Stefano', 'G008'],
    ['Olgiate Olona', 'G028'],
    ['Origgio', 'G103'],
    ['Orino', 'G105'],
    ['Porto Ceresio', 'G906'],
    ['Porto Valtravaglia', 'G907'],
    ['Rancio Valcuvia', 'H173'],
    ['Ranco', 'H174'],
    ['Saltrio', 'H723'],
    ['Samarate', 'H736'],
    ['Saronno', 'I441'],
    ['Sesto Calende', 'I688'],
    ['Solbiate Arno', 'I793'],
    ['Solbiate Olona', 'I794'],
    ['Somma Lombardo', 'I819'],
    ['Sumirago', 'L003'],
    ['Taino', 'L032'],
    ['Ternate', 'L115'],
    ['Tradate', 'L319'],
    ['Travedona-Monate', 'L342'],
    ['Tronzano Lago Maggiore', 'A705'],
    ['Uboldo', 'L480'],
    ['Valganna', 'L577'],
    ['Varano Borghi', 'L671'],
    ['Varese', 'L682'],
    ['Vedano Olona', 'L703'],
    ['Venegono Inferiore', 'L733'],
    ['Venegono Superiore', 'L734'],
    ['Vergiate', 'L765'],
    ['Viggiù', 'L876'],
    ['Vizzola Ticino', 'M101'],
    ['Sangiano', 'H872'],
    ['Maccagno con Pino e Veddasca', 'M339'],
    ['Cadrezzate con Osmate', 'M425'],
  ],
  CO: [
    ['Albavilla', 'A143'],
    ['Albese con Cassano', 'A153'],
    ['Albiolo', 'A164'],
    ['Alserio', 'A224'],
    ['Alzate Brianza', 'A249'],
    ['Anzano del Parco', 'A319'],
    ['Appiano Gentile', 'A333'],
    ['Argegno', 'A391'],
    ['Arosio', 'A430'],
    ['Asso', 'A476'],
    ['Barni', 'A670'],
    ['Bene Lario', 'A778'],
    ['Beregazzo con Figliaro', 'A791'],
    ['Binago', 'A870'],
    ['Bizzarone', 'A898'],
    ['Blessagno', 'A904'],
    ['Blevio', 'A905'],
    ['Bregnano', 'B134'],
    ['Brenna', 'B144'],
    ['Brienno', 'B172'],
    ['Brunate', 'B218'],
    ['Bulgarograsso', 'B262'],
    ['Cabiate', 'B313'],
    ['Cadorago', 'B346'],
    ['Caglio', 'B355'],
    ["Campione d'Italia", 'B513'],
    ['Cantù', 'B639'],
    ['Canzo', 'B641'],
    ['Capiago Intimiano', 'B653'],
    ['Carate Urio', 'B730'],
    ['Carbonate', 'B742'],
    ['Carimate', 'B778'],
    ['Carlazzo', 'B785'],
    ['Carugo', 'B851'],
    ["Caslino d'Erba", 'B974'],
    ['Casnate con Bernate', 'B977'],
    ['Cassina Rizzardi', 'C020'],
    ['Castelmarte', 'C206'],
    ['Castelnuovo Bozzente', 'C220'],
    ['Cavargna', 'C381'],
    ["Cerano d'Intelvi", 'C482'],
    ['Cermenate', 'C516'],
    ['Cernobbio', 'C520'],
    ['Cirimido', 'C724'],
    ['Claino con Osteno', 'C787'],
    ['Colonno', 'C902'],
    ['Como', 'C933'],
    ['Corrido', 'D041'],
    ['Cremia', 'D147'],
    ['Cucciago', 'D196'],
    ['Cusino', 'D232'],
    ['Dizzasco', 'D310'],
    ['Domaso', 'D329'],
    ['Dongo', 'D341'],
    ['Dosso del Liro', 'D355'],
    ['Erba', 'D416'],
    ['Eupilio', 'D445'],
    ['Faggeto Lario', 'D462'],
    ['Faloppio', 'D482'],
    ['Fenegrò', 'D531'],
    ['Figino Serenza', 'D579'],
    ['Fino Mornasco', 'D605'],
    ['Garzeno', 'D930'],
    ['Gera Lario', 'D974'],
    ['Grandate', 'E139'],
    ['Grandola ed Uniti', 'E141'],
    ['Griante', 'E172'],
    ['Guanzate', 'E235'],
    ['Inverigo', 'E309'],
    ['Laglio', 'E405'],
    ['Laino', 'E416'],
    ['Lambrugo', 'E428'],
    ['Lasnigo', 'E462'],
    ['Lezzeno', 'E569'],
    ['Limido Comasco', 'E593'],
    ['Lipomo', 'E607'],
    ['Livo', 'E623'],
    ['Locate Varesino', 'E638'],
    ['Lomazzo', 'E659'],
    ['Longone al Segrino', 'E679'],
    ['Luisago', 'E735'],
    ["Lurago d'Erba", 'E749'],
    ['Lurago Marinone', 'E750'],
    ['Lurate Caccivio', 'E753'],
    ['Magreglio', 'E830'],
    ['Mariano Comense', 'E951'],
    ['Maslianico', 'F017'],
    ['Menaggio', 'F120'],
    ['Merone', 'F151'],
    ['Moltrasio', 'F305'],
    ['Monguzzo', 'F372'],
    ['Montano Lucino', 'F427'],
    ['Montemezzo', 'F564'],
    ['Montorfano', 'F688'],
    ['Mozzate', 'F788'],
    ['Musso', 'F828'],
    ['Nesso', 'F877'],
    ['Novedrate', 'F958'],
    ['Olgiate Comasco', 'G025'],
    ['Oltrona di San Mamette', 'G056'],
    ['Orsenigo', 'G126'],
    ['Peglio', 'G415'],
    ['Pianello del Lario', 'G556'],
    ['Pigra', 'G665'],
    ['Plesio', 'G737'],
    ['Pognana Lario', 'G773'],
    ['Ponna', 'G821'],
    ['Ponte Lambro', 'G847'],
    ['Porlezza', 'G889'],
    ['Proserpio', 'H074'],
    ['Pusiano', 'H094'],
    ['Rezzago', 'H255'],
    ['Rodero', 'H478'],
    ['Ronago', 'H521'],
    ['Rovellasca', 'H601'],
    ['Rovello Porro', 'H602'],
    ['Sala Comacina', 'H679'],
    ['San Bartolomeo Val Cavargna', 'H760'],
    ['San Fermo della Battaglia', 'H840'],
    ['San Nazzaro Val Cavargna', 'I051'],
    ['Schignano', 'I529'],
    ['Senna Comasco', 'I611'],
    ['Sorico', 'I856'],
    ['Sormano', 'I860'],
    ['Stazzona', 'I943'],
    ['Tavernerio', 'L071'],
    ['Torno', 'L228'],
    ['Trezzone', 'L413'],
    ['Turate', 'L470'],
    ['Uggiate-Trevano', 'L487'],
    ['Valbrona', 'L547'],
    ['Valmorea', 'L640'],
    ['Val Rezzo', 'H259'],
    ['Valsolda', 'C936'],
    ['Veleso', 'L715'],
    ['Veniano', 'L737'],
    ['Vercana', 'L748'],
    ['Vertemate con Minoprio', 'L792'],
    ['Villa Guardia', 'L956'],
    ['Zelbio', 'M156'],
    ['San Siro', 'I162'],
    ['Gravedona ed Uniti', 'M315'],
    ['Bellagio', 'M335'],
    ['Colverde', 'M336'],
    ['Tremezzina', 'M341'],
    ['Alta Valle Intelvi', 'M383'],
    ['Centro Valle Intelvi', 'M394'],
    ['Solbiate con Cagno', 'M412'],
  ],
  SO: [
    ['Albaredo per San Marco', 'A135'],
    ['Albosaggia', 'A172'],
    ['Andalo Valtellino', 'A273'],
    ['Aprica', 'A337'],
    ['Ardenno', 'A382'],
    ['Bema', 'A777'],
    ['Berbenno di Valtellina', 'A787'],
    ['Bianzone', 'A848'],
    ['Bormio', 'B049'],
    ['Buglio in Monte', 'B255'],
    ['Caiolo', 'B366'],
    ['Campodolcino', 'B530'],
    ['Caspoggio', 'B993'],
    ["Castello dell'Acqua", 'C186'],
    ['Castione Andevenno', 'C325'],
    ['Cedrasco', 'C418'],
    ['Cercino', 'C493'],
    ['Chiavenna', 'C623'],
    ['Chiesa in Valmalenco', 'C628'],
    ['Chiuro', 'C651'],
    ['Cino', 'C709'],
    ['Civo', 'C785'],
    ['Colorina', 'C903'],
    ['Cosio Valtellino', 'D088'],
    ['Dazio', 'D258'],
    ['Delebio', 'D266'],
    ['Dubino', 'D377'],
    ['Faedo Valtellino', 'D456'],
    ['Forcola', 'D694'],
    ['Fusine', 'D830'],
    ['Gerola Alta', 'D990'],
    ['Gordona', 'E090'],
    ['Grosio', 'E200'],
    ['Grosotto', 'E201'],
    ['Madesimo', 'E342'],
    ['Lanzada', 'E443'],
    ['Livigno', 'E621'],
    ['Lovero', 'E705'],
    ['Mantello', 'E896'],
    ['Mazzo di Valtellina', 'F070'],
    ['Mello', 'F115'],
    ['Mese', 'F153'],
    ['Montagna in Valtellina', 'F393'],
    ['Morbegno', 'F712'],
    ['Novate Mezzola', 'F956'],
    ['Pedesina', 'G410'],
    ['Piantedo', 'G572'],
    ['Piateda', 'G576'],
    ['Piuro', 'G718'],
    ['Poggiridenti', 'G431'],
    ['Ponte in Valtellina', 'G829'],
    ['Postalesio', 'G937'],
    ['Prata Camportaccio', 'G993'],
    ['Rasura', 'H192'],
    ['Rogolo', 'H493'],
    ['Samolaco', 'H752'],
    ['San Giacomo Filippo', 'H868'],
    ['Sernio', 'I636'],
    ['Sondalo', 'I828'],
    ['Sondrio', 'I829'],
    ['Spriana', 'I928'],
    ['Talamona', 'L035'],
    ['Tartano', 'L056'],
    ['Teglio', 'L084'],
    ['Tirano', 'L175'],
    ['Torre di Santa Maria', 'L244'],
    ["Tovo di Sant'Agata", 'L316'],
    ['Traona', 'L330'],
    ['Tresivio', 'L392'],
    ['Valdidentro', 'L557'],
    ['Valdisotto', 'L563'],
    ['Valfurva', 'L576'],
    ['Val Masino', 'L638'],
    ['Verceia', 'L749'],
    ['Vervio', 'L799'],
    ['Villa di Chiavenna', 'L907'],
    ['Villa di Tirano', 'L908'],
  ],
  MI: [
    ['Abbiategrasso', 'A010'],
    ['Albairate', 'A127'],
    ['Arconate', 'A375'],
    ['Arese', 'A389'],
    ['Arluno', 'A413'],
    ['Assago', 'A473'],
    ['Bareggio', 'A652'],
    ['Basiano', 'A697'],
    ['Basiglio', 'A699'],
    ['Bellinzago Lombardo', 'A751'],
    ['Bernate Ticino', 'A804'],
    ['Besate', 'A820'],
    ['Binasco', 'A872'],
    ['Boffalora sopra Ticino', 'A920'],
    ['Bollate', 'A940'],
    ['Bresso', 'B162'],
    ['Bubbiano', 'B235'],
    ['Buccinasco', 'B240'],
    ['Buscate', 'B286'],
    ['Bussero', 'B292'],
    ['Busto Garolfo', 'B301'],
    ['Calvignasco', 'B448'],
    ['Cambiago', 'B461'],
    ['Canegrate', 'B593'],
    ['Carpiano', 'B820'],
    ['Carugate', 'B850'],
    ['Casarile', 'B938'],
    ['Casorezzo', 'B989'],
    ["Cassano d'Adda", 'C003'],
    ["Cassina de' Pecchi", 'C014'],
    ['Cassinetta di Lugagnano', 'C033'],
    ['Castano Primo', 'C052'],
    ['Cernusco sul Naviglio', 'C523'],
    ['Cerro al Lambro', 'C536'],
    ['Cerro Maggiore', 'C537'],
    ['Cesano Boscone', 'C565'],
    ['Cesate', 'C569'],
    ['Cinisello Balsamo', 'C707'],
    ['Cisliano', 'C733'],
    ['Cologno Monzese', 'C895'],
    ['Colturano', 'C908'],
    ['Corbetta', 'C986'],
    ['Cormano', 'D013'],
    ['Cornaredo', 'D018'],
    ['Corsico', 'D045'],
    ['Cuggiono', 'D198'],
    ['Cusago', 'D229'],
    ['Cusano Milanino', 'D231'],
    ['Dairago', 'D244'],
    ['Dresano', 'D367'],
    ['Gaggiano', 'D845'],
    ['Garbagnate Milanese', 'D912'],
    ['Gessate', 'D995'],
    ['Gorgonzola', 'E094'],
    ['Grezzago', 'E170'],
    ['Gudo Visconti', 'E258'],
    ['Inveruno', 'E313'],
    ['Inzago', 'E317'],
    ['Lacchiarella', 'E395'],
    ['Lainate', 'E415'],
    ['Legnano', 'E514'],
    ['Liscate', 'E610'],
    ['Locate di Triulzi', 'E639'],
    ['Magenta', 'E801'],
    ['Magnago', 'E819'],
    ['Marcallo con Casone', 'E921'],
    ['Masate', 'F003'],
    ['Mediglia', 'F084'],
    ['Melegnano', 'F100'],
    ['Melzo', 'F119'],
    ['Mesero', 'F155'],
    ['Milano', 'F205'],
    ['Morimondo', 'D033'],
    ['Motta Visconti', 'F783'],
    ['Nerviano', 'F874'],
    ['Nosate', 'F939'],
    ['Novate Milanese', 'F955'],
    ['Noviglio', 'F968'],
    ['Opera', 'G078'],
    ['Ossona', 'G181'],
    ['Ozzero', 'G206'],
    ['Paderno Dugnano', 'G220'],
    ['Pantigliate', 'G316'],
    ['Parabiago', 'G324'],
    ['Paullo', 'G385'],
    ['Pero', 'C013'],
    ['Peschiera Borromeo', 'G488'],
    ['Pessano con Bornago', 'G502'],
    ['Pieve Emanuele', 'G634'],
    ['Pioltello', 'G686'],
    ['Pogliano Milanese', 'G772'],
    ["Pozzo d'Adda", 'G955'],
    ['Pozzuolo Martesana', 'G965'],
    ['Pregnana Milanese', 'H026'],
    ['Rescaldina', 'H240'],
    ['Rho', 'H264'],
    ['Robecchetto con Induno', 'H371'],
    ['Robecco sul Naviglio', 'H373'],
    ['Rodano', 'H470'],
    ['Rosate', 'H560'],
    ['Rozzano', 'H623'],
    ['San Colombano al Lambro', 'H803'],
    ['San Donato Milanese', 'H827'],
    ['San Giorgio su Legnano', 'H884'],
    ['San Giuliano Milanese', 'H930'],
    ['Santo Stefano Ticino', 'I361'],
    ['San Vittore Olona', 'I409'],
    ['San Zenone al Lambro', 'I415'],
    ['Sedriano', 'I566'],
    ['Segrate', 'I577'],
    ['Senago', 'I602'],
    ['Sesto San Giovanni', 'I690'],
    ['Settala', 'I696'],
    ['Settimo Milanese', 'I700'],
    ['Solaro', 'I786'],
    ['Trezzano Rosa', 'L408'],
    ['Trezzano sul Naviglio', 'L409'],
    ["Trezzo sull'Adda", 'L411'],
    ['Tribiano', 'L415'],
    ['Truccazzano', 'L454'],
    ['Turbigo', 'L471'],
    ['Vanzago', 'L665'],
    ["Vaprio d'Adda", 'L667'],
    ['Vernate', 'L773'],
    ['Vignate', 'L883'],
    ['Vimodrone', 'M053'],
    ['Vittuone', 'M091'],
    ['Vizzolo Predabissi', 'M102'],
    ['Zibido San Giacomo', 'M176'],
    ['Villa Cortese', 'L928'],
    ['Vanzaghello', 'L664'],
    ['Baranzate', 'A618'],
    ['Vermezzo con Zelo', 'M424'],
  ],
  BG: [
    ['Adrara San Martino', 'A057'],
    ['Adrara San Rocco', 'A058'],
    ["Albano Sant'Alessandro", 'A129'],
    ['Albino', 'A163'],
    ['Almè', 'A214'],
    ['Almenno San Bartolomeo', 'A216'],
    ['Almenno San Salvatore', 'A217'],
    ['Alzano Lombardo', 'A246'],
    ['Ambivere', 'A259'],
    ['Antegnate', 'A304'],
    ['Arcene', 'A365'],
    ['Ardesio', 'A383'],
    ["Arzago d'Adda", 'A440'],
    ['Averara', 'A511'],
    ['Aviatico', 'A517'],
    ['Azzano San Paolo', 'A528'],
    ['Azzone', 'A533'],
    ['Bagnatica', 'A557'],
    ['Barbata', 'A631'],
    ['Bariano', 'A664'],
    ['Barzana', 'A684'],
    ['Bedulita', 'A732'],
    ['Berbenno', 'A786'],
    ['Bergamo', 'A794'],
    ['Berzo San Fermo', 'A815'],
    ['Bianzano', 'A846'],
    ['Blello', 'A903'],
    ['Bolgare', 'A937'],
    ['Boltiere', 'A950'],
    ['Bonate Sopra', 'A963'],
    ['Bonate Sotto', 'A962'],
    ['Borgo di Terzo', 'B010'],
    ['Bossico', 'B083'],
    ['Bottanuco', 'B088'],
    ['Bracca', 'B112'],
    ['Branzi', 'B123'],
    ['Brembate', 'B137'],
    ['Brembate di Sopra', 'B138'],
    ["Brignano Gera d'Adda", 'B178'],
    ['Brumano', 'B217'],
    ['Brusaporto', 'B223'],
    ['Calcinate', 'B393'],
    ['Calcio', 'B395'],
    ["Calusco d'Adda", 'B434'],
    ['Calvenzano', 'B442'],
    ['Camerata Cornello', 'B471'],
    ["Canonica d'Adda", 'B618'],
    ['Capizzone', 'B661'],
    ['Capriate San Gervasio', 'B703'],
    ['Caprino Bergamasco', 'B710'],
    ['Caravaggio', 'B731'],
    ['Carobbio degli Angeli', 'B801'],
    ['Carona', 'B803'],
    ['Carvico', 'B854'],
    ['Casazza', 'B947'],
    ["Casirate d'Adda", 'B971'],
    ['Casnigo', 'B978'],
    ['Cassiglio', 'C007'],
    ['Castelli Calepio', 'C079'],
    ['Castel Rozzone', 'C255'],
    ['Castione della Presolana', 'C324'],
    ['Castro', 'C337'],
    ['Cavernago', 'C396'],
    ["Cazzano Sant'Andrea", 'C410'],
    ['Cenate Sopra', 'C456'],
    ['Cenate Sotto', 'C457'],
    ['Cene', 'C459'],
    ['Cerete', 'C506'],
    ["Chignolo d'Isola", 'C635'],
    ['Chiuduno', 'C649'],
    ['Cisano Bergamasco', 'C728'],
    ['Ciserano', 'C730'],
    ['Cividate al Piano', 'C759'],
    ['Clusone', 'C800'],
    ['Colere', 'C835'],
    ['Cologno al Serio', 'C894'],
    ['Colzate', 'C910'],
    ['Comun Nuovo', 'C937'],
    ['Corna Imagna', 'D015'],
    ['Cortenuova', 'D066'],
    ['Costa di Mezzate', 'D110'],
    ['Costa Valle Imagna', 'D103'],
    ['Costa Volpino', 'D117'],
    ['Covo', 'D126'],
    ['Credaro', 'D139'],
    ['Curno', 'D221'],
    ['Cusio', 'D233'],
    ['Dalmine', 'D245'],
    ['Dossena', 'D352'],
    ['Endine Gaiano', 'D406'],
    ['Entratico', 'D411'],
    ["Fara Gera d'Adda", 'D490'],
    ['Fara Olivana con Sola', 'D491'],
    ['Filago', 'D588'],
    ['Fino del Monte', 'D604'],
    ['Fiorano al Serio', 'D606'],
    ['Fontanella', 'D672'],
    ['Fonteno', 'D684'],
    ['Foppolo', 'D688'],
    ['Foresto Sparso', 'D697'],
    ['Fornovo San Giovanni', 'D727'],
    ['Fuipiano Valle Imagna', 'D817'],
    ['Gandellino', 'D903'],
    ['Gandino', 'D905'],
    ['Gandosso', 'D906'],
    ['Gaverina Terme', 'D943'],
    ['Gazzaniga', 'D952'],
    ['Ghisalba', 'E006'],
    ['Gorlago', 'E100'],
    ['Gorle', 'E103'],
    ['Gorno', 'E106'],
    ['Grassobbio', 'E148'],
    ['Gromo', 'E189'],
    ['Grone', 'E192'],
    ['Grumello del Monte', 'E219'],
    ['Isola di Fondra', 'E353'],
    ['Isso', 'E370'],
    ['Lallio', 'E422'],
    ['Leffe', 'E509'],
    ['Lenna', 'E524'],
    ['Levate', 'E562'],
    ['Locatello', 'E640'],
    ['Lovere', 'E704'],
    ['Lurano', 'E751'],
    ['Luzzana', 'E770'],
    ['Madone', 'E794'],
    ['Mapello', 'E901'],
    ['Martinengo', 'E987'],
    ['Mezzoldo', 'F186'],
    ["Misano di Gera d'Adda", 'F243'],
    ["Moio de' Calvi", 'F276'],
    ['Monasterolo del Castello', 'F328'],
    ['Montello', 'F547'],
    ['Morengo', 'F720'],
    ['Mornico al Serio', 'F738'],
    ['Mozzanica', 'F786'],
    ['Mozzo', 'F791'],
    ['Nembro', 'F864'],
    ['Olmo al Brembo', 'G049'],
    ['Oltre il Colle', 'G050'],
    ['Oltressenda Alta', 'G054'],
    ['Oneta', 'G068'],
    ['Onore', 'G075'],
    ['Orio al Serio', 'G108'],
    ['Ornica', 'G118'],
    ['Osio Sopra', 'G159'],
    ['Osio Sotto', 'G160'],
    ['Pagazzano', 'G233'],
    ['Paladina', 'G249'],
    ['Palazzago', 'G259'],
    ['Palosco', 'G295'],
    ['Parre', 'G346'],
    ['Parzanica', 'G350'],
    ['Pedrengo', 'G412'],
    ['Peia', 'G418'],
    ['Pianico', 'G564'],
    ['Piario', 'G574'],
    ['Piazza Brembana', 'G579'],
    ['Piazzatorre', 'G583'],
    ['Piazzolo', 'G588'],
    ['Pognano', 'G774'],
    ['Ponte Nossa', 'F941'],
    ['Ponteranica', 'G853'],
    ['Ponte San Pietro', 'G856'],
    ['Pontida', 'G864'],
    ['Pontirolo Nuovo', 'G867'],
    ['Pradalunga', 'G968'],
    ['Predore', 'H020'],
    ['Premolo', 'H036'],
    ['Presezzo', 'H046'],
    ['Pumenengo', 'H091'],
    ['Ranica', 'H176'],
    ['Ranzanico', 'H177'],
    ['Riva di Solto', 'H331'],
    ['Rogno', 'H492'],
    ['Romano di Lombardia', 'H509'],
    ['Roncobello', 'H535'],
    ['Roncola', 'H544'],
    ["Rota d'Imagna", 'H584'],
    ['Rovetta', 'H615'],
    ['San Giovanni Bianco', 'H910'],
    ["San Paolo d'Argon", 'B310'],
    ['San Pellegrino Terme', 'I079'],
    ['Santa Brigida', 'I168'],
    ['Sarnico', 'I437'],
    ['Scanzorosciate', 'I506'],
    ['Schilpario', 'I530'],
    ['Sedrina', 'I567'],
    ['Selvino', 'I597'],
    ['Seriate', 'I628'],
    ['Serina', 'I629'],
    ['Solto Collina', 'I812'],
    ['Songavazzo', 'I830'],
    ['Sorisole', 'I858'],
    ['Sotto il Monte Giovanni XXIII', 'I869'],
    ['Sovere', 'I873'],
    ['Spinone al Lago', 'I916'],
    ['Spirano', 'I919'],
    ['Stezzano', 'I951'],
    ['Strozza', 'I986'],
    ['Suisio', 'I997'],
    ['Taleggio', 'L037'],
    ['Tavernola Bergamasca', 'L073'],
    ['Telgate', 'L087'],
    ["Terno d'Isola", 'L118'],
    ['Torre Boldone', 'L251'],
    ["Torre de' Busi", 'L257'],
    ["Torre de' Roveri", 'L265'],
    ['Torre Pallavicina', 'L276'],
    ['Trescore Balneario', 'L388'],
    ['Treviglio', 'L400'],
    ['Treviolo', 'L404'],
    ['Ubiale Clanezzo', 'C789'],
    ['Urgnano', 'L502'],
    ['Valbondione', 'L544'],
    ['Valbrembo', 'L545'],
    ['Valgoglio', 'L579'],
    ['Valleve', 'L623'],
    ['Valnegra', 'L642'],
    ['Valtorta', 'L655'],
    ['Vedeseta', 'L707'],
    ['Verdellino', 'L752'],
    ['Verdello', 'L753'],
    ['Vertova', 'L795'],
    ['Viadanica', 'L827'],
    ['Vigano San Martino', 'L865'],
    ['Vigolo', 'L894'],
    ["Villa d'Adda", 'L929'],
    ["Villa d'Almè", 'A215'],
    ['Villa di Serio', 'L936'],
    ["Villa d'Ogna", 'L938'],
    ['Villongo', 'M045'],
    ['Vilminore di Scalve', 'M050'],
    ['Zandobbio', 'M144'],
    ['Zanica', 'M147'],
    ['Zogno', 'M184'],
    ['Costa Serina', 'D111'],
    ['Algua', 'A193'],
    ['Cornalba', 'D016'],
    ['Medolago', 'F085'],
    ['Solza', 'I813'],
    ["Sant'Omobono Terme", 'M333'],
    ['Val Brembilla', 'M334'],
  ],
  BS: [
    ['Acquafredda', 'A034'],
    ['Adro', 'A060'],
    ['Agnosine', 'A082'],
    ['Alfianello', 'A188'],
    ['Anfo', 'A288'],
    ['Angolo Terme', 'A293'],
    ['Artogne', 'A451'],
    ['Azzano Mella', 'A529'],
    ['Bagnolo Mella', 'A569'],
    ['Bagolino', 'A578'],
    ['Barbariga', 'A630'],
    ['Barghe', 'A661'],
    ['Bassano Bresciano', 'A702'],
    ['Bedizzole', 'A729'],
    ['Berlingo', 'A799'],
    ['Berzo Demo', 'A816'],
    ['Berzo Inferiore', 'A817'],
    ['Bienno', 'A861'],
    ['Bione', 'A878'],
    ['Borgo San Giacomo', 'B035'],
    ['Borgosatollo', 'B040'],
    ['Borno', 'B054'],
    ['Botticino', 'B091'],
    ['Bovegno', 'B100'],
    ['Bovezzo', 'B102'],
    ['Brandico', 'B120'],
    ['Braone', 'B124'],
    ['Breno', 'B149'],
    ['Brescia', 'B157'],
    ['Brione', 'B184'],
    ['Caino', 'B365'],
    ['Calcinato', 'B394'],
    ['Calvagese della Riviera', 'B436'],
    ['Calvisano', 'B450'],
    ['Capo di Ponte', 'B664'],
    ['Capovalle', 'B676'],
    ['Capriano del Colle', 'B698'],
    ['Capriolo', 'B711'],
    ['Carpenedolo', 'B817'],
    ['Castegnato', 'C055'],
    ['Castelcovati', 'C072'],
    ['Castel Mella', 'C208'],
    ['Castenedolo', 'C293'],
    ['Casto', 'C330'],
    ['Castrezzato', 'C332'],
    ['Cazzago San Martino', 'C408'],
    ['Cedegolo', 'C417'],
    ['Cellatica', 'C439'],
    ['Cerveno', 'C549'],
    ['Ceto', 'C585'],
    ['Cevo', 'C591'],
    ['Chiari', 'C618'],
    ['Cigole', 'C685'],
    ['Cimbergo', 'C691'],
    ['Cividate Camuno', 'C760'],
    ['Coccaglio', 'C806'],
    ['Collebeato', 'C850'],
    ['Collio', 'C883'],
    ['Cologne', 'C893'],
    ['Comezzano-Cizzago', 'C925'],
    ['Concesio', 'C948'],
    ['Corte Franca', 'D058'],
    ['Corteno Golgi', 'D064'],
    ['Corzano', 'D082'],
    ['Darfo Boario Terme', 'D251'],
    ['Dello', 'D270'],
    ['Desenzano del Garda', 'D284'],
    ['Edolo', 'D391'],
    ['Erbusco', 'D421'],
    ['Esine', 'D434'],
    ['Fiesse', 'D576'],
    ['Flero', 'D634'],
    ['Gambara', 'D891'],
    ['Gardone Riviera', 'D917'],
    ['Gardone Val Trompia', 'D918'],
    ['Gargnano', 'D924'],
    ['Gavardo', 'D940'],
    ['Ghedi', 'D999'],
    ['Gianico', 'E010'],
    ['Gottolengo', 'E116'],
    ['Gussago', 'E271'],
    ['Idro', 'E280'],
    ['Incudine', 'E297'],
    ['Irma', 'E325'],
    ['Iseo', 'E333'],
    ['Isorella', 'E364'],
    ['Lavenone', 'E497'],
    ['Leno', 'E526'],
    ['Limone sul Garda', 'E596'],
    ['Lodrino', 'E652'],
    ['Lograto', 'E654'],
    ['Lonato del Garda', 'M312'],
    ['Longhena', 'E673'],
    ['Losine', 'E698'],
    ['Lozio', 'E706'],
    ['Lumezzane', 'E738'],
    ['Maclodio', 'E787'],
    ['Magasa', 'E800'],
    ['Mairano', 'E841'],
    ['Malegno', 'E851'],
    ['Malonno', 'E865'],
    ['Manerba del Garda', 'E883'],
    ['Manerbio', 'E884'],
    ['Marcheno', 'E928'],
    ['Marmentino', 'E961'],
    ['Marone', 'E967'],
    ['Mazzano', 'F063'],
    ['Milzano', 'F216'],
    ['Moniga del Garda', 'F373'],
    ['Monno', 'F375'],
    ['Monte Isola', 'F532'],
    ['Monticelli Brusati', 'F672'],
    ['Montichiari', 'F471'],
    ['Montirone', 'F680'],
    ['Mura', 'F806'],
    ['Muscoline', 'F820'],
    ['Nave', 'F851'],
    ['Niardo', 'F884'],
    ['Nuvolento', 'F989'],
    ['Nuvolera', 'F990'],
    ['Odolo', 'G001'],
    ['Offlaga', 'G006'],
    ['Ome', 'G061'],
    ['Ono San Pietro', 'G074'],
    ['Orzinuovi', 'G149'],
    ['Orzivecchi', 'G150'],
    ['Ospitaletto', 'G170'],
    ['Ossimo', 'G179'],
    ['Padenghe sul Garda', 'G213'],
    ['Paderno Franciacorta', 'G217'],
    ['Paisco Loveno', 'G247'],
    ['Paitone', 'G248'],
    ["Palazzolo sull'Oglio", 'G264'],
    ['Paratico', 'G327'],
    ['Paspardo', 'G354'],
    ['Passirano', 'G361'],
    ['Pavone del Mella', 'G391'],
    ['San Paolo', 'G407'],
    ['Pertica Alta', 'G474'],
    ['Pertica Bassa', 'G475'],
    ['Pezzaze', 'G529'],
    ['Pian Camuno', 'G546'],
    ['Pisogne', 'G710'],
    ['Polaveno', 'G779'],
    ['Polpenazze del Garda', 'G801'],
    ['Pompiano', 'G815'],
    ['Poncarale', 'G818'],
    ['Ponte di Legno', 'G844'],
    ['Pontevico', 'G859'],
    ['Pontoglio', 'G869'],
    ['Pozzolengo', 'G959'],
    ['Pralboino', 'G977'],
    ['Preseglie', 'H043'],
    ['Prevalle', 'H055'],
    ["Provaglio d'Iseo", 'H078'],
    ['Provaglio Val Sabbia', 'H077'],
    ['Puegnago sul Garda', 'H086'],
    ["Quinzano d'Oglio", 'H140'],
    ['Remedello', 'H230'],
    ['Rezzato', 'H256'],
    ['Roccafranca', 'H410'],
    ['Rodengo Saiano', 'H477'],
    ['Roè Volciano', 'H484'],
    ['Roncadelle', 'H525'],
    ['Rovato', 'H598'],
    ['Rudiano', 'H630'],
    ['Sabbio Chiese', 'H650'],
    ['Sale Marasino', 'H699'],
    ['Salò', 'H717'],
    ['San Felice del Benaco', 'H838'],
    ['San Gervasio Bresciano', 'H865'],
    ['San Zeno Naviglio', 'I412'],
    ['Sarezzo', 'I433'],
    ["Saviore dell'Adamello", 'I476'],
    ['Sellero', 'I588'],
    ['Seniga', 'I607'],
    ['Serle', 'I631'],
    ['Sirmione', 'I633'],
    ['Soiano del Lago', 'I782'],
    ['Sonico', 'I831'],
    ['Sulzano', 'L002'],
    ['Tavernole sul Mella', 'C698'],
    ['Temù', 'L094'],
    ['Tignale', 'L169'],
    ['Torbole Casaglia', 'L210'],
    ['Toscolano-Maderno', 'L312'],
    ['Travagliato', 'L339'],
    ['Tremosine sul Garda', 'L372'],
    ['Trenzano', 'L380'],
    ['Treviso Bresciano', 'L406'],
    ["Urago d'Oglio", 'L494'],
    ['Vallio Terme', 'L626'],
    ['Valvestino', 'L468'],
    ['Verolanuova', 'L777'],
    ['Verolavecchia', 'L778'],
    ['Vestone', 'L812'],
    ["Vezza d'Oglio", 'L816'],
    ['Villa Carcina', 'L919'],
    ['Villachiara', 'L923'],
    ['Villanuova sul Clisi', 'L995'],
    ['Vione', 'M065'],
    ['Visano', 'M070'],
    ['Vobarno', 'M104'],
    ['Zone', 'M188'],
    ['Piancogno', 'G549'],
  ],
  PV: [
    ['Alagna', 'A118'],
    ['Albaredo Arnaboldi', 'A134'],
    ['Albonese', 'A171'],
    ['Albuzzano', 'A175'],
    ['Arena Po', 'A387'],
    ['Badia Pavese', 'A538'],
    ['Bagnaria', 'A550'],
    ['Barbianello', 'A634'],
    ['Bascapè', 'A690'],
    ['Bastida Pancarana', 'A712'],
    ['Battuda', 'A718'],
    ['Belgioioso', 'A741'],
    ['Bereguardo', 'A792'],
    ['Borgarello', 'A989'],
    ['Borgo Priolo', 'B028'],
    ['Borgoratto Mormorolo', 'B030'],
    ['Borgo San Siro', 'B038'],
    ['Bornasco', 'B051'],
    ['Bosnasco', 'B082'],
    ['Brallo di Pregola', 'B117'],
    ['Breme', 'B142'],
    ['Bressana Bottarone', 'B159'],
    ['Broni', 'B201'],
    ['Calvignano', 'B447'],
    ['Campospinoso', 'B567'],
    ['Candia Lomellina', 'B587'],
    ['Canneto Pavese', 'B613'],
    ['Carbonara al Ticino', 'B741'],
    ['Casanova Lonati', 'B929'],
    ['Casatisma', 'B945'],
    ['Casei Gerola', 'B954'],
    ['Casorate Primo', 'B988'],
    ['Cassolnovo', 'C038'],
    ['Castana', 'C050'],
    ['Casteggio', 'C053'],
    ['Castelletto di Branduzzo', 'C157'],
    ["Castello d'Agogna", 'C184'],
    ['Castelnovetto', 'C213'],
    ['Cava Manara', 'C360'],
    ['Cecima', 'C414'],
    ['Ceranova', 'C484'],
    ['Ceretto Lomellina', 'C508'],
    ['Cergnago', 'C509'],
    ['Certosa di Pavia', 'C541'],
    ['Cervesina', 'C551'],
    ['Chignolo Po', 'C637'],
    ['Cigognola', 'C684'],
    ['Cilavegna', 'C686'],
    ['Codevilla', 'C813'],
    ['Confienza', 'C958'],
    ['Copiano', 'C979'],
    ['Corana', 'C982'],
    ['Corvino San Quirico', 'D081'],
    ["Costa de' Nobili", 'D109'],
    ['Cozzo', 'D127'],
    ['Cura Carpignano', 'B824'],
    ['Dorno', 'D348'],
    ['Ferrera Erbognone', 'D552'],
    ['Filighera', 'D594'],
    ['Fortunago', 'D732'],
    ['Frascarolo', 'D771'],
    ['Galliavola', 'D873'],
    ['Gambarana', 'D892'],
    ['Gambolò', 'D901'],
    ['Garlasco', 'D925'],
    ['Gerenzago', 'D980'],
    ['Giussago', 'E062'],
    ['Godiasco Salice Terme', 'E072'],
    ['Golferenzo', 'E081'],
    ['Gravellona Lomellina', 'E152'],
    ['Gropello Cairoli', 'E195'],
    ['Inverno e Monteleone', 'E310'],
    ['Landriano', 'E437'],
    ['Langosco', 'E439'],
    ['Lardirago', 'E454'],
    ['Linarolo', 'E600'],
    ['Lirio', 'E608'],
    ['Lomello', 'E662'],
    ['Lungavilla', 'B387'],
    ['Magherno', 'E804'],
    ['Marcignago', 'E934'],
    ['Marzano', 'E999'],
    ['Mede', 'F080'],
    ['Menconico', 'F122'],
    ['Mezzana Bigli', 'F170'],
    ['Mezzana Rabattone', 'F171'],
    ['Mezzanino', 'F175'],
    ['Miradolo Terme', 'F238'],
    ['Montalto Pavese', 'F417'],
    ['Montebello della Battaglia', 'F440'],
    ['Montecalvo Versiggia', 'F449'],
    ['Montescano', 'F638'],
    ['Montesegale', 'F644'],
    ['Monticelli Pavese', 'F670'],
    ['Montù Beccaria', 'F701'],
    ['Mornico Losana', 'F739'],
    ['Mortara', 'F754'],
    ['Nicorvo', 'F891'],
    ['Olevano di Lomellina', 'G021'],
    ['Oliva Gessi', 'G032'],
    ['Ottobiano', 'G194'],
    ['Palestro', 'G275'],
    ['Pancarana', 'G304'],
    ['Parona', 'G342'],
    ['Pavia', 'G388'],
    ["Pietra de' Giorgi", 'G612'],
    ['Pieve Albignola', 'G635'],
    ['Pieve del Cairo', 'G639'],
    ['Pieve Porto Morone', 'G650'],
    ['Pinarolo Po', 'G671'],
    ['Pizzale', 'G720'],
    ['Ponte Nizza', 'G851'],
    ['Portalbera', 'G895'],
    ['Rea', 'H204'],
    ['Redavalle', 'H216'],
    ['Retorbido', 'H246'],
    ['Rivanazzano Terme', 'H336'],
    ['Robbio', 'H369'],
    ['Robecco Pavese', 'H375'],
    ["Rocca de' Giorgi", 'H396'],
    ['Rocca Susella', 'H450'],
    ['Rognano', 'H491'],
    ['Romagnese', 'H505'],
    ['Roncaro', 'H527'],
    ['Rosasco', 'H559'],
    ['Rovescala', 'H614'],
    ['San Cipriano Po', 'H799'],
    ['San Damiano al Colle', 'H814'],
    ['San Genesio ed Uniti', 'H859'],
    ['San Giorgio di Lomellina', 'H885'],
    ['San Martino Siccomario', 'I014'],
    ["Sannazzaro de' Burgondi", 'I048'],
    ['Santa Cristina e Bissone', 'I175'],
    ['Santa Giuletta', 'I203'],
    ["Sant'Alessio con Vialone", 'I213'],
    ['Santa Margherita di Staffora', 'I230'],
    ['Santa Maria della Versa', 'I237'],
    ["Sant'Angelo Lomellina", 'I276'],
    ['San Zenone al Po', 'I416'],
    ['Sartirana Lomellina', 'I447'],
    ['Scaldasole', 'I487'],
    ['Semiana', 'I599'],
    ['Silvano Pietra', 'I739'],
    ['Siziano', 'E265'],
    ['Sommo', 'I825'],
    ['Spessa', 'I894'],
    ['Stradella', 'I968'],
    ['Suardi', 'B014'],
    ['Torrazza Coste', 'L237'],
    ['Torre Beretti e Castellaro', 'L250'],
    ["Torre d'Arese", 'L256'],
    ["Torre de' Negri", 'L262'],
    ["Torre d'Isola", 'L269'],
    ['Torrevecchia Pia', 'L285'],
    ['Torricella Verzate', 'L292'],
    ['Travacò Siccomario', 'I236'],
    ['Trivolzio', 'L440'],
    ['Tromello', 'L449'],
    ['Trovo', 'L453'],
    ['Val di Nizza', 'L562'],
    ['Valeggio', 'L568'],
    ['Valle Lomellina', 'L593'],
    ['Valle Salimbene', 'L617'],
    ['Varzi', 'L690'],
    ['Velezzo Lomellina', 'L716'],
    ['Vellezzo Bellini', 'L720'],
    ['Verretto', 'L784'],
    ['Verrua Po', 'L788'],
    ['Vidigulfo', 'L854'],
    ['Vigevano', 'L872'],
    ['Villa Biscossi', 'L917'],
    ["Villanova d'Ardenghi", 'L983'],
    ['Villanterio', 'L994'],
    ['Vistarino', 'M079'],
    ['Voghera', 'M109'],
    ['Volpara', 'M119'],
    ['Zavattarello', 'M150'],
    ['Zeccone', 'M152'],
    ['Zeme', 'M161'],
    ['Zenevredo', 'M162'],
    ['Zerbo', 'M166'],
    ['Zerbolò', 'M167'],
    ['Zinasco', 'M180'],
    ['Cornale e Bastida', 'M338'],
    ['Corteolona e Genzone', 'M372'],
    ['Colli Verdi', 'M419'],
  ],
  CR: [
    ['Acquanegra Cremonese', 'A039'],
    ['Agnadello', 'A076'],
    ['Annicco', 'A299'],
    ['Azzanello', 'A526'],
    ['Bagnolo Cremasco', 'A570'],
    ['Bonemerse', 'A972'],
    ['Bordolano', 'A986'],
    ['Calvatone', 'B439'],
    ['Camisano', 'B484'],
    ['Campagnola Cremasca', 'B498'],
    ['Capergnanica', 'B650'],
    ['Cappella Cantone', 'B679'],
    ["Cappella de' Picenardi", 'B680'],
    ['Capralba', 'B686'],
    ['Casalbuttano ed Uniti', 'B869'],
    ['Casale Cremasco-Vidolasco', 'B881'],
    ['Casaletto Ceredano', 'B889'],
    ['Casaletto di Sopra', 'B890'],
    ['Casaletto Vaprio', 'B891'],
    ['Casalmaggiore', 'B898'],
    ['Casalmorano', 'B900'],
    ['Casteldidone', 'C089'],
    ['Castel Gabbiano', 'C115'],
    ['Castelleone', 'C153'],
    ['Castelverde', 'B129'],
    ['Castelvisconti', 'C290'],
    ['Cella Dati', 'C435'],
    ['Chieve', 'C634'],
    ['Cicognolo', 'C678'],
    ["Cingia de' Botti", 'C703'],
    ["Corte de' Cortesi con Cignone", 'D056'],
    ["Corte de' Frati", 'D057'],
    ['Credera Rubbiano', 'D141'],
    ['Crema', 'D142'],
    ['Cremona', 'D150'],
    ['Cremosano', 'D151'],
    ["Crotta d'Adda", 'D186'],
    ['Cumignano sul Naviglio', 'D203'],
    ['Derovere', 'D278'],
    ['Dovera', 'D358'],
    ['Fiesco', 'D574'],
    ['Formigara', 'D710'],
    ['Gabbioneta-Binanuova', 'D834'],
    ['Gadesco-Pieve Delmona', 'D841'],
    ['Genivolta', 'D966'],
    ["Gerre de' Caprioli", 'D993'],
    ['Gombito', 'E082'],
    ['Grontardo', 'E193'],
    ['Grumello Cremonese ed Uniti', 'E217'],
    ['Gussola', 'E272'],
    ['Isola Dovarese', 'E356'],
    ['Izano', 'E380'],
    ['Madignano', 'E793'],
    ['Malagnino', 'E843'],
    ['Martignana di Po', 'E983'],
    ['Monte Cremasco', 'F434'],
    ['Montodine', 'F681'],
    ['Moscazzano', 'F761'],
    ['Motta Baluffi', 'F771'],
    ['Offanengo', 'G004'],
    ['Olmeneta', 'G047'],
    ['Ostiano', 'G185'],
    ['Paderno Ponchielli', 'G222'],
    ['Palazzo Pignano', 'G260'],
    ['Pandino', 'G306'],
    ['Persico Dosimo', 'G469'],
    ['Pescarolo ed Uniti', 'G483'],
    ['Pessina Cremonese', 'G504'],
    ['Pianengo', 'G558'],
    ['Pieranica', 'G603'],
    ["Pieve d'Olmi", 'G647'],
    ['Pieve San Giacomo', 'G651'],
    ['Pizzighettone', 'G721'],
    ['Pozzaglio ed Uniti', 'B914'],
    ['Quintano', 'H130'],
    ['Ricengo', 'H276'],
    ['Ripalta Arpina', 'H314'],
    ['Ripalta Cremasca', 'H315'],
    ['Ripalta Guerina', 'H316'],
    ['Rivarolo del Re ed Uniti', 'H341'],
    ["Rivolta d'Adda", 'H357'],
    ["Robecco d'Oglio", 'H372'],
    ['Romanengo', 'H508'],
    ['Salvirola', 'H731'],
    ['San Bassano', 'H767'],
    ['San Daniele Po', 'H815'],
    ['San Giovanni in Croce', 'H918'],
    ['San Martino del Lago', 'I007'],
    ['Scandolara Ravara', 'I497'],
    ["Scandolara Ripa d'Oglio", 'I498'],
    ['Sergnano', 'I627'],
    ['Sesto ed Uniti', 'I683'],
    ['Solarolo Rainerio', 'I790'],
    ['Soncino', 'I827'],
    ['Soresina', 'I849'],
    ['Sospiro', 'I865'],
    ['Spinadesco', 'I906'],
    ['Spineda', 'I909'],
    ["Spino d'Adda", 'I914'],
    ['Stagno Lombardo', 'I935'],
    ['Ticengo', 'L164'],
    ['Torlino Vimercati', 'L221'],
    ['Tornata', 'L225'],
    ["Torre de' Picenardi", 'L258'],
    ['Torricella del Pizzo', 'L296'],
    ['Trescore Cremasco', 'L389'],
    ['Trigolo', 'L426'],
    ['Vaiano Cremasco', 'L535'],
    ['Vailate', 'L539'],
    ['Vescovato', 'L806'],
    ['Volongo', 'M116'],
    ['Voltido', 'M127'],
    ['Piadena Drizzona', 'M418'],
  ],
  MN: [
    ['Acquanegra sul Chiese', 'A038'],
    ['Asola', 'A470'],
    ['Bagnolo San Vito', 'A575'],
    ['Bozzolo', 'B110'],
    ["Canneto sull'Oglio", 'B612'],
    ['Casalmoro', 'B901'],
    ['Casaloldo', 'B907'],
    ['Casalromano', 'B911'],
    ['Castelbelforte', 'C059'],
    ["Castel d'Ario", 'C076'],
    ['Castel Goffredo', 'C118'],
    ['Castellucchio', 'C195'],
    ['Castiglione delle Stiviere', 'C312'],
    ['Cavriana', 'C406'],
    ['Ceresara', 'C502'],
    ['Commessaggio', 'C930'],
    ['Curtatone', 'D227'],
    ['Dosolo', 'D351'],
    ['Gazoldo degli Ippoliti', 'D949'],
    ['Gazzuolo', 'D959'],
    ['Goito', 'E078'],
    ['Gonzaga', 'E089'],
    ['Guidizzolo', 'E261'],
    ['Magnacavallo', 'E818'],
    ['Mantova', 'E897'],
    ['Marcaria', 'E922'],
    ['Mariana Mantovana', 'E949'],
    ['Marmirolo', 'E962'],
    ['Medole', 'F086'],
    ['Moglia', 'F267'],
    ['Monzambano', 'F705'],
    ['Motteggiana', 'B012'],
    ['Ostiglia', 'G186'],
    ['Pegognaga', 'G417'],
    ['Piubega', 'G717'],
    ['Poggio Rusco', 'G753'],
    ['Pomponesco', 'G816'],
    ['Ponti sul Mincio', 'G862'],
    ['Porto Mantovano', 'G917'],
    ['Quingentole', 'H129'],
    ['Quistello', 'H143'],
    ['Redondesco', 'H218'],
    ['Rivarolo Mantovano', 'H342'],
    ['Rodigo', 'H481'],
    ['Roncoferraro', 'H541'],
    ['Roverbella', 'H604'],
    ['Sabbioneta', 'H652'],
    ['San Benedetto Po', 'H771'],
    ['San Giacomo delle Segnate', 'H870'],
    ['San Giorgio Bigarello', 'H883'],
    ['San Giovanni del Dosso', 'H912'],
    ["San Martino dall'Argine", 'I005'],
    ['Schivenoglia', 'I532'],
    ['Sermide e Felonica', 'I632'],
    ['Serravalle a Po', 'I662'],
    ['Solferino', 'I801'],
    ['Sustinente', 'L015'],
    ['Suzzara', 'L020'],
    ['Viadana', 'L826'],
    ['Villimpenta', 'M044'],
    ['Volta Mantovana', 'M125'],
    ['Borgo Virgilio', 'M340'],
    ['Borgo Mantovano', 'M396'],
    ['Borgocarbonara', 'M406'],
  ],
  LC: [
    ['Abbadia Lariana', 'A005'],
    ['Airuno', 'A112'],
    ['Annone di Brianza', 'A301'],
    ['Ballabio', 'A594'],
    ['Barzago', 'A683'],
    ['Barzanò', 'A686'],
    ['Barzio', 'A687'],
    ['Bellano', 'A745'],
    ['Bosisio Parini', 'B081'],
    ['Brivio', 'B194'],
    ['Bulciago', 'B261'],
    ['Calco', 'B396'],
    ['Calolziocorte', 'B423'],
    ['Carenno', 'B763'],
    ['Casargo', 'B937'],
    ['Casatenovo', 'B943'],
    ['Cassago Brianza', 'B996'],
    ['Cassina Valsassina', 'C024'],
    ['Castello di Brianza', 'C187'],
    ['Cernusco Lombardone', 'C521'],
    ['Cesana Brianza', 'C563'],
    ['Civate', 'C752'],
    ['Colico', 'C839'],
    ['Colle Brianza', 'C851'],
    ['Cortenova', 'D065'],
    ['Costa Masnaga', 'D112'],
    ['Crandola Valsassina', 'D131'],
    ['Cremella', 'D143'],
    ['Cremeno', 'D145'],
    ['Dervio', 'D280'],
    ['Dolzago', 'D327'],
    ['Dorio', 'D346'],
    ['Ello', 'D398'],
    ['Erve', 'D428'],
    ['Esino Lario', 'D436'],
    ['Galbiate', 'D865'],
    ['Garbagnate Monastero', 'D913'],
    ['Garlate', 'D926'],
    ['Imbersago', 'E287'],
    ['Introbio', 'E305'],
    ['Lecco', 'E507'],
    ['Lierna', 'E581'],
    ['Lomagna', 'E656'],
    ['Malgrate', 'E858'],
    ['Mandello del Lario', 'E879'],
    ['Margno', 'E947'],
    ['Merate', 'F133'],
    ['Missaglia', 'F248'],
    ['Moggio', 'F265'],
    ['Molteno', 'F304'],
    ['Monte Marenzo', 'F561'],
    ['Montevecchia', 'F657'],
    ['Monticello Brianza', 'F674'],
    ['Morterone', 'F758'],
    ['Nibionno', 'F887'],
    ['Oggiono', 'G009'],
    ['Olgiate Molgora', 'G026'],
    ['Olginate', 'G030'],
    ['Oliveto Lario', 'G040'],
    ['Osnago', 'G161'],
    ["Paderno d'Adda", 'G218'],
    ['Pagnona', 'G241'],
    ['Parlasco', 'G336'],
    ['Pasturo', 'G368'],
    ['Perledo', 'G456'],
    ['Pescate', 'G485'],
    ['Premana', 'H028'],
    ['Primaluna', 'H063'],
    ['Robbiate', 'G223'],
    ['Rogeno', 'H486'],
    ['Santa Maria Hoè', 'I243'],
    ['Sirone', 'I759'],
    ['Sirtori', 'I761'],
    ['Sueglio', 'I994'],
    ['Suello', 'I996'],
    ['Taceno', 'L022'],
    ['Valgreghentino', 'L581'],
    ['Valmadrera', 'L634'],
    ['Varenna', 'L680'],
    ['Vendrogno', 'L731'],
    ['Vercurago', 'L751'],
    ['Viganò', 'L866'],
    ['Verderio', 'M337'],
    ['La Valletta Brianza', 'M348'],
    ['Valvarrone', 'M395'],
  ],
  LO: [
    ['Abbadia Cerreto', 'A004'],
    ['Bertonico', 'A811'],
    ["Boffalora d'Adda", 'A919'],
    ['Borghetto Lodigiano', 'A995'],
    ['Borgo San Giovanni', 'B017'],
    ['Brembio', 'B141'],
    ['Casaletto Lodigiano', 'B887'],
    ['Casalmaiocco', 'B899'],
    ['Casalpusterlengo', 'B910'],
    ['Caselle Landi', 'B961'],
    ['Caselle Lurani', 'B958'],
    ["Castelnuovo Bocca d'Adda", 'C228'],
    ["Castiglione d'Adda", 'C304'],
    ['Castiraga Vidardo', 'C329'],
    ["Cavenago d'Adda", 'C394'],
    ["Cervignano d'Adda", 'C555'],
    ['Codogno', 'C816'],
    ['Comazzo', 'C917'],
    ['Cornegliano Laudense', 'D021'],
    ['Corno Giovine', 'D028'],
    ['Cornovecchio', 'D029'],
    ['Corte Palasio', 'D068'],
    ['Crespiatica', 'D159'],
    ['Fombio', 'D660'],
    ['Galgagnano', 'D868'],
    ['Graffignana', 'E127'],
    ['Guardamiglio', 'E238'],
    ['Livraga', 'E627'],
    ['Lodi', 'E648'],
    ['Lodi Vecchio', 'E651'],
    ['Maccastorna', 'E777'],
    ['Mairago', 'E840'],
    ['Maleo', 'E852'],
    ['Marudo', 'E994'],
    ['Massalengo', 'F028'],
    ['Meleti', 'F102'],
    ['Merlino', 'F149'],
    ['Montanaso Lombardo', 'F423'],
    ['Mulazzano', 'F801'],
    ['Orio Litta', 'G107'],
    ['Ospedaletto Lodigiano', 'G166'],
    ['Ossago Lodigiano', 'G171'],
    ['Pieve Fissiraga', 'G096'],
    ['Salerano sul Lambro', 'H701'],
    ['San Fiorano', 'H844'],
    ['San Martino in Strada', 'I012'],
    ['San Rocco al Porto', 'I140'],
    ["Sant'Angelo Lodigiano", 'I274'],
    ['Santo Stefano Lodigiano', 'I362'],
    ['Secugnago', 'I561'],
    ['Senna Lodigiana', 'I612'],
    ['Somaglia', 'I815'],
    ['Sordio', 'I848'],
    ['Tavazzano con Villavesco', 'F260'],
    ['Terranova dei Passerini', 'L125'],
    ['Turano Lodigiano', 'L469'],
    ['Valera Fratta', 'L572'],
    ['Villanova del Sillaro', 'L977'],
    ['Zelo Buon Persico', 'M158'],
    ['Castelgerundo', 'M393'],
  ],
  MB: [
    ['Agrate Brianza', 'A087'],
    ['Aicurzio', 'A096'],
    ['Albiate', 'A159'],
    ['Arcore', 'A376'],
    ['Barlassina', 'A668'],
    ['Bellusco', 'A759'],
    ['Bernareggio', 'A802'],
    ['Besana in Brianza', 'A818'],
    ['Biassono', 'A849'],
    ['Bovisio-Masciago', 'B105'],
    ['Briosco', 'B187'],
    ['Brugherio', 'B212'],
    ['Burago di Molgora', 'B272'],
    ['Camparada', 'B501'],
    ['Carate Brianza', 'B729'],
    ['Carnate', 'B798'],
    ['Cavenago di Brianza', 'C395'],
    ['Ceriano Laghetto', 'C512'],
    ['Cesano Maderno', 'C566'],
    ['Cogliate', 'C820'],
    ['Concorezzo', 'C952'],
    ['Correzzana', 'D038'],
    ['Desio', 'D286'],
    ['Giussano', 'E063'],
    ['Lazzate', 'E504'],
    ['Lesmo', 'E550'],
    ['Limbiate', 'E591'],
    ['Lissone', 'E617'],
    ['Macherio', 'E786'],
    ['Meda', 'F078'],
    ['Mezzago', 'F165'],
    ['Misinto', 'F247'],
    ['Monza', 'F704'],
    ['Muggiò', 'F797'],
    ['Nova Milanese', 'F944'],
    ['Ornago', 'G116'],
    ['Renate', 'H233'],
    ['Ronco Briantino', 'H537'],
    ['Seregno', 'I625'],
    ['Seveso', 'I709'],
    ['Sovico', 'I878'],
    ['Sulbiate', 'I998'],
    ['Triuggio', 'L434'],
    ['Usmate Velate', 'L511'],
    ['Varedo', 'L677'],
    ['Vedano al Lambro', 'L704'],
    ['Veduggio con Colzano', 'L709'],
    ['Verano Brianza', 'L744'],
    ['Villasanta', 'M017'],
    ['Vimercate', 'M052'],
    ['Busnago', 'B289'],
    ['Caponago', 'B671'],
    ["Cornate d'Adda", 'D019'],
    ['Lentate sul Seveso', 'E530'],
    ['Roncello', 'H529'],
  ],
  BZ: [
    ['Aldino/Aldein', 'A179'],
    ['Andriano/Andrian', 'A286'],
    ['Anterivo/Altrei', 'A306'],
    ['Appiano sulla strada del vino/Eppan an der Weinstraße', 'A332'],
    ['Avelengo/Hafling', 'A507'],
    ['Badia/Abtei', 'A537'],
    ['Barbiano/Barbian', 'A635'],
    ['Bolzano/Bozen', 'A952'],
    ['Braies/Prags', 'B116'],
    ['Brennero/Brenner', 'B145'],
    ['Bressanone/Brixen', 'B160'],
    ['Bronzolo/Branzoll', 'B203'],
    ['Brunico/Bruneck', 'B220'],
    ['Caines/Kuens', 'B364'],
    ['Caldaro sulla strada del vino/Kaltern an der Weinstraße', 'B397'],
    ['Campo di Trens/Freienfeld', 'B529'],
    ['Campo Tures/Sand in Taufers', 'B570'],
    ['Castelbello-Ciardes/Kastelbell-Tschars', 'C062'],
    ['Castelrotto/Kastelruth', 'C254'],
    ['Cermes/Tscherms', 'A022'],
    ['Chienes/Kiens', 'C625'],
    ['Chiusa/Klausen', 'C652'],
    ["Cornedo all'Isarco/Karneid", 'B799'],
    ['Cortaccia sulla strada del vino/Kurtatsch an der Weinstraße', 'D048'],
    ['Cortina sulla strada del vino/Kurtinig an der Weinstraße', 'D075'],
    ['Corvara in Badia/Corvara', 'D079'],
    ['Curon Venosta/Graun im Vinschgau', 'D222'],
    ['Dobbiaco/Toblach', 'D311'],
    ['Egna/Neumarkt', 'D392'],
    ['Falzes/Pfalzen', 'D484'],
    ['Fiè allo Sciliar/Völs am Schlern', 'D571'],
    ['Fortezza/Franzensfeste', 'D731'],
    ['Funes/Villnöß', 'D821'],
    ['Gais/Gais', 'D860'],
    ['Gargazzone/Gargazon', 'D923'],
    ['Glorenza/Glurns', 'E069'],
    ['Laces/Latsch', 'E398'],
    ['Lagundo/Algund', 'E412'],
    ['Laion/Lajen', 'E420'],
    ['Laives/Leifers', 'E421'],
    ['Lana/Lana', 'E434'],
    ['Lasa/Laas', 'E457'],
    ['Lauregno/Laurein', 'E481'],
    ['Luson/Lüsen', 'E764'],
    ['Magrè sulla strada del vino/Margreid an der Weinstraße', 'E829'],
    ['Malles Venosta/Mals', 'E862'],
    ['Marebbe/Enneberg', 'E938'],
    ['Marlengo/Marling', 'E959'],
    ['Martello/Martell', 'E981'],
    ['Meltina/Mölten', 'F118'],
    ['Merano/Meran', 'F132'],
    ['Monguelfo-Tesido/Welsberg-Taisten', 'F371'],
    ['Montagna/Montan', 'F392'],
    ['Moso in Passiria/Moos in Passeier', 'F766'],
    ['Nalles/Nals', 'F836'],
    ['Naturno/Naturns', 'F849'],
    ['Naz-Sciaves/Natz-Schabs', 'F856'],
    ['Nova Levante/Welschnofen', 'F949'],
    ['Nova Ponente/Deutschnofen', 'F950'],
    ['Ora/Auer', 'G083'],
    ['Ortisei/St. Ulrich', 'G140'],
    ['Parcines/Partschins', 'G328'],
    ['Perca/Percha', 'G443'],
    ['Plaus/Plaus', 'G299'],
    ['Ponte Gardena/Waidbruck', 'G830'],
    ['Postal/Burgstall', 'G936'],
    ['Prato allo Stelvio/Prad am Stilfserjoch', 'H004'],
    ['Predoi/Prettau', 'H019'],
    ['Proves/Proveis', 'H081'],
    ['Racines/Ratschings', 'H152'],
    ['Rasun-Anterselva/Rasen-Antholz', 'H189'],
    ['Renon/Ritten', 'H236'],
    ['Rifiano/Riffian', 'H284'],
    ['Rio di Pusteria/Mühlbach', 'H299'],
    ['Rodengo/Rodeneck', 'H475'],
    ['Salorno/Salurn', 'H719'],
    ['San Candido/Innichen', 'H786'],
    ['San Genesio Atesino/Jenesien', 'H858'],
    ['San Leonardo in Passiria/St. Leonhard in Passeier', 'H952'],
    ['San Lorenzo di Sebato/St. Lorenzen', 'H956'],
    ['San Martino in Badia/St. Martin in Thurn', 'H988'],
    ['San Martino in Passiria/St. Martin in Passeier', 'H989'],
    ['San Pancrazio/St. Pankraz', 'I065'],
    ['Santa Cristina Valgardena/St. Christina in Gröden', 'I173'],
    ['Sarentino/Sarntal', 'I431'],
    ['Scena/Schenna', 'I519'],
    ['Selva dei Molini/Mühlwald', 'I593'],
    ['Selva di Val Gardena/Wolkenstein in Gröden', 'I591'],
    ['Senales/Schnals', 'I604'],
    ['Sesto/Sexten', 'I687'],
    ['Silandro/Schlanders', 'I729'],
    ['Sluderno/Schluderns', 'I771'],
    ['Stelvio/Stilfs', 'I948'],
    ['Terento/Terenten', 'L106'],
    ['Terlano/Terlan', 'L108'],
    ['Termeno sulla strada del vino/Tramin an der Weinstraße', 'L111'],
    ['Tesimo/Tisens', 'L149'],
    ['Tires/Tiers', 'L176'],
    ['Tirolo/Tirol', 'L178'],
    ['Trodena nel parco naturale/Truden im Naturpark', 'L444'],
    ['Tubre/Taufers im Münstertal', 'L455'],
    ['Ultimo/Ulten', 'L490'],
    ['Vadena/Pfatten', 'L527'],
    ['Valdaora/Olang', 'L552'],
    ['Val di Vizze/Pfitsch', 'L564'],
    ['Valle Aurina/Ahrntal', 'L595'],
    ['Valle di Casies/Gsies', 'L601'],
    ['Vandoies/Vintl', 'L660'],
    ['Varna/Vahrn', 'L687'],
    ['Verano/Vöran', 'L745'],
    ['Villabassa/Niederdorf', 'L915'],
    ['Villandro/Villanders', 'L971'],
    ['Vipiteno/Sterzing', 'M067'],
    ['Velturno/Feldthurns', 'L724'],
    ['La Valle/Wengen', 'E491'],
    ['Senale-San Felice/Unsere Liebe Frau im Walde-St. Felix', 'I603'],
  ],
  TN: [
    ['Ala', 'A116'],
    ['Albiano', 'A158'],
    ['Aldeno', 'A178'],
    ['Andalo', 'A274'],
    ['Arco', 'A372'],
    ['Avio', 'A520'],
    ['Baselga di Pinè', 'A694'],
    ['Bedollo', 'A730'],
    ['Besenello', 'A821'],
    ['Bieno', 'A863'],
    ['Bleggio Superiore', 'A902'],
    ['Bocenago', 'A916'],
    ['Bondone', 'A968'],
    ['Borgo Valsugana', 'B006'],
    ['Brentonico', 'B153'],
    ['Bresimo', 'B158'],
    ['Brez', 'B165'],
    ['Caderzone Terme', 'B335'],
    ['Cagnò', 'B360'],
    ['Calceranica al Lago', 'B389'],
    ['Caldes', 'B400'],
    ['Caldonazzo', 'B404'],
    ['Calliano', 'B419'],
    ['Campitello di Fassa', 'B514'],
    ['Campodenno', 'B525'],
    ['Canal San Bovo', 'B577'],
    ['Canazei', 'B579'],
    ['Capriana', 'B697'],
    ['Carano', 'B723'],
    ['Carisolo', 'B783'],
    ['Carzano', 'B856'],
    ['Castel Condino', 'C183'],
    ['Castelfondo', 'C103'],
    ['Castello-Molina di Fiemme', 'C189'],
    ['Castello Tesino', 'C194'],
    ['Castelnuovo', 'C216'],
    ['Cavalese', 'C372'],
    ['Cavareno', 'C380'],
    ['Cavedago', 'C392'],
    ['Cavedine', 'C393'],
    ['Cavizzana', 'C400'],
    ['Cimone', 'C700'],
    ['Cinte Tesino', 'C712'],
    ['Cis', 'C727'],
    ['Civezzano', 'C756'],
    ['Cles', 'C794'],
    ['Cloz', 'C797'],
    ['Commezzadura', 'C931'],
    ['Croviana', 'D188'],
    ['Daiano', 'D243'],
    ['Dambel', 'D246'],
    ['Denno', 'D273'],
    ['Drena', 'D365'],
    ['Dro', 'D371'],
    ['Faedo', 'D457'],
    ['Fai della Paganella', 'D468'],
    ['Fiavè', 'D565'],
    ['Fierozzo', 'D573'],
    ['Folgaria', 'D651'],
    ['Fondo', 'D663'],
    ['Fornace', 'D714'],
    ['Frassilongo', 'D775'],
    ['Garniga Terme', 'D928'],
    ['Giovo', 'E048'],
    ['Giustino', 'E065'],
    ['Grigno', 'E178'],
    ['Imer', 'E288'],
    ['Isera', 'E334'],
    ['Lavarone', 'E492'],
    ['Lavis', 'E500'],
    ['Levico Terme', 'E565'],
    ['Livo', 'E624'],
    ['Lona-Lases', 'E664'],
    ['Luserna', 'E757'],
    ['Malé', 'E850'],
    ['Malosco', 'E866'],
    ['Massimeno', 'F045'],
    ['Mazzin', 'F068'],
    ['Mezzana', 'F168'],
    ['Mezzano', 'F176'],
    ['Mezzocorona', 'F183'],
    ['Mezzolombardo', 'F187'],
    ['Moena', 'F263'],
    ['Molveno', 'F307'],
    ['Mori', 'F728'],
    ['Nago-Torbole', 'F835'],
    ['Nogaredo', 'F920'],
    ['Nomi', 'F929'],
    ['Novaledo', 'F947'],
    ['Ospedaletto', 'G168'],
    ['Ossana', 'G173'],
    ['Palù del Fersina', 'G296'],
    ['Panchià', 'G305'],
    ['Ronzo-Chienis', 'M303'],
    ['Peio', 'G419'],
    ['Pellizzano', 'G428'],
    ['Pelugo', 'G429'],
    ['Pergine Valsugana', 'G452'],
    ['Pieve Tesino', 'G656'],
    ['Pinzolo', 'G681'],
    ['Pomarolo', 'G808'],
    ['Predazzo', 'H018'],
    ['Rabbi', 'H146'],
    ['Revò', 'H254'],
    ['Riva del Garda', 'H330'],
    ['Romallo', 'H506'],
    ['Romeno', 'H517'],
    ['Roncegno Terme', 'H528'],
    ['Ronchi Valsugana', 'H532'],
    ['Ronzone', 'H552'],
    ['Roverè della Luna', 'H607'],
    ['Rovereto', 'H612'],
    ['Ruffrè-Mendola', 'H634'],
    ['Rumo', 'H639'],
    ['Sagron Mis', 'H666'],
    ['Samone', 'H754'],
    ["San Michele all'Adige", 'I042'],
    ["Sant'Orsola Terme", 'I354'],
    ['Sanzeno', 'I411'],
    ['Sarnonico', 'I439'],
    ['Scurelle', 'I554'],
    ['Segonzano', 'I576'],
    ['Sfruz', 'I714'],
    ['Soraga di Fassa', 'I839'],
    ['Sover', 'I871'],
    ['Spiazzo', 'I899'],
    ['Spormaggiore', 'I924'],
    ['Sporminore', 'I925'],
    ['Stenico', 'I949'],
    ['Storo', 'I964'],
    ['Strembo', 'I975'],
    ['Telve', 'L089'],
    ['Telve di Sopra', 'L090'],
    ['Tenna', 'L096'],
    ['Tenno', 'L097'],
    ['Terragnolo', 'L121'],
    ['Terzolas', 'L145'],
    ['Tesero', 'L147'],
    ['Tione di Trento', 'L174'],
    ['Ton', 'L200'],
    ['Torcegno', 'L211'],
    ['Trambileno', 'L322'],
    ['Trento', 'L378'],
    ['Valfloriana', 'L575'],
    ['Vallarsa', 'L588'],
    ['Varena', 'L678'],
    ['Vermiglio', 'L769'],
    ['Vignola-Falesina', 'L886'],
    ['Villa Lagarina', 'L957'],
    ['Volano', 'M113'],
    ['Ziano di Fiemme', 'M173'],
    ['Comano Terme', 'M314'],
    ['Ledro', 'M313'],
    ['Predaia', 'M344'],
    ['San Lorenzo Dorsino', 'M345'],
    ['Valdaone', 'M343'],
    ['Dimaro Folgarida', 'M366'],
    ['Pieve di Bono-Prezzo', 'M365'],
    ['Altavalle', 'M349'],
    ['Altopiano della Vigolana', 'M350'],
    ['Amblar-Don', 'M351'],
    ['Borgo Chiese', 'M352'],
    ['Borgo Lares', 'M353'],
    ['Castel Ivano', 'M354'],
    ['Cembra Lisignago', 'M355'],
    ['Contà', 'M356'],
    ['Madruzzo', 'M357'],
    ['Porte di Rendena', 'M358'],
    ['Primiero San Martino di Castrozza', 'M359'],
    ['Sella Giudicarie', 'M360'],
    ['Tre Ville', 'M361'],
    ['Vallelaghi', 'M362'],
    ["Ville d'Anaunia", 'M363'],
    ['San Giovanni di Fassa-Sèn Jan', 'M390'],
    ["Terre d'Adige", 'M407'],
  ],
  VR: [
    ['Affi', 'A061'],
    ["Albaredo d'Adige", 'A137'],
    ['Angiari', 'A292'],
    ['Arcole', 'A374'],
    ['Badia Calavena', 'A540'],
    ['Bardolino', 'A650'],
    ['Belfiore', 'A737'],
    ['Bevilacqua', 'A837'],
    ['Bonavigo', 'A964'],
    ["Boschi Sant'Anna", 'B070'],
    ['Bosco Chiesanuova', 'B073'],
    ['Bovolone', 'B107'],
    ['Brentino Belluno', 'B152'],
    ['Brenzone sul Garda', 'B154'],
    ['Bussolengo', 'B296'],
    ['Buttapietra', 'B304'],
    ['Caldiero', 'B402'],
    ['Caprino Veronese', 'B709'],
    ['Casaleone', 'B886'],
    ['Castagnaro', 'C041'],
    ["Castel d'Azzano", 'C078'],
    ['Castelnuovo del Garda', 'C225'],
    ['Cavaion Veronese', 'C370'],
    ['Cazzano di Tramigna', 'C412'],
    ['Cerea', 'C498'],
    ['Cerro Veronese', 'C538'],
    ['Cologna Veneta', 'C890'],
    ['Colognola ai Colli', 'C897'],
    ['Concamarise', 'C943'],
    ['Costermano sul Garda', 'D118'],
    ['Dolcè', 'D317'],
    ['Erbè', 'D419'],
    ['Erbezzo', 'D420'],
    ['Ferrara di Monte Baldo', 'D549'],
    ['Fumane', 'D818'],
    ['Garda', 'D915'],
    ['Gazzo Veronese', 'D957'],
    ['Grezzana', 'E171'],
    ['Illasi', 'E284'],
    ['Isola della Scala', 'E349'],
    ['Isola Rizza', 'E358'],
    ['Lavagno', 'E489'],
    ['Lazise', 'E502'],
    ['Legnago', 'E512'],
    ['Malcesine', 'E848'],
    ['Marano di Valpolicella', 'E911'],
    ['Mezzane di Sotto', 'F172'],
    ['Minerbe', 'F218'],
    ['Montecchia di Crosara', 'F461'],
    ["Monteforte d'Alpone", 'F508'],
    ['Mozzecane', 'F789'],
    ['Negrar di Valpolicella', 'F861'],
    ['Nogara', 'F918'],
    ['Nogarole Rocca', 'F921'],
    ['Oppeano', 'G080'],
    ['Palù', 'G297'],
    ['Pastrengo', 'G365'],
    ['Pescantina', 'G481'],
    ['Peschiera del Garda', 'G489'],
    ['Povegliano Veronese', 'G945'],
    ['Pressana', 'H048'],
    ['Rivoli Veronese', 'H356'],
    ['Roncà', 'H522'],
    ["Ronco all'Adige", 'H540'],
    ['Roverchiara', 'H606'],
    ['Roveredo di Guà', 'H610'],
    ['Roverè Veronese', 'H608'],
    ['Salizzole', 'H714'],
    ['San Bonifacio', 'H783'],
    ['San Giovanni Ilarione', 'H916'],
    ['San Giovanni Lupatoto', 'H924'],
    ['Sanguinetto', 'H944'],
    ['San Martino Buon Albergo', 'I003'],
    ['San Mauro di Saline', 'H712'],
    ['San Pietro di Morubio', 'I105'],
    ['San Pietro in Cariano', 'I109'],
    ["Sant'Ambrogio di Valpolicella", 'I259'],
    ["Sant'Anna d'Alfaedo", 'I292'],
    ['San Zeno di Montagna', 'I414'],
    ['Selva di Progno', 'I594'],
    ['Soave', 'I775'],
    ['Sommacampagna', 'I821'],
    ['Sona', 'I826'],
    ['Sorgà', 'I850'],
    ['Terrazzo', 'L136'],
    ['Torri del Benaco', 'L287'],
    ['Tregnago', 'L364'],
    ['Trevenzuolo', 'L396'],
    ['Valeggio sul Mincio', 'L567'],
    ['Velo Veronese', 'L722'],
    ['Verona', 'L781'],
    ['Veronella', 'D193'],
    ['Vestenanova', 'L810'],
    ['Vigasio', 'L869'],
    ['Villa Bartolomea', 'L912'],
    ['Villafranca di Verona', 'L949'],
    ['Zevio', 'M172'],
    ['Zimella', 'M178'],
  ],
  VI: [
    ['Agugliaro', 'A093'],
    ['Albettone', 'A154'],
    ['Alonte', 'A220'],
    ['Altavilla Vicentina', 'A231'],
    ['Altissimo', 'A236'],
    ['Arcugnano', 'A377'],
    ['Arsiero', 'A444'],
    ['Arzignano', 'A459'],
    ['Asiago', 'A465'],
    ['Asigliano Veneto', 'A467'],
    ['Bassano del Grappa', 'A703'],
    ['Bolzano Vicentino', 'A954'],
    ['Breganze', 'B132'],
    ['Brendola', 'B143'],
    ['Bressanvido', 'B161'],
    ['Brogliano', 'B196'],
    ['Caldogno', 'B403'],
    ['Caltrano', 'B433'],
    ['Calvene', 'B441'],
    ['Camisano Vicentino', 'B485'],
    ['Campiglia dei Berici', 'B511'],
    ['Carrè', 'B835'],
    ['Cartigliano', 'B844'],
    ['Cassola', 'C037'],
    ['Castegnero', 'C056'],
    ['Castelgomberto', 'C119'],
    ['Chiampo', 'C605'],
    ['Chiuppano', 'C650'],
    ['Cogollo del Cengio', 'C824'],
    ['Cornedo Vicentino', 'D020'],
    ['Costabissara', 'D107'],
    ['Creazzo', 'D136'],
    ['Crespadoro', 'D156'],
    ['Dueville', 'D379'],
    ['Enego', 'D407'],
    ['Fara Vicentino', 'D496'],
    ['Foza', 'D750'],
    ['Gallio', 'D882'],
    ['Gambellara', 'D897'],
    ['Gambugliano', 'D902'],
    ['Grisignano di Zocco', 'E184'],
    ['Grumolo delle Abbadesse', 'E226'],
    ['Isola Vicentina', 'E354'],
    ['Laghi', 'E403'],
    ['Lastebasse', 'E465'],
    ['Longare', 'E671'],
    ['Lonigo', 'E682'],
    ['Lugo di Vicenza', 'E731'],
    ['Malo', 'E864'],
    ['Marano Vicentino', 'E912'],
    ['Marostica', 'E970'],
    ['Montebello Vicentino', 'F442'],
    ['Montecchio Maggiore', 'F464'],
    ['Montecchio Precalcino', 'F465'],
    ['Monte di Malo', 'F486'],
    ['Montegalda', 'F514'],
    ['Montegaldella', 'F515'],
    ['Monteviale', 'F662'],
    ['Monticello Conte Otto', 'F675'],
    ['Montorso Vicentino', 'F696'],
    ['Mussolente', 'F829'],
    ['Nanto', 'F838'],
    ['Nogarole Vicentino', 'F922'],
    ['Nove', 'F957'],
    ['Noventa Vicentina', 'F964'],
    ['Orgiano', 'G095'],
    ['Pedemonte', 'G406'],
    ['Pianezze', 'G560'],
    ['Piovene Rocchette', 'G694'],
    ['Pojana Maggiore', 'G776'],
    ['Posina', 'G931'],
    ['Pove del Grappa', 'G943'],
    ['Pozzoleone', 'G957'],
    ['Quinto Vicentino', 'H134'],
    ['Recoaro Terme', 'H214'],
    ['Roana', 'H361'],
    ["Romano d'Ezzelino", 'H512'],
    ['Rosà', 'H556'],
    ['Rossano Veneto', 'H580'],
    ['Rotzo', 'H594'],
    ['Salcedo', 'F810'],
    ['Sandrigo', 'H829'],
    ['San Pietro Mussolino', 'I117'],
    ['Santorso', 'I353'],
    ['San Vito di Leguzzano', 'I401'],
    ['Sarcedo', 'I425'],
    ['Sarego', 'I430'],
    ['Schiavon', 'I527'],
    ['Schio', 'I531'],
    ['Solagna', 'I783'],
    ['Sossano', 'I867'],
    ['Sovizzo', 'I879'],
    ['Tezze sul Brenta', 'L156'],
    ['Thiene', 'L157'],
    ['Tonezza del Cimone', 'D717'],
    ['Torrebelvicino', 'L248'],
    ['Torri di Quartesolo', 'L297'],
    ['Trissino', 'L433'],
    ['Valdagno', 'L551'],
    ['Valdastico', 'L554'],
    ['Valli del Pasubio', 'L624'],
    ["Velo d'Astico", 'L723'],
    ['Vicenza', 'L840'],
    ['Villaga', 'L952'],
    ['Villaverla', 'M032'],
    ['Zanè', 'M145'],
    ['Zermeghedo', 'M170'],
    ['Zovencedo', 'M194'],
    ['Zugliano', 'M199'],
    ['Val Liona', 'M384'],
    ['Barbarano Mossano', 'M401'],
    ['Valbrenta', 'M423'],
    ['Colceresa', 'M426'],
    ['Lusiana Conco', 'M427'],
  ],
  BL: [
    ['Agordo', 'A083'],
    ['Alano di Piave', 'A121'],
    ['Alleghe', 'A206'],
    ['Arsiè', 'A443'],
    ['Auronzo di Cadore', 'A501'],
    ['Belluno', 'A757'],
    ['Borca di Cadore', 'A982'],
    ['Calalzo di Cadore', 'B375'],
    ['Cencenighe Agordino', 'C458'],
    ['Cesiomaggiore', 'C577'],
    ["Chies d'Alpago", 'C630'],
    ['Cibiana di Cadore', 'C672'],
    ['Colle Santa Lucia', 'C872'],
    ['Comelico Superiore', 'C920'],
    ["Cortina d'Ampezzo", 'A266'],
    ['Danta di Cadore', 'D247'],
    ['Domegge di Cadore', 'D330'],
    ['Falcade', 'D470'],
    ['Feltre', 'D530'],
    ['Fonzaso', 'D686'],
    ["Canale d'Agordo", 'B574'],
    ['Gosaldo', 'E113'],
    ['Lamon', 'E429'],
    ['La Valle Agordina', 'E490'],
    ['Limana', 'E588'],
    ['Livinallongo del Col di Lana', 'E622'],
    ['Lorenzago di Cadore', 'E687'],
    ['Lozzo di Cadore', 'E708'],
    ['Ospitale di Cadore', 'G169'],
    ['Pedavena', 'G404'],
    ['Perarolo di Cadore', 'G442'],
    ['Pieve di Cadore', 'G642'],
    ['Ponte nelle Alpi', 'B662'],
    ['Rivamonte Agordino', 'H327'],
    ['Rocca Pietore', 'H379'],
    ['San Gregorio nelle Alpi', 'H938'],
    ['San Nicolò di Comelico', 'I063'],
    ['San Pietro di Cadore', 'I088'],
    ['Santa Giustina', 'I206'],
    ['San Tomaso Agordino', 'I347'],
    ['Santo Stefano di Cadore', 'C919'],
    ['San Vito di Cadore', 'I392'],
    ['Sedico', 'I563'],
    ['Selva di Cadore', 'I592'],
    ['Seren del Grappa', 'I626'],
    ['Sospirolo', 'I866'],
    ['Soverzene', 'I876'],
    ['Sovramonte', 'I673'],
    ['Taibon Agordino', 'L030'],
    ['Tambre', 'L040'],
    ['Vallada Agordina', 'L584'],
    ['Valle di Cadore', 'L590'],
    ['Vigo di Cadore', 'L890'],
    ['Vodo Cadore', 'M108'],
    ['Voltago Agordino', 'M124'],
    ['Zoppè di Cadore', 'M189'],
    ['Quero Vas', 'M332'],
    ['Longarone', 'M342'],
    ['Alpago', 'M375'],
    ['Val di Zoldo', 'M374'],
    ['Borgo Valbelluna', 'M421'],
  ],
  TV: [
    ['Altivole', 'A237'],
    ['Arcade', 'A360'],
    ['Asolo', 'A471'],
    ['Borso del Grappa', 'B061'],
    ['Breda di Piave', 'B128'],
    ['Caerano di San Marco', 'B349'],
    ['Cappella Maggiore', 'B678'],
    ['Carbonera', 'B744'],
    ['Casale sul Sile', 'B879'],
    ['Casier', 'B965'],
    ['Castelcucco', 'C073'],
    ['Castelfranco Veneto', 'C111'],
    ['Castello di Godego', 'C190'],
    ['Cavaso del Tomba', 'C384'],
    ['Cessalto', 'C580'],
    ['Chiarano', 'C614'],
    ['Cimadolmo', 'C689'],
    ['Cison di Valmarino', 'C735'],
    ['Codognè', 'C815'],
    ['Colle Umberto', 'C848'],
    ['Conegliano', 'C957'],
    ['Cordignano', 'C992'],
    ['Cornuda', 'D030'],
    ['Crocetta del Montello', 'C670'],
    ['Farra di Soligo', 'D505'],
    ['Follina', 'D654'],
    ['Fontanelle', 'D674'],
    ['Fonte', 'D680'],
    ['Fregona', 'D794'],
    ['Gaiarine', 'D854'],
    ['Giavera del Montello', 'E021'],
    ["Godega di Sant'Urbano", 'E071'],
    ['Gorgo al Monticano', 'E092'],
    ['Istrana', 'E373'],
    ['Loria', 'E692'],
    ['Mansuè', 'E893'],
    ['Mareno di Piave', 'E940'],
    ['Maser', 'F009'],
    ['Maserada sul Piave', 'F012'],
    ['Meduna di Livenza', 'F088'],
    ['Miane', 'F190'],
    ['Mogliano Veneto', 'F269'],
    ['Monastier di Treviso', 'F332'],
    ['Monfumo', 'F360'],
    ['Montebelluna', 'F443'],
    ['Morgano', 'F725'],
    ['Moriago della Battaglia', 'F729'],
    ['Motta di Livenza', 'F770'],
    ['Nervesa della Battaglia', 'F872'],
    ['Oderzo', 'F999'],
    ['Ormelle', 'G115'],
    ['Orsago', 'G123'],
    ['Paese', 'G229'],
    ['Pederobba', 'G408'],
    ['Pieve di Soligo', 'G645'],
    ['Ponte di Piave', 'G846'],
    ['Ponzano Veneto', 'G875'],
    ['Portobuffolè', 'G909'],
    ['Possagno', 'G933'],
    ['Povegliano', 'G944'],
    ['Preganziol', 'H022'],
    ['Quinto di Treviso', 'H131'],
    ['Refrontolo', 'H220'],
    ['Resana', 'H238'],
    ['Revine Lago', 'H253'],
    ['Riese Pio X', 'H280'],
    ['Roncade', 'H523'],
    ['Salgareda', 'H706'],
    ['San Biagio di Callalta', 'H781'],
    ['San Fior', 'H843'],
    ['San Pietro di Feletto', 'I103'],
    ['San Polo di Piave', 'I124'],
    ['Santa Lucia di Piave', 'I221'],
    ['San Vendemiano', 'I382'],
    ['San Zenone degli Ezzelini', 'I417'],
    ['Sarmede', 'I435'],
    ['Segusino', 'I578'],
    ['Sernaglia della Battaglia', 'I635'],
    ['Silea', 'F116'],
    ['Spresiano', 'I927'],
    ['Susegana', 'L014'],
    ['Tarzo', 'L058'],
    ['Trevignano', 'L402'],
    ['Treviso', 'L407'],
    ['Valdobbiadene', 'L565'],
    ['Vazzola', 'L700'],
    ['Vedelago', 'L706'],
    ['Vidor', 'L856'],
    ['Villorba', 'M048'],
    ['Vittorio Veneto', 'M089'],
    ['Volpago del Montello', 'M118'],
    ['Zenson di Piave', 'M163'],
    ['Zero Branco', 'M171'],
    ['Pieve del Grappa', 'M422'],
  ],
  VE: [
    ['Annone Veneto', 'A302'],
    ['Campagna Lupia', 'B493'],
    ['Campolongo Maggiore', 'B546'],
    ['Camponogara', 'B554'],
    ['Caorle', 'B642'],
    ['Cavarzere', 'C383'],
    ['Ceggia', 'C422'],
    ['Chioggia', 'C638'],
    ['Cinto Caomaggiore', 'C714'],
    ['Cona', 'C938'],
    ['Concordia Sagittaria', 'C950'],
    ['Dolo', 'D325'],
    ['Eraclea', 'D415'],
    ["Fiesso d'Artico", 'D578'],
    ['Fossalta di Piave', 'D740'],
    ['Fossalta di Portogruaro', 'D741'],
    ['Fossò', 'D748'],
    ['Gruaro', 'E215'],
    ['Jesolo', 'C388'],
    ['Marcon', 'E936'],
    ['Martellago', 'E980'],
    ['Meolo', 'F130'],
    ['Mira', 'F229'],
    ['Mirano', 'F241'],
    ['Musile di Piave', 'F826'],
    ['Noale', 'F904'],
    ['Noventa di Piave', 'F963'],
    ['Pianiga', 'G565'],
    ['Portogruaro', 'G914'],
    ['Pramaggiore', 'G981'],
    ["Quarto d'Altino", 'H117'],
    ['Salzano', 'H735'],
    ['San Donà di Piave', 'H823'],
    ['San Michele al Tagliamento', 'I040'],
    ['Santa Maria di Sala', 'I242'],
    ['San Stino di Livenza', 'I373'],
    ['Scorzè', 'I551'],
    ['Spinea', 'I908'],
    ['Stra', 'I965'],
    ['Teglio Veneto', 'L085'],
    ['Torre di Mosto', 'L267'],
    ['Venezia', 'L736'],
    ['Vigonovo', 'L899'],
    ['Cavallino-Treporti', 'M308'],
  ],
  PD: [
    ['Abano Terme', 'A001'],
    ['Agna', 'A075'],
    ['Albignasego', 'A161'],
    ['Anguillara Veneta', 'A296'],
    ['Arquà Petrarca', 'A434'],
    ['Arre', 'A438'],
    ['Arzergrande', 'A458'],
    ['Bagnoli di Sopra', 'A568'],
    ['Baone', 'A613'],
    ['Barbona', 'A637'],
    ['Battaglia Terme', 'A714'],
    ['Boara Pisani', 'A906'],
    ['Borgoricco', 'B031'],
    ['Bovolenta', 'B106'],
    ['Brugine', 'B213'],
    ['Cadoneghe', 'B345'],
    ['Campodarsego', 'B524'],
    ['Campodoro', 'B531'],
    ['Camposampiero', 'B563'],
    ['Campo San Martino', 'B564'],
    ['Candiana', 'B589'],
    ['Carceri', 'B749'],
    ['Carmignano di Brenta', 'B795'],
    ['Cartura', 'B848'],
    ['Casale di Scodosia', 'B877'],
    ['Casalserugo', 'B912'],
    ['Castelbaldo', 'C057'],
    ['Cervarese Santa Croce', 'C544'],
    ['Cinto Euganeo', 'C713'],
    ['Cittadella', 'C743'],
    ['Codevigo', 'C812'],
    ['Conselve', 'C964'],
    ['Correzzola', 'D040'],
    ['Curtarolo', 'D226'],
    ['Este', 'D442'],
    ['Fontaniva', 'D679'],
    ['Galliera Veneta', 'D879'],
    ['Galzignano Terme', 'D889'],
    ['Gazzo', 'D956'],
    ['Grantorto', 'E145'],
    ['Granze', 'E146'],
    ['Legnaro', 'E515'],
    ['Limena', 'E592'],
    ['Loreggia', 'E684'],
    ['Lozzo Atestino', 'E709'],
    ['Maserà di Padova', 'F011'],
    ['Masi', 'F013'],
    ['Massanzago', 'F033'],
    ['Megliadino San Vitale', 'F092'],
    ['Merlara', 'F148'],
    ['Mestrino', 'F161'],
    ['Monselice', 'F382'],
    ['Montagnana', 'F394'],
    ['Montegrotto Terme', 'F529'],
    ['Noventa Padovana', 'F962'],
    ['Ospedaletto Euganeo', 'G167'],
    ['Padova', 'G224'],
    ['Pernumia', 'G461'],
    ["Piacenza d'Adige", 'G534'],
    ['Piazzola sul Brenta', 'G587'],
    ['Piombino Dese', 'G688'],
    ['Piove di Sacco', 'G693'],
    ['Polverara', 'G802'],
    ['Ponso', 'G823'],
    ['Pontelongo', 'G850'],
    ['Ponte San Nicolò', 'G855'],
    ['Pozzonovo', 'G963'],
    ['Rovolon', 'H622'],
    ['Rubano', 'H625'],
    ['Saccolongo', 'H655'],
    ['San Giorgio delle Pertiche', 'H893'],
    ['San Giorgio in Bosco', 'H897'],
    ['San Martino di Lupari', 'I008'],
    ['San Pietro in Gu', 'I107'],
    ['San Pietro Viminario', 'I120'],
    ['Santa Giustina in Colle', 'I207'],
    ["Sant'Angelo di Piove di Sacco", 'I275'],
    ["Sant'Elena", 'I319'],
    ["Sant'Urbano", 'I375'],
    ['Saonara', 'I418'],
    ['Selvazzano Dentro', 'I595'],
    ['Solesino', 'I799'],
    ['Stanghella', 'I938'],
    ['Teolo', 'L100'],
    ['Terrassa Padovana', 'L132'],
    ['Tombolo', 'L199'],
    ['Torreglia', 'L270'],
    ['Trebaseleghe', 'L349'],
    ['Tribano', 'L414'],
    ['Urbana', 'L497'],
    ['Veggiano', 'L710'],
    ['Vescovana', 'L805'],
    ["Vighizzolo d'Este", 'L878'],
    ['Vigodarzere', 'L892'],
    ['Vigonza', 'L900'],
    ['Villa del Conte', 'L934'],
    ['Villa Estense', 'L937'],
    ['Villafranca Padovana', 'L947'],
    ['Villanova di Camposampiero', 'L979'],
    ["Vo'", 'M103'],
    ['Due Carrare', 'M300'],
    ['Borgo Veneto', 'M402'],
  ],
  RO: [
    ['Adria', 'A059'],
    ['Ariano nel Polesine', 'A400'],
    ['Arquà Polesine', 'A435'],
    ['Badia Polesine', 'A539'],
    ['Bagnolo di Po', 'A574'],
    ['Bergantino', 'A795'],
    ['Bosaro', 'B069'],
    ['Calto', 'B432'],
    ['Canaro', 'B578'],
    ['Canda', 'B582'],
    ['Castelguglielmo', 'C122'],
    ['Castelmassa', 'C207'],
    ['Castelnovo Bariano', 'C215'],
    ['Ceneselli', 'C461'],
    ['Ceregnano', 'C500'],
    ['Corbola', 'C987'],
    ['Costa di Rovigo', 'D105'],
    ['Crespino', 'D161'],
    ['Ficarolo', 'D568'],
    ['Fiesso Umbertiano', 'D577'],
    ['Frassinelle Polesine', 'D776'],
    ['Fratta Polesine', 'D788'],
    ['Gaiba', 'D855'],
    ['Gavello', 'D942'],
    ['Giacciano con Baruchella', 'E008'],
    ['Guarda Veneta', 'E240'],
    ['Lendinara', 'E522'],
    ['Loreo', 'E689'],
    ['Lusia', 'E761'],
    ['Melara', 'F095'],
    ['Occhiobello', 'F994'],
    ['Papozze', 'G323'],
    ['Pettorazza Grimani', 'G525'],
    ['Pincara', 'G673'],
    ['Polesella', 'G782'],
    ['Pontecchio Polesine', 'G836'],
    ['Porto Tolle', 'G923'],
    ['Rosolina', 'H573'],
    ['Rovigo', 'H620'],
    ['Salara', 'H689'],
    ['San Bellino', 'H768'],
    ['San Martino di Venezze', 'H996'],
    ['Stienta', 'I953'],
    ['Taglio di Po', 'L026'],
    ['Trecenta', 'L359'],
    ['Villadose', 'L939'],
    ['Villamarzana', 'L967'],
    ['Villanova del Ghebbo', 'L985'],
    ['Villanova Marchesana', 'L988'],
    ['Porto Viro', 'G926'],
  ],
  UD: [
    ['Aiello del Friuli', 'A103'],
    ['Amaro', 'A254'],
    ['Ampezzo', 'A267'],
    ['Aquileia', 'A346'],
    ['Arta Terme', 'A447'],
    ['Artegna', 'A448'],
    ['Attimis', 'A491'],
    ['Bagnaria Arsa', 'A553'],
    ['Basiliano', 'A700'],
    ['Bertiolo', 'A810'],
    ['Bicinicco', 'A855'],
    ['Bordano', 'A983'],
    ['Buja', 'B259'],
    ['Buttrio', 'B309'],
    ['Camino al Tagliamento', 'B483'],
    ['Campoformido', 'B536'],
    ['Carlino', 'B788'],
    ['Cassacco', 'B994'],
    ['Castions di Strada', 'C327'],
    ['Cavazzo Carnico', 'C389'],
    ['Cercivento', 'C494'],
    ['Cervignano del Friuli', 'C556'],
    ['Chiopris-Viscone', 'C641'],
    ['Chiusaforte', 'C656'],
    ['Cividale del Friuli', 'C758'],
    ['Codroipo', 'C817'],
    ['Colloredo di Monte Albano', 'C885'],
    ['Comeglians', 'C918'],
    ['Corno di Rosazzo', 'D027'],
    ['Coseano', 'D085'],
    ['Dignano', 'D300'],
    ['Dogna', 'D316'],
    ['Drenchia', 'D366'],
    ['Enemonzo', 'D408'],
    ['Faedis', 'D455'],
    ['Fagagna', 'D461'],
    ['Flaibano', 'D630'],
    ['Forni Avoltri', 'D718'],
    ['Forni di Sopra', 'D719'],
    ['Forni di Sotto', 'D720'],
    ['Gemona del Friuli', 'D962'],
    ['Gonars', 'E083'],
    ['Grimacco', 'E179'],
    ['Latisana', 'E473'],
    ['Lauco', 'E476'],
    ['Lestizza', 'E553'],
    ['Lignano Sabbiadoro', 'E584'],
    ['Lusevera', 'E760'],
    ['Magnano in Riviera', 'E820'],
    ['Majano', 'E833'],
    ['Malborghetto Valbruna', 'E847'],
    ['Manzano', 'E899'],
    ['Marano Lagunare', 'E910'],
    ['Martignacco', 'E982'],
    ['Mereto di Tomba', 'F144'],
    ['Moggio Udinese', 'F266'],
    ['Moimacco', 'F275'],
    ['Montenars', 'F574'],
    ['Mortegliano', 'F756'],
    ['Moruzzo', 'F760'],
    ['Muzzana del Turgnano', 'F832'],
    ['Nimis', 'F898'],
    ['Osoppo', 'G163'],
    ['Ovaro', 'G198'],
    ['Pagnacco', 'G238'],
    ['Palazzolo dello Stella', 'G268'],
    ['Palmanova', 'G284'],
    ['Paluzza', 'G300'],
    ['Pasian di Prato', 'G352'],
    ['Paularo', 'G381'],
    ['Pavia di Udine', 'G389'],
    ['Pocenia', 'G743'],
    ['Pontebba', 'G831'],
    ['Porpetto', 'G891'],
    ['Povoletto', 'G949'],
    ['Pozzuolo del Friuli', 'G966'],
    ['Pradamano', 'G969'],
    ['Prato Carnico', 'H002'],
    ['Precenicco', 'H014'],
    ['Premariacco', 'H029'],
    ['Preone', 'H038'],
    ['Prepotto', 'H040'],
    ['Pulfero', 'H089'],
    ['Ragogna', 'H161'],
    ['Ravascletto', 'H196'],
    ['Raveo', 'H200'],
    ['Reana del Rojale', 'H206'],
    ['Remanzacco', 'H229'],
    ['Resia', 'H242'],
    ['Resiutta', 'H244'],
    ['Rigolato', 'H289'],
    ["Rive d'Arcano", 'H347'],
    ['Ronchis', 'H533'],
    ['Ruda', 'H629'],
    ['San Daniele del Friuli', 'H816'],
    ['San Giorgio di Nogaro', 'H895'],
    ['San Giovanni al Natisone', 'H906'],
    ['San Leonardo', 'H951'],
    ['San Pietro al Natisone', 'I092'],
    ['Santa Maria la Longa', 'I248'],
    ['San Vito al Torre', 'I404'],
    ['San Vito di Fagagna', 'I405'],
    ['Sauris', 'I464'],
    ['Savogna', 'I478'],
    ['Sedegliano', 'I562'],
    ['Socchieve', 'I777'],
    ['Stregna', 'I974'],
    ['Sutrio', 'L018'],
    ['Taipana', 'G736'],
    ['Talmassons', 'L039'],
    ['Tarcento', 'L050'],
    ['Tarvisio', 'L057'],
    ['Tavagnacco', 'L065'],
    ["Terzo d'Aquileia", 'L144'],
    ['Tolmezzo', 'L195'],
    ['Torreano', 'L246'],
    ['Torviscosa', 'L309'],
    ['Trasaghis', 'L335'],
    ['Treppo Grande', 'L382'],
    ['Tricesimo', 'L421'],
    ['Trivignano Udinese', 'L438'],
    ['Udine', 'L483'],
    ['Varmo', 'L686'],
    ['Venzone', 'L743'],
    ['Verzegnis', 'L801'],
    ['Villa Santina', 'L909'],
    ['Visco', 'M073'],
    ['Zuglio', 'M200'],
    ['Forgaria nel Friuli', 'D700'],
    ['Campolongo Tapogliano', 'M311'],
    ['Rivignano Teor', 'M317'],
    ['Sappada', 'I421'],
    ['Fiumicello Villa Vicentina', 'M400'],
    ['Treppo Ligosullo', 'M399'],
  ],
  GO: [
    ['Capriva del Friuli', 'B712'],
    ['Cormons', 'D014'],
    ['Doberdò del Lago-Doberdob', 'D312'],
    ['Dolegna del Collio', 'D321'],
    ["Farra d'Isonzo", 'D504'],
    ['Fogliano Redipuglia', 'D645'],
    ['Gorizia', 'E098'],
    ["Gradisca d'Isonzo", 'E124'],
    ['Grado', 'E125'],
    ['Mariano del Friuli', 'E952'],
    ['Medea', 'F081'],
    ['Monfalcone', 'F356'],
    ['Moraro', 'F710'],
    ['Mossa', 'F767'],
    ["Romans d'Isonzo", 'H514'],
    ['Ronchi dei Legionari', 'H531'],
    ['Sagrado', 'H665'],
    ["San Canzian d'Isonzo", 'H787'],
    ['San Floriano del Collio-teverjan', 'H845'],
    ['San Lorenzo Isontino', 'H964'],
    ["San Pier d'Isonzo", 'I082'],
    ["Savogna d'Isonzo-Sovodnje ob So?i", 'I479'],
    ['Staranzano', 'I939'],
    ['Turriaco', 'L474'],
    ['Villesse', 'M043'],
  ],
  TS: [
    ['Duino Aurisina-Devin Nabreina', 'D383'],
    ['Monrupino-Repentabor', 'F378'],
    ['Muggia', 'F795'],
    ['San Dorligo della Valle-Dolina', 'D324'],
    ['Sgonico-Zgonik', 'I715'],
    ['Trieste', 'L424'],
  ],
  PN: [
    ['Andreis', 'A283'],
    ['Arba', 'A354'],
    ['Aviano', 'A516'],
    ['Azzano Decimo', 'A530'],
    ['Barcis', 'A640'],
    ['Brugnera', 'B215'],
    ['Budoia', 'B247'],
    ['Caneva', 'B598'],
    ['Casarsa della Delizia', 'B940'],
    ['Castelnovo del Friuli', 'C217'],
    ['Cavasso Nuovo', 'C385'],
    ['Chions', 'C640'],
    ['Cimolais', 'C699'],
    ['Claut', 'C790'],
    ['Clauzetto', 'C791'],
    ['Cordenons', 'C991'],
    ['Cordovado', 'C993'],
    ['Erto e Casso', 'D426'],
    ['Fanna', 'D487'],
    ['Fiume Veneto', 'D621'],
    ['Fontanafredda', 'D670'],
    ['Frisanco', 'D804'],
    ['Maniago', 'E889'],
    ['Meduno', 'F089'],
    ['Montereale Valcellina', 'F596'],
    ['Morsano al Tagliamento', 'F750'],
    ['Pasiano di Pordenone', 'G353'],
    ['Pinzano al Tagliamento', 'G680'],
    ['Polcenigo', 'G780'],
    ['Porcia', 'G886'],
    ['Pordenone', 'G888'],
    ['Prata di Pordenone', 'G994'],
    ['Pravisdomini', 'H010'],
    ['Roveredo in Piano', 'H609'],
    ['Sacile', 'H657'],
    ['San Giorgio della Richinvelda', 'H891'],
    ['San Martino al Tagliamento', 'H999'],
    ['San Quirino', 'I136'],
    ['San Vito al Tagliamento', 'I403'],
    ['Sequals', 'I621'],
    ['Sesto al Reghena', 'I686'],
    ['Spilimbergo', 'I904'],
    ['Tramonti di Sopra', 'L324'],
    ['Tramonti di Sotto', 'L325'],
    ['Travesio', 'L347'],
    ["Vito d'Asio", 'M085'],
    ['Vivaro', 'M096'],
    ['Zoppola', 'M190'],
    ['Vajont', 'M265'],
    ['Valvasone Arzene', 'M346'],
  ],
  IM: [
    ['Airole', 'A111'],
    ['Apricale', 'A338'],
    ["Aquila d'Arroscia", 'A344'],
    ['Armo', 'A418'],
    ['Aurigo', 'A499'],
    ['Badalucco', 'A536'],
    ['Bajardo', 'A581'],
    ['Bordighera', 'A984'],
    ["Borghetto d'Arroscia", 'A993'],
    ['Borgomaro', 'B020'],
    ['Camporosso', 'B559'],
    ['Caravonica', 'B734'],
    ['Castellaro', 'C143'],
    ['Castel Vittorio', 'C110'],
    ['Ceriana', 'C511'],
    ['Cervo', 'C559'],
    ['Cesio', 'C578'],
    ['Chiusanico', 'C657'],
    ['Chiusavecchia', 'C660'],
    ['Cipressa', 'C718'],
    ['Civezza', 'C755'],
    ["Cosio d'Arroscia", 'D087'],
    ['Costarainera', 'D114'],
    ['Diano Arentino', 'D293'],
    ['Diano Castello', 'D296'],
    ['Diano Marina', 'D297'],
    ['Diano San Pietro', 'D298'],
    ['Dolceacqua', 'D318'],
    ['Dolcedo', 'D319'],
    ['Imperia', 'E290'],
    ['Isolabona', 'E346'],
    ['Lucinasco', 'E719'],
    ['Mendatica', 'F123'],
    ['Molini di Triora', 'F290'],
    ['Montegrosso Pian Latte', 'F528'],
    ['Olivetta San Michele', 'G041'],
    ['Ospedaletti', 'G164'],
    ['Perinaldo', 'G454'],
    ['Pietrabruna', 'G607'],
    ['Pieve di Teco', 'G632'],
    ['Pigna', 'G660'],
    ['Pompeiana', 'G814'],
    ['Pontedassio', 'G840'],
    ['Pornassio', 'G890'],
    ['Prelà', 'H027'],
    ['Ranzo', 'H180'],
    ['Rezzo', 'H257'],
    ['Riva Ligure', 'H328'],
    ['Rocchetta Nervina', 'H460'],
    ['San Bartolomeo al Mare', 'H763'],
    ['San Biagio della Cima', 'H780'],
    ['San Lorenzo al Mare', 'H957'],
    ['Sanremo', 'I138'],
    ['Santo Stefano al Mare', 'I365'],
    ['Seborga', 'I556'],
    ['Soldano', 'I796'],
    ['Taggia', 'L024'],
    ['Terzorio', 'L146'],
    ['Triora', 'L430'],
    ['Vallebona', 'L596'],
    ['Vallecrosia', 'L599'],
    ['Vasia', 'L693'],
    ['Ventimiglia', 'L741'],
    ['Vessalico', 'L809'],
    ['Villa Faraldi', 'L943'],
    ['Montalto Carpasio', 'M387'],
  ],
  SV: [
    ['Alassio', 'A122'],
    ['Albenga', 'A145'],
    ['Albissola Marina', 'A165'],
    ['Albisola Superiore', 'A166'],
    ['Altare', 'A226'],
    ['Andora', 'A278'],
    ['Arnasco', 'A422'],
    ['Balestrino', 'A593'],
    ['Bardineto', 'A647'],
    ['Bergeggi', 'A796'],
    ['Boissano', 'A931'],
    ['Borghetto Santo Spirito', 'A999'],
    ['Borgio Verezzi', 'B005'],
    ['Bormida', 'B048'],
    ['Cairo Montenotte', 'B369'],
    ['Calice Ligure', 'B409'],
    ['Calizzano', 'B416'],
    ['Carcare', 'B748'],
    ['Casanova Lerrone', 'B927'],
    ['Castelbianco', 'C063'],
    ['Castelvecchio di Rocca Barbena', 'C276'],
    ['Celle Ligure', 'C443'],
    ['Cengio', 'C463'],
    ['Ceriale', 'C510'],
    ['Cisano sul Neva', 'C729'],
    ['Cosseria', 'D095'],
    ['Dego', 'D264'],
    ['Erli', 'D424'],
    ['Finale Ligure', 'D600'],
    ['Garlenda', 'D927'],
    ['Giustenice', 'E064'],
    ['Giusvalla', 'E066'],
    ['Laigueglia', 'E414'],
    ['Loano', 'E632'],
    ['Magliolo', 'E816'],
    ['Mallare', 'E860'],
    ['Massimino', 'F046'],
    ['Millesimo', 'F213'],
    ['Mioglia', 'F226'],
    ['Murialdo', 'F813'],
    ['Nasino', 'F847'],
    ['Noli', 'F926'],
    ['Onzo', 'G076'],
    ['Orco Feglino', 'D522'],
    ['Ortovero', 'G144'],
    ['Osiglia', 'G155'],
    ['Pallare', 'G281'],
    ['Piana Crixia', 'G542'],
    ['Pietra Ligure', 'G605'],
    ['Plodio', 'G741'],
    ['Pontinvrea', 'G866'],
    ['Quiliano', 'H126'],
    ['Rialto', 'H266'],
    ['Roccavignale', 'H452'],
    ['Sassello', 'I453'],
    ['Savona', 'I480'],
    ['Spotorno', 'I926'],
    ['Stella', 'I946'],
    ['Stellanello', 'I947'],
    ['Testico', 'L152'],
    ['Toirano', 'L190'],
    ['Tovo San Giacomo', 'L315'],
    ['Urbe', 'L499'],
    ['Vado Ligure', 'L528'],
    ['Varazze', 'L675'],
    ['Vendone', 'L730'],
    ['Vezzi Portio', 'L823'],
    ["Villanova d'Albenga", 'L975'],
    ['Zuccarello', 'M197'],
  ],
  GE: [
    ['Arenzano', 'A388'],
    ['Avegno', 'A506'],
    ['Bargagli', 'A658'],
    ['Bogliasco', 'A922'],
    ['Borzonasca', 'B067'],
    ['Busalla', 'B282'],
    ['Camogli', 'B490'],
    ['Campo Ligure', 'B538'],
    ['Campomorone', 'B551'],
    ['Carasco', 'B726'],
    ['Casarza Ligure', 'B939'],
    ['Casella', 'B956'],
    ['Castiglione Chiavarese', 'C302'],
    ['Ceranesi', 'C481'],
    ['Chiavari', 'C621'],
    ['Cicagna', 'C673'],
    ['Cogoleto', 'C823'],
    ['Cogorno', 'C826'],
    ['Coreglia Ligure', 'C995'],
    ['Crocefieschi', 'D175'],
    ['Davagna', 'D255'],
    ['Fascia', 'D509'],
    ['Favale di Malvaro', 'D512'],
    ['Fontanigorda', 'D677'],
    ['Genova', 'D969'],
    ['Gorreto', 'E109'],
    ['Isola del Cantone', 'E341'],
    ['Lavagna', 'E488'],
    ['Leivi', 'E519'],
    ['Lorsica', 'E695'],
    ['Lumarzo', 'E737'],
    ['Masone', 'F020'],
    ['Mele', 'F098'],
    ['Mezzanego', 'F173'],
    ['Mignanego', 'F202'],
    ['Moconesi', 'F256'],
    ['Moneglia', 'F354'],
    ['Montebruno', 'F445'],
    ['Montoggio', 'F682'],
    ['Ne', 'F858'],
    ['Neirone', 'F862'],
    ['Orero', 'G093'],
    ['Pieve Ligure', 'G646'],
    ['Portofino', 'G913'],
    ['Propata', 'H073'],
    ['Rapallo', 'H183'],
    ['Recco', 'H212'],
    ['Rezzoaglio', 'H258'],
    ['Ronco Scrivia', 'H536'],
    ['Rondanina', 'H546'],
    ['Rossiglione', 'H581'],
    ['Rovegno', 'H599'],
    ['San Colombano Certenoli', 'H802'],
    ['Santa Margherita Ligure', 'I225'],
    ["Sant'Olcese", 'I346'],
    ["Santo Stefano d'Aveto", 'I368'],
    ['Savignone', 'I475'],
    ['Serra Riccò', 'I640'],
    ['Sestri Levante', 'I693'],
    ['Sori', 'I852'],
    ['Tiglieto', 'L167'],
    ['Torriglia', 'L298'],
    ['Tribogna', 'L416'],
    ['Uscio', 'L507'],
    ['Valbrevenna', 'L546'],
    ['Vobbia', 'M105'],
    ['Zoagli', 'M182'],
  ],
  SP: [
    ['Ameglia', 'A261'],
    ['Arcola', 'A373'],
    ['Beverino', 'A836'],
    ['Bolano', 'A932'],
    ['Bonassola', 'A961'],
    ['Borghetto di Vara', 'A992'],
    ['Brugnato', 'B214'],
    ['Calice al Cornoviglio', 'B410'],
    ['Carro', 'B838'],
    ['Carrodano', 'B839'],
    ['Castelnuovo Magra', 'C240'],
    ['Deiva Marina', 'D265'],
    ['Follo', 'D655'],
    ['Framura', 'D758'],
    ['La Spezia', 'E463'],
    ['Lerici', 'E542'],
    ['Levanto', 'E560'],
    ['Maissana', 'E842'],
    ['Monterosso al Mare', 'F609'],
    ['Luni', 'G143'],
    ['Pignone', 'G664'],
    ['Portovenere', 'G925'],
    ['Riccò del Golfo di Spezia', 'H275'],
    ['Riomaggiore', 'H304'],
    ['Rocchetta di Vara', 'H461'],
    ['Santo Stefano di Magra', 'I363'],
    ['Sarzana', 'I449'],
    ['Sesta Godano', 'E070'],
    ['Varese Ligure', 'L681'],
    ['Vernazza', 'L774'],
    ['Vezzano Ligure', 'L819'],
    ['Zignago', 'M177'],
  ],
  PC: [
    ['Agazzano', 'A067'],
    ['Alseno', 'A223'],
    ['Besenzone', 'A823'],
    ['Bettola', 'A831'],
    ['Bobbio', 'A909'],
    ['Borgonovo Val Tidone', 'B025'],
    ['Cadeo', 'B332'],
    ['Calendasco', 'B405'],
    ['Caorso', 'B643'],
    ['Carpaneto Piacentino', 'B812'],
    ["Castell'Arquato", 'C145'],
    ['Castel San Giovanni', 'C261'],
    ['Castelvetro Piacentino', 'C288'],
    ['Cerignale', 'C513'],
    ['Coli', 'C838'],
    ['Corte Brugnatella', 'D054'],
    ['Cortemaggiore', 'D061'],
    ['Farini', 'D502'],
    ['Ferriere', 'D555'],
    ["Fiorenzuola d'Arda", 'D611'],
    ['Gazzola', 'D958'],
    ['Gossolengo', 'E114'],
    ['Gragnano Trebbiense', 'E132'],
    ['Gropparello', 'E196'],
    ["Lugagnano Val d'Arda", 'E726'],
    ["Monticelli d'Ongina", 'F671'],
    ['Morfasso', 'F724'],
    ['Ottone', 'G195'],
    ['Piacenza', 'G535'],
    ['Pianello Val Tidone', 'G557'],
    ['Piozzano', 'G696'],
    ['Podenzano', 'G747'],
    ["Ponte dell'Olio", 'G842'],
    ['Pontenure', 'G852'],
    ['Rivergaro', 'H350'],
    ['Rottofreno', 'H593'],
    ['San Giorgio Piacentino', 'H887'],
    ['San Pietro in Cerro', 'G788'],
    ['Sarmato', 'I434'],
    ['Travo', 'L348'],
    ['Vernasca', 'L772'],
    ['Vigolzone', 'L897'],
    ["Villanova sull'Arda", 'L980'],
    ['Zerba', 'M165'],
    ['Ziano Piacentino', 'L848'],
    ['Alta Val Tidone', 'M386'],
  ],
  PR: [
    ['Albareto', 'A138'],
    ['Bardi', 'A646'],
    ['Bedonia', 'A731'],
    ['Berceto', 'A788'],
    ['Bore', 'A987'],
    ['Borgo Val di Taro', 'B042'],
    ['Busseto', 'B293'],
    ['Calestano', 'B408'],
    ['Collecchio', 'C852'],
    ['Colorno', 'C904'],
    ['Compiano', 'C934'],
    ['Corniglio', 'D026'],
    ['Felino', 'D526'],
    ['Fidenza', 'B034'],
    ['Fontanellato', 'D673'],
    ['Fontevivo', 'D685'],
    ['Fornovo di Taro', 'D728'],
    ['Langhirano', 'E438'],
    ["Lesignano de' Bagni", 'E547'],
    ['Medesano', 'F082'],
    ['Monchio delle Corti', 'F340'],
    ['Montechiarugolo', 'F473'],
    ['Neviano degli Arduini', 'F882'],
    ['Noceto', 'F914'],
    ['Palanzano', 'G255'],
    ['Parma', 'G337'],
    ['Pellegrino Parmense', 'G424'],
    ['Roccabianca', 'H384'],
    ['Sala Baganza', 'H682'],
    ['Salsomaggiore Terme', 'H720'],
    ['San Secondo Parmense', 'I153'],
    ['Solignano', 'I803'],
    ['Soragna', 'I840'],
    ['Terenzo', 'E548'],
    ['Tizzano Val Parma', 'L183'],
    ['Tornolo', 'L229'],
    ['Torrile', 'L299'],
    ['Traversetolo', 'L346'],
    ['Valmozzola', 'L641'],
    ["Varano de' Melegari", 'L672'],
    ['Varsi', 'L689'],
    ['Sissa Trecasali', 'M325'],
    ['Polesine Zibello', 'M367'],
    ['Sorbolo Mezzani', 'M411'],
  ],
  RE: [
    ['Albinea', 'A162'],
    ['Bagnolo in Piano', 'A573'],
    ['Baiso', 'A586'],
    ['Bibbiano', 'A850'],
    ['Boretto', 'A988'],
    ['Brescello', 'B156'],
    ['Cadelbosco di Sopra', 'B328'],
    ['Campagnola Emilia', 'B499'],
    ['Campegine', 'B502'],
    ['Carpineti', 'B825'],
    ['Casalgrande', 'B893'],
    ['Casina', 'B967'],
    ['Castellarano', 'C141'],
    ['Castelnovo di Sotto', 'C218'],
    ["Castelnovo ne' Monti", 'C219'],
    ['Cavriago', 'C405'],
    ['Canossa', 'C669'],
    ['Correggio', 'D037'],
    ['Fabbrico', 'D450'],
    ['Gattatico', 'D934'],
    ['Gualtieri', 'E232'],
    ['Guastalla', 'E253'],
    ['Luzzara', 'E772'],
    ['Montecchio Emilia', 'F463'],
    ['Novellara', 'F960'],
    ['Poviglio', 'G947'],
    ['Quattro Castella', 'H122'],
    ['Reggiolo', 'H225'],
    ["Reggio nell'Emilia", 'H223'],
    ['Rio Saliceto', 'H298'],
    ['Rolo', 'H500'],
    ['Rubiera', 'H628'],
    ['San Martino in Rio', 'I011'],
    ["San Polo d'Enza", 'I123'],
    ["Sant'Ilario d'Enza", 'I342'],
    ['Scandiano', 'I496'],
    ['Toano', 'L184'],
    ['Vetto', 'L815'],
    ['Vezzano sul Crostolo', 'L820'],
    ['Viano', 'L831'],
    ['Villa Minozzo', 'L969'],
    ['Ventasso', 'M364'],
  ],
  MO: [
    ['Bastiglia', 'A713'],
    ['Bomporto', 'A959'],
    ['Campogalliano', 'B539'],
    ['Camposanto', 'B566'],
    ['Carpi', 'B819'],
    ['Castelfranco Emilia', 'C107'],
    ['Castelnuovo Rangone', 'C242'],
    ['Castelvetro di Modena', 'C287'],
    ['Cavezzo', 'C398'],
    ['Concordia sulla Secchia', 'C951'],
    ['Fanano', 'D486'],
    ['Finale Emilia', 'D599'],
    ['Fiorano Modenese', 'D607'],
    ['Fiumalbo', 'D617'],
    ['Formigine', 'D711'],
    ['Frassinoro', 'D783'],
    ['Guiglia', 'E264'],
    ['Lama Mocogno', 'E426'],
    ['Maranello', 'E904'],
    ['Marano sul Panaro', 'E905'],
    ['Medolla', 'F087'],
    ['Mirandola', 'F240'],
    ['Modena', 'F257'],
    ['Montecreto', 'F484'],
    ['Montefiorino', 'F503'],
    ['Montese', 'F642'],
    ['Nonantola', 'F930'],
    ['Novi di Modena', 'F966'],
    ['Palagano', 'G250'],
    ['Pavullo nel Frignano', 'G393'],
    ['Pievepelago', 'G649'],
    ['Polinago', 'G789'],
    ['Prignano sulla Secchia', 'H061'],
    ['Ravarino', 'H195'],
    ['Riolunato', 'H303'],
    ['San Cesario sul Panaro', 'H794'],
    ['San Felice sul Panaro', 'H835'],
    ['San Possidonio', 'I128'],
    ['San Prospero', 'I133'],
    ['Sassuolo', 'I462'],
    ['Savignano sul Panaro', 'I473'],
    ['Serramazzoni', 'F357'],
    ['Sestola', 'I689'],
    ['Soliera', 'I802'],
    ['Spilamberto', 'I903'],
    ['Vignola', 'L885'],
    ['Zocca', 'M183'],
  ],
  BO: [
    ["Anzola dell'Emilia", 'A324'],
    ['Argelato', 'A392'],
    ['Baricella', 'A665'],
    ['Bentivoglio', 'A785'],
    ['Bologna', 'A944'],
    ['Borgo Tossignano', 'B044'],
    ['Budrio', 'B249'],
    ['Calderara di Reno', 'B399'],
    ['Camugnano', 'B572'],
    ['Casalecchio di Reno', 'B880'],
    ['Casalfiumanese', 'B892'],
    ["Castel d'Aiano", 'C075'],
    ['Castel del Rio', 'C086'],
    ['Castel di Casio', 'B969'],
    ['Castel Guelfo di Bologna', 'C121'],
    ["Castello d'Argile", 'C185'],
    ['Castel Maggiore', 'C204'],
    ['Castel San Pietro Terme', 'C265'],
    ['Castenaso', 'C292'],
    ['Castiglione dei Pepoli', 'C296'],
    ['Crevalcore', 'D166'],
    ['Dozza', 'D360'],
    ['Fontanelice', 'D668'],
    ['Gaggio Montano', 'D847'],
    ['Galliera', 'D878'],
    ["Granarolo dell'Emilia", 'E136'],
    ['Grizzana Morandi', 'E187'],
    ['Imola', 'E289'],
    ['Lizzano in Belvedere', 'A771'],
    ['Loiano', 'E655'],
    ['Malalbergo', 'E844'],
    ['Marzabotto', 'B689'],
    ['Medicina', 'F083'],
    ['Minerbio', 'F219'],
    ['Molinella', 'F288'],
    ['Monghidoro', 'F363'],
    ['Monterenzio', 'F597'],
    ['Monte San Pietro', 'F627'],
    ['Monzuno', 'F706'],
    ['Mordano', 'F718'],
    ["Ozzano dell'Emilia", 'G205'],
    ['Pianoro', 'G570'],
    ['Pieve di Cento', 'G643'],
    ['Sala Bolognese', 'H678'],
    ['San Benedetto Val di Sambro', 'G566'],
    ['San Giorgio di Piano', 'H896'],
    ['San Giovanni in Persiceto', 'G467'],
    ['San Lazzaro di Savena', 'H945'],
    ['San Pietro in Casale', 'I110'],
    ["Sant'Agata Bolognese", 'I191'],
    ['Sasso Marconi', 'G972'],
    ['Vergato', 'L762'],
    ['Zola Predosa', 'M185'],
    ['Valsamoggia', 'M320'],
    ['Alto Reno Terme', 'M369'],
  ],
  FE: [
    ['Argenta', 'A393'],
    ['Bondeno', 'A965'],
    ['Cento', 'C469'],
    ['Codigoro', 'C814'],
    ['Comacchio', 'C912'],
    ['Copparo', 'C980'],
    ['Ferrara', 'D548'],
    ['Jolanda di Savoia', 'E320'],
    ['Lagosanto', 'E410'],
    ['Masi Torello', 'F016'],
    ['Mesola', 'F156'],
    ['Ostellato', 'G184'],
    ['Poggio Renatico', 'G768'],
    ['Portomaggiore', 'G916'],
    ['Vigarano Mainarda', 'L868'],
    ['Voghiera', 'M110'],
    ['Goro', 'E107'],
    ['Fiscaglia', 'M323'],
    ['Terre del Reno', 'M381'],
    ['Riva del Po', 'M410'],
    ['Tresignana', 'M409'],
  ],
  RA: [
    ['Alfonsine', 'A191'],
    ['Bagnacavallo', 'A547'],
    ['Bagnara di Romagna', 'A551'],
    ['Brisighella', 'B188'],
    ['Casola Valsenio', 'B982'],
    ['Castel Bolognese', 'C065'],
    ['Cervia', 'C553'],
    ['Conselice', 'C963'],
    ['Cotignola', 'D121'],
    ['Faenza', 'D458'],
    ['Fusignano', 'D829'],
    ['Lugo', 'E730'],
    ['Massa Lombarda', 'F029'],
    ['Ravenna', 'H199'],
    ['Riolo Terme', 'H302'],
    ['Russi', 'H642'],
    ["Sant'Agata sul Santerno", 'I196'],
    ['Solarolo', 'I787'],
  ],
  FC: [
    ['Bagno di Romagna', 'A565'],
    ['Bertinoro', 'A809'],
    ['Borghi', 'B001'],
    ['Castrocaro Terme e Terra del Sole', 'C339'],
    ['Cesena', 'C573'],
    ['Cesenatico', 'C574'],
    ['Civitella di Romagna', 'C777'],
    ['Dovadola', 'D357'],
    ['Forlì', 'D704'],
    ['Forlimpopoli', 'D705'],
    ['Galeata', 'D867'],
    ['Gambettola', 'D899'],
    ['Gatteo', 'D935'],
    ['Longiano', 'E675'],
    ['Meldola', 'F097'],
    ['Mercato Saraceno', 'F139'],
    ['Modigliana', 'F259'],
    ['Montiano', 'F668'],
    ['Portico e San Benedetto', 'G904'],
    ['Predappio', 'H017'],
    ['Premilcuore', 'H034'],
    ['Rocca San Casciano', 'H437'],
    ['Roncofreddo', 'H542'],
    ['San Mauro Pascoli', 'I027'],
    ['Santa Sofia', 'I310'],
    ['Sarsina', 'I444'],
    ['Savignano sul Rubicone', 'I472'],
    ['Sogliano al Rubicone', 'I779'],
    ['Tredozio', 'L361'],
    ['Verghereto', 'L764'],
  ],
  RN: [
    ['Bellaria-Igea Marina', 'A747'],
    ['Cattolica', 'C357'],
    ['Coriano', 'D004'],
    ['Gemmano', 'D961'],
    ['Misano Adriatico', 'F244'],
    ['Mondaino', 'F346'],
    ['Montefiore Conca', 'F502'],
    ['Montegridolfo', 'F523'],
    ['Morciano di Romagna', 'F715'],
    ['Riccione', 'H274'],
    ['Rimini', 'H294'],
    ['Saludecio', 'H724'],
    ['San Clemente', 'H801'],
    ['San Giovanni in Marignano', 'H921'],
    ['Santarcangelo di Romagna', 'I304'],
    ['Verucchio', 'L797'],
    ['Casteldelci', 'C080'],
    ['Maiolo', 'E838'],
    ['Novafeltria', 'F137'],
    ['Pennabilli', 'G433'],
    ['San Leo', 'H949'],
    ["Sant'Agata Feltria", 'I201'],
    ['Talamello', 'L034'],
    ['Poggio Torriana', 'M324'],
    ['Montescudo-Monte Colombo', 'M368'],
  ],
  MS: [
    ['Aulla', 'A496'],
    ['Bagnone', 'A576'],
    ['Carrara', 'B832'],
    ['Casola in Lunigiana', 'B979'],
    ['Comano', 'C914'],
    ['Filattiera', 'D590'],
    ['Fivizzano', 'D629'],
    ['Fosdinovo', 'D735'],
    ['Licciana Nardi', 'E574'],
    ['Massa', 'F023'],
    ['Montignoso', 'F679'],
    ['Mulazzo', 'F802'],
    ['Podenzana', 'G746'],
    ['Pontremoli', 'G870'],
    ['Tresana', 'L386'],
    ['Villafranca in Lunigiana', 'L946'],
    ['Zeri', 'M169'],
  ],
  LU: [
    ['Altopascio', 'A241'],
    ['Bagni di Lucca', 'A560'],
    ['Barga', 'A657'],
    ['Borgo a Mozzano', 'B007'],
    ['Camaiore', 'B455'],
    ['Camporgiano', 'B557'],
    ['Capannori', 'B648'],
    ['Careggine', 'B760'],
    ['Castelnuovo di Garfagnana', 'C236'],
    ['Castiglione di Garfagnana', 'C303'],
    ['Coreglia Antelminelli', 'C996'],
    ['Forte dei Marmi', 'D730'],
    ['Fosciandora', 'D734'],
    ['Gallicano', 'D874'],
    ['Lucca', 'E715'],
    ['Massarosa', 'F035'],
    ['Minucciano', 'F225'],
    ['Molazzana', 'F283'],
    ['Montecarlo', 'F452'],
    ['Pescaglia', 'G480'],
    ['Piazza al Serchio', 'G582'],
    ['Pietrasanta', 'G628'],
    ['Pieve Fosciana', 'G648'],
    ['Porcari', 'G882'],
    ['San Romano in Garfagnana', 'I142'],
    ['Seravezza', 'I622'],
    ['Stazzema', 'I942'],
    ['Vagli Sotto', 'L533'],
    ['Viareggio', 'L833'],
    ['Villa Basilica', 'L913'],
    ['Villa Collemandina', 'L926'],
    ['Fabbriche di Vergemoli', 'M319'],
    ['Sillano Giuncugnano', 'M347'],
  ],
  PT: [
    ['Agliana', 'A071'],
    ['Buggiano', 'B251'],
    ['Lamporecchio', 'E432'],
    ['Larciano', 'E451'],
    ['Marliana', 'E960'],
    ['Massa e Cozzile', 'F025'],
    ['Monsummano Terme', 'F384'],
    ['Montale', 'F410'],
    ['Montecatini-Terme', 'A561'],
    ['Pescia', 'G491'],
    ['Pieve a Nievole', 'G636'],
    ['Pistoia', 'G713'],
    ['Ponte Buggianese', 'G833'],
    ['Quarrata', 'H109'],
    ['Sambuca Pistoiese', 'H744'],
    ['Serravalle Pistoiese', 'I660'],
    ['Uzzano', 'L522'],
    ['Chiesina Uzzanese', 'C631'],
    ['Abetone Cutigliano', 'M376'],
    ['San Marcello Piteglio', 'M377'],
  ],
  FI: [
    ['Bagno a Ripoli', 'A564'],
    ['Barberino di Mugello', 'A632'],
    ['Borgo San Lorenzo', 'B036'],
    ['Calenzano', 'B406'],
    ['Campi Bisenzio', 'B507'],
    ['Capraia e Limite', 'B684'],
    ['Castelfiorentino', 'C101'],
    ['Cerreto Guidi', 'C529'],
    ['Certaldo', 'C540'],
    ['Dicomano', 'D299'],
    ['Empoli', 'D403'],
    ['Fiesole', 'D575'],
    ['Firenze', 'D612'],
    ['Firenzuola', 'D613'],
    ['Fucecchio', 'D815'],
    ['Gambassi Terme', 'D895'],
    ['Greve in Chianti', 'E169'],
    ['Impruneta', 'E291'],
    ['Lastra a Signa', 'E466'],
    ['Londa', 'E668'],
    ['Marradi', 'E971'],
    ['Montaione', 'F398'],
    ['Montelupo Fiorentino', 'F551'],
    ['Montespertoli', 'F648'],
    ['Palazzuolo sul Senio', 'G270'],
    ['Pelago', 'G420'],
    ['Pontassieve', 'G825'],
    ['Reggello', 'H222'],
    ["Rignano sull'Arno", 'H286'],
    ['Rufina', 'H635'],
    ['San Casciano in Val di Pesa', 'H791'],
    ['San Godenzo', 'H937'],
    ['Scandicci', 'B962'],
    ['Sesto Fiorentino', 'I684'],
    ['Signa', 'I728'],
    ['Vaglia', 'L529'],
    ['Vicchio', 'L838'],
    ['Vinci', 'M059'],
    ['Figline e Incisa Valdarno', 'M321'],
    ['Scarperia e San Piero', 'M326'],
    ['Barberino Tavarnelle', 'M408'],
  ],
  LI: [
    ['Bibbona', 'A852'],
    ['Campiglia Marittima', 'B509'],
    ["Campo nell'Elba", 'B553'],
    ['Capoliveri', 'B669'],
    ['Capraia Isola', 'B685'],
    ['Castagneto Carducci', 'C044'],
    ['Cecina', 'C415'],
    ['Collesalvetti', 'C869'],
    ['Livorno', 'E625'],
    ['Marciana', 'E930'],
    ['Marciana Marina', 'E931'],
    ['Piombino', 'G687'],
    ['Porto Azzurro', 'E680'],
    ['Portoferraio', 'G912'],
    ['Rosignano Marittimo', 'H570'],
    ['San Vincenzo', 'I390'],
    ['Sassetta', 'I454'],
    ['Suvereto', 'L019'],
    ['Rio', 'M391'],
  ],
  PI: [
    ['Bientina', 'A864'],
    ['Buti', 'B303'],
    ['Calci', 'B390'],
    ['Calcinaia', 'B392'],
    ['Capannoli', 'B647'],
    ['Casale Marittimo', 'B878'],
    ['Cascina', 'B950'],
    ['Castelfranco di Sotto', 'C113'],
    ['Castellina Marittima', 'C174'],
    ['Castelnuovo di Val di Cecina', 'C244'],
    ['Chianni', 'C609'],
    ['Fauglia', 'D510'],
    ['Guardistallo', 'E250'],
    ['Lajatico', 'E413'],
    ['Montecatini Val di Cecina', 'F458'],
    ['Montescudaio', 'F640'],
    ['Monteverdi Marittimo', 'F661'],
    ["Montopoli in Val d'Arno", 'F686'],
    ['Orciano Pisano', 'G090'],
    ['Palaia', 'G254'],
    ['Peccioli', 'G395'],
    ['Pisa', 'G702'],
    ['Pomarance', 'G804'],
    ['Ponsacco', 'G822'],
    ['Pontedera', 'G843'],
    ['Riparbella', 'H319'],
    ['San Giuliano Terme', 'A562'],
    ['San Miniato', 'I046'],
    ["Santa Croce sull'Arno", 'I177'],
    ['Santa Luce', 'I217'],
    ['Santa Maria a Monte', 'I232'],
    ['Terricciola', 'L138'],
    ['Vecchiano', 'L702'],
    ['Vicopisano', 'L850'],
    ['Volterra', 'M126'],
    ['Casciana Terme Lari', 'M327'],
    ['Crespina Lorenzana', 'M328'],
  ],
  AR: [
    ['Anghiari', 'A291'],
    ['Arezzo', 'A390'],
    ['Badia Tedalda', 'A541'],
    ['Bibbiena', 'A851'],
    ['Bucine', 'B243'],
    ['Capolona', 'B670'],
    ['Caprese Michelangelo', 'B693'],
    ['Castel Focognano', 'C102'],
    ['Castel San Niccolò', 'C263'],
    ['Castiglion Fibocchi', 'C318'],
    ['Castiglion Fiorentino', 'C319'],
    ['Cavriglia', 'C407'],
    ['Chitignano', 'C648'],
    ['Chiusi della Verna', 'C663'],
    ['Civitella in Val di Chiana', 'C774'],
    ['Cortona', 'D077'],
    ['Foiano della Chiana', 'D649'],
    ['Loro Ciuffenna', 'E693'],
    ['Lucignano', 'E718'],
    ['Marciano della Chiana', 'E933'],
    ['Montemignaio', 'F565'],
    ['Monterchi', 'F594'],
    ['Monte San Savino', 'F628'],
    ['Montevarchi', 'F656'],
    ['Ortignano Raggiolo', 'G139'],
    ['Pieve Santo Stefano', 'G653'],
    ['Poppi', 'G879'],
    ['San Giovanni Valdarno', 'H901'],
    ['Sansepolcro', 'I155'],
    ['Sestino', 'I681'],
    ['Subbiano', 'I991'],
    ['Talla', 'L038'],
    ['Terranuova Bracciolini', 'L123'],
    ['Castelfranco Piandiscò', 'M322'],
    ['Pratovecchio Stia', 'M329'],
    ['Laterina Pergine Valdarno', 'M392'],
  ],
  SI: [
    ['Abbadia San Salvatore', 'A006'],
    ['Asciano', 'A461'],
    ['Buonconvento', 'B269'],
    ["Casole d'Elsa", 'B984'],
    ['Castellina in Chianti', 'C172'],
    ['Castelnuovo Berardenga', 'C227'],
    ["Castiglione d'Orcia", 'C313'],
    ['Cetona', 'C587'],
    ['Chianciano Terme', 'C608'],
    ['Chiusdino', 'C661'],
    ['Chiusi', 'C662'],
    ["Colle di Val d'Elsa", 'C847'],
    ['Gaiole in Chianti', 'D858'],
    ['Montepulciano', 'F592'],
    ['Monteriggioni', 'F598'],
    ["Monteroni d'Arbia", 'F605'],
    ['Monticiano', 'F676'],
    ['Murlo', 'F815'],
    ['Piancastagnaio', 'G547'],
    ['Pienza', 'G602'],
    ['Poggibonsi', 'G752'],
    ['Radda in Chianti', 'H153'],
    ['Radicofani', 'H156'],
    ['Radicondoli', 'H157'],
    ['Rapolano Terme', 'H185'],
    ['San Casciano dei Bagni', 'H790'],
    ['San Gimignano', 'H875'],
    ["San Quirico d'Orcia", 'I135'],
    ['Sarteano', 'I445'],
    ['Siena', 'I726'],
    ['Sinalunga', 'A468'],
    ['Sovicille', 'I877'],
    ['Torrita di Siena', 'L303'],
    ['Trequanda', 'L384'],
    ['Montalcino', 'M378'],
  ],
  GR: [
    ['Arcidosso', 'A369'],
    ['Campagnatico', 'B497'],
    ['Capalbio', 'B646'],
    ['Castel del Piano', 'C085'],
    ["Castell'Azzara", 'C147'],
    ['Castiglione della Pescaia', 'C310'],
    ['Cinigiano', 'C705'],
    ['Civitella Paganico', 'C782'],
    ['Follonica', 'D656'],
    ['Gavorrano', 'D948'],
    ['Grosseto', 'E202'],
    ['Isola del Giglio', 'E348'],
    ['Magliano in Toscana', 'E810'],
    ['Manciano', 'E875'],
    ['Massa Marittima', 'F032'],
    ['Monte Argentario', 'F437'],
    ['Montieri', 'F677'],
    ['Orbetello', 'G088'],
    ['Pitigliano', 'G716'],
    ['Roccalbegna', 'H417'],
    ['Roccastrada', 'H449'],
    ['Santa Fiora', 'I187'],
    ['Scansano', 'I504'],
    ['Scarlino', 'I510'],
    ['Seggiano', 'I571'],
    ['Sorano', 'I841'],
    ['Monterotondo Marittimo', 'F612'],
    ['Semproniano', 'I601'],
  ],
  PO: [
    ['Cantagallo', 'B626'],
    ['Carmignano', 'B794'],
    ['Montemurlo', 'F572'],
    ['Poggio a Caiano', 'G754'],
    ['Prato', 'G999'],
    ['Vaiano', 'L537'],
    ['Vernio', 'L775'],
  ],
  PG: [
    ['Assisi', 'A475'],
    ['Bastia Umbra', 'A710'],
    ['Bettona', 'A832'],
    ['Bevagna', 'A835'],
    ['Campello sul Clitunno', 'B504'],
    ['Cannara', 'B609'],
    ['Cascia', 'B948'],
    ['Castel Ritaldi', 'C252'],
    ['Castiglione del Lago', 'C309'],
    ['Cerreto di Spoleto', 'C527'],
    ['Citerna', 'C742'],
    ['Città della Pieve', 'C744'],
    ['Città di Castello', 'C745'],
    ['Collazzone', 'C845'],
    ['Corciano', 'C990'],
    ['Costacciaro', 'D108'],
    ['Deruta', 'D279'],
    ['Foligno', 'D653'],
    ['Fossato di Vico', 'D745'],
    ['Fratta Todina', 'D787'],
    ["Giano dell'Umbria", 'E012'],
    ['Gualdo Cattaneo', 'E229'],
    ['Gualdo Tadino', 'E230'],
    ['Gubbio', 'E256'],
    ['Lisciano Niccone', 'E613'],
    ['Magione', 'E805'],
    ['Marsciano', 'E975'],
    ['Massa Martana', 'F024'],
    ['Monte Castello di Vibio', 'F456'],
    ['Montefalco', 'F492'],
    ['Monteleone di Spoleto', 'F540'],
    ['Monte Santa Maria Tiberina', 'F629'],
    ['Montone', 'F685'],
    ['Nocera Umbra', 'F911'],
    ['Norcia', 'F935'],
    ['Paciano', 'G212'],
    ['Panicale', 'G308'],
    ['Passignano sul Trasimeno', 'G359'],
    ['Perugia', 'G478'],
    ['Piegaro', 'G601'],
    ['Pietralunga', 'G618'],
    ['Poggiodomo', 'G758'],
    ['Preci', 'H015'],
    ['San Giustino', 'H935'],
    ["Sant'Anatolia di Narco", 'I263'],
    ['Scheggia e Pascelupo', 'I522'],
    ['Scheggino', 'I523'],
    ['Sellano', 'I585'],
    ['Sigillo', 'I727'],
    ['Spello', 'I888'],
    ['Spoleto', 'I921'],
    ['Todi', 'L188'],
    ['Torgiano', 'L216'],
    ['Trevi', 'L397'],
    ['Tuoro sul Trasimeno', 'L466'],
    ['Umbertide', 'D786'],
    ['Valfabbrica', 'L573'],
    ['Vallo di Nera', 'L627'],
    ['Valtopina', 'L653'],
  ],
  TR: [
    ['Acquasparta', 'A045'],
    ['Allerona', 'A207'],
    ['Alviano', 'A242'],
    ['Amelia', 'A262'],
    ['Arrone', 'A439'],
    ['Attigliano', 'A490'],
    ['Baschi', 'A691'],
    ["Calvi dell'Umbria", 'B446'],
    ['Castel Giorgio', 'C117'],
    ['Castel Viscardo', 'C289'],
    ['Fabro', 'D454'],
    ['Ferentillo', 'D538'],
    ['Ficulle', 'D570'],
    ['Giove', 'E045'],
    ['Guardea', 'E241'],
    ['Lugnano in Teverina', 'E729'],
    ['Montecastrilli', 'F457'],
    ['Montecchio', 'F462'],
    ['Montefranco', 'F510'],
    ['Montegabbione', 'F513'],
    ["Monteleone d'Orvieto", 'F543'],
    ['Narni', 'F844'],
    ['Orvieto', 'G148'],
    ['Otricoli', 'G189'],
    ['Parrano', 'G344'],
    ['Penna in Teverina', 'G432'],
    ['Polino', 'G790'],
    ['Porano', 'G881'],
    ['San Gemini', 'H857'],
    ['San Venanzo', 'I381'],
    ['Stroncone', 'I981'],
    ['Terni', 'L117'],
    ['Avigliano Umbro', 'M258'],
  ],
  PU: [
    ['Acqualagna', 'A035'],
    ['Apecchio', 'A327'],
    ["Belforte all'Isauro", 'A740'],
    ['Borgo Pace', 'B026'],
    ['Cagli', 'B352'],
    ['Cantiano', 'B636'],
    ['Carpegna', 'B816'],
    ['Cartoceto', 'B846'],
    ['Fano', 'D488'],
    ['Fermignano', 'D541'],
    ['Fossombrone', 'D749'],
    ['Fratte Rosa', 'D791'],
    ['Frontino', 'D807'],
    ['Frontone', 'D808'],
    ['Gabicce Mare', 'D836'],
    ['Gradara', 'E122'],
    ['Isola del Piano', 'E351'],
    ['Lunano', 'E743'],
    ['Macerata Feltria', 'E785'],
    ['Mercatello sul Metauro', 'F135'],
    ['Mercatino Conca', 'F136'],
    ['Mombaroccio', 'F310'],
    ['Mondavio', 'F347'],
    ['Mondolfo', 'F348'],
    ['Montecalvo in Foglia', 'F450'],
    ['Monte Cerignone', 'F467'],
    ['Monteciccardo', 'F474'],
    ['Montecopiolo', 'F478'],
    ['Montefelcino', 'F497'],
    ['Monte Grimano Terme', 'F524'],
    ['Montelabbate', 'F533'],
    ['Monte Porzio', 'F589'],
    ['Peglio', 'G416'],
    ['Pergola', 'G453'],
    ['Pesaro', 'G479'],
    ['Petriano', 'G514'],
    ['Piandimeleto', 'G551'],
    ['Pietrarubbia', 'G627'],
    ['Piobbico', 'G682'],
    ['San Costanzo', 'H809'],
    ['San Lorenzo in Campo', 'H958'],
    ["Sant'Angelo in Vado", 'I287'],
    ["Sant'Ippolito", 'I344'],
    ['Sassofeltrio', 'I460'],
    ["Serra Sant'Abbondio", 'I654'],
    ['Tavoleto', 'L078'],
    ['Tavullia', 'L081'],
    ['Urbania', 'L498'],
    ['Urbino', 'L500'],
    ['Vallefoglia', 'M331'],
    ['Colli al Metauro', 'M380'],
    ['Terre Roveresche', 'M379'],
    ['Sassocorvaro Auditore', 'M413'],
  ],
  AN: [
    ['Agugliano', 'A092'],
    ['Ancona', 'A271'],
    ['Arcevia', 'A366'],
    ['Barbara', 'A626'],
    ['Belvedere Ostrense', 'A769'],
    ['Camerano', 'B468'],
    ['Camerata Picena', 'B470'],
    ['Castelbellino', 'C060'],
    ['Castelfidardo', 'C100'],
    ['Castelleone di Suasa', 'C152'],
    ['Castelplanio', 'C248'],
    ["Cerreto d'Esi", 'C524'],
    ['Chiaravalle', 'C615'],
    ['Corinaldo', 'D007'],
    ['Cupramontana', 'D211'],
    ['Fabriano', 'D451'],
    ['Falconara Marittima', 'D472'],
    ['Filottrano', 'D597'],
    ['Genga', 'D965'],
    ['Jesi', 'E388'],
    ['Loreto', 'E690'],
    ['Maiolati Spontini', 'E837'],
    ['Mergo', 'F145'],
    ['Monsano', 'F381'],
    ['Montecarotto', 'F453'],
    ['Montemarciano', 'F560'],
    ['Monte Roberto', 'F600'],
    ['Monte San Vito', 'F634'],
    ["Morro d'Alba", 'F745'],
    ['Numana', 'F978'],
    ['Offagna', 'G003'],
    ['Osimo', 'G157'],
    ['Ostra', 'F401'],
    ['Ostra Vetere', 'F581'],
    ['Poggio San Marcello', 'G771'],
    ['Polverigi', 'G803'],
    ['Rosora', 'H575'],
    ['San Marcello', 'H979'],
    ['San Paolo di Jesi', 'I071'],
    ['Santa Maria Nuova', 'I251'],
    ['Sassoferrato', 'I461'],
    ['Senigallia', 'I608'],
    ["Serra de' Conti", 'I643'],
    ['Serra San Quirico', 'I653'],
    ['Sirolo', 'I758'],
    ['Staffolo', 'I932'],
    ['Trecastelli', 'M318'],
  ],
  MC: [
    ['Apiro', 'A329'],
    ['Appignano', 'A334'],
    ['Belforte del Chienti', 'A739'],
    ['Bolognola', 'A947'],
    ['Caldarola', 'B398'],
    ['Camerino', 'B474'],
    ['Camporotondo di Fiastrone', 'B562'],
    ['Castelraimondo', 'C251'],
    ['Castelsantangelo sul Nera', 'C267'],
    ['Cessapalombo', 'C582'],
    ['Cingoli', 'C704'],
    ['Civitanova Marche', 'C770'],
    ['Colmurano', 'C886'],
    ['Corridonia', 'D042'],
    ['Esanatoglia', 'D429'],
    ['Fiastra', 'D564'],
    ['Fiuminata', 'D628'],
    ['Gagliole', 'D853'],
    ['Gualdo', 'E228'],
    ['Loro Piceno', 'E694'],
    ['Macerata', 'E783'],
    ['Matelica', 'F051'],
    ['Mogliano', 'F268'],
    ['Montecassiano', 'F454'],
    ['Monte Cavallo', 'F460'],
    ['Montecosaro', 'F482'],
    ['Montefano', 'F496'],
    ['Montelupone', 'F552'],
    ['Monte San Giusto', 'F621'],
    ['Monte San Martino', 'F622'],
    ['Morrovalle', 'F749'],
    ['Muccia', 'F793'],
    ['Penna San Giovanni', 'G436'],
    ['Petriolo', 'G515'],
    ['Pieve Torina', 'G657'],
    ['Pioraco', 'G690'],
    ['Poggio San Vicino', 'D566'],
    ['Pollenza', 'F567'],
    ['Porto Recanati', 'G919'],
    ['Potenza Picena', 'F632'],
    ['Recanati', 'H211'],
    ['Ripe San Ginesio', 'H323'],
    ['San Ginesio', 'H876'],
    ['San Severino Marche', 'I156'],
    ["Sant'Angelo in Pontano", 'I286'],
    ['Sarnano', 'I436'],
    ['Sefro', 'I569'],
    ['Serrapetrona', 'I651'],
    ['Serravalle di Chienti', 'I661'],
    ['Tolentino', 'L191'],
    ['Treia', 'L366'],
    ['Urbisaglia', 'L501'],
    ['Ussita', 'L517'],
    ['Visso', 'M078'],
    ['Valfornace', 'M382'],
  ],
  AP: [
    ['Acquasanta Terme', 'A044'],
    ['Acquaviva Picena', 'A047'],
    ['Appignano del Tronto', 'A335'],
    ['Arquata del Tronto', 'A437'],
    ['Ascoli Piceno', 'A462'],
    ['Carassai', 'B727'],
    ['Castel di Lama', 'C093'],
    ['Castignano', 'C321'],
    ['Castorano', 'C331'],
    ['Colli del Tronto', 'C877'],
    ['Comunanza', 'C935'],
    ['Cossignano', 'D096'],
    ['Cupra Marittima', 'D210'],
    ['Folignano', 'D652'],
    ['Force', 'D691'],
    ['Grottammare', 'E207'],
    ['Maltignano', 'E868'],
    ['Massignano', 'F044'],
    ['Monsampolo del Tronto', 'F380'],
    ['Montalto delle Marche', 'F415'],
    ['Montedinove', 'F487'],
    ["Montefiore dell'Aso", 'F501'],
    ['Montegallo', 'F516'],
    ['Montemonaco', 'F570'],
    ['Monteprandone', 'F591'],
    ['Offida', 'G005'],
    ['Palmiano', 'G289'],
    ['Ripatransone', 'H321'],
    ['Roccafluvione', 'H390'],
    ['Rotella', 'H588'],
    ['San Benedetto del Tronto', 'H769'],
    ['Spinetoli', 'I912'],
    ['Venarotta', 'L728'],
  ],
  FM: [
    ['Altidona', 'A233'],
    ['Amandola', 'A252'],
    ['Belmonte Piceno', 'A760'],
    ['Campofilone', 'B534'],
    ['Falerone', 'D477'],
    ['Fermo', 'D542'],
    ["Francavilla d'Ete", 'D760'],
    ['Grottazzolina', 'E208'],
    ['Lapedona', 'E447'],
    ['Magliano di Tenna', 'E807'],
    ['Massa Fermana', 'F021'],
    ['Monsampietro Morico', 'F379'],
    ['Montappone', 'F428'],
    ['Montefalcone Appennino', 'F493'],
    ['Montefortino', 'F509'],
    ['Monte Giberto', 'F517'],
    ['Montegiorgio', 'F520'],
    ['Montegranaro', 'F522'],
    ['Monteleone di Fermo', 'F536'],
    ['Montelparo', 'F549'],
    ['Monte Rinaldo', 'F599'],
    ['Monterubbiano', 'F614'],
    ['Monte San Pietrangeli', 'F626'],
    ['Monte Urano', 'F653'],
    ['Monte Vidon Combatte', 'F664'],
    ['Monte Vidon Corrado', 'F665'],
    ['Montottone', 'F697'],
    ['Moresco', 'F722'],
    ['Ortezzano', 'G137'],
    ['Pedaso', 'G403'],
    ['Petritoli', 'G516'],
    ['Ponzano di Fermo', 'G873'],
    ['Porto San Giorgio', 'G920'],
    ["Porto Sant'Elpidio", 'G921'],
    ['Rapagnano', 'H182'],
    ['Santa Vittoria in Matenano', 'I315'],
    ["Sant'Elpidio a Mare", 'I324'],
    ['Servigliano', 'C070'],
    ['Smerillo', 'I774'],
    ['Torre San Patrizio', 'L279'],
  ],
  VT: [
    ['Acquapendente', 'A040'],
    ['Arlena di Castro', 'A412'],
    ['Bagnoregio', 'A577'],
    ['Barbarano Romano', 'A628'],
    ['Bassano Romano', 'A704'],
    ['Bassano in Teverina', 'A706'],
    ['Blera', 'A857'],
    ['Bolsena', 'A949'],
    ['Bomarzo', 'A955'],
    ['Calcata', 'B388'],
    ['Canepina', 'B597'],
    ['Canino', 'B604'],
    ['Capodimonte', 'B663'],
    ['Capranica', 'B688'],
    ['Caprarola', 'B691'],
    ['Carbognano', 'B735'],
    ["Castel Sant'Elia", 'C269'],
    ['Castiglione in Teverina', 'C315'],
    ['Celleno', 'C446'],
    ['Cellere', 'C447'],
    ['Civita Castellana', 'C765'],
    ["Civitella d'Agliano", 'C780'],
    ['Corchiano', 'C988'],
    ['Fabrica di Roma', 'D452'],
    ['Faleria', 'D475'],
    ['Farnese', 'D503'],
    ['Gallese', 'D870'],
    ['Gradoli', 'E126'],
    ['Graffignano', 'E128'],
    ['Grotte di Castro', 'E210'],
    ['Ischia di Castro', 'E330'],
    ['Latera', 'E467'],
    ['Lubriano', 'E713'],
    ['Marta', 'E978'],
    ['Montalto di Castro', 'F419'],
    ['Montefiascone', 'F499'],
    ['Monte Romano', 'F603'],
    ['Monterosi', 'F606'],
    ['Nepi', 'F868'],
    ['Onano', 'G065'],
    ['Oriolo Romano', 'G111'],
    ['Orte', 'G135'],
    ['Piansano', 'G571'],
    ['Proceno', 'H071'],
    ['Ronciglione', 'H534'],
    ['Villa San Giovanni in Tuscia', 'H913'],
    ['San Lorenzo Nuovo', 'H969'],
    ['Soriano nel Cimino', 'I855'],
    ['Sutri', 'L017'],
    ['Tarquinia', 'D024'],
    ['Tessennano', 'L150'],
    ['Tuscania', 'L310'],
    ['Valentano', 'L569'],
    ['Vallerano', 'L612'],
    ['Vasanello', 'A701'],
    ['Vejano', 'L713'],
    ['Vetralla', 'L814'],
    ['Vignanello', 'L882'],
    ['Viterbo', 'M082'],
    ['Vitorchiano', 'M086'],
  ],
  RI: [
    ['Accumoli', 'A019'],
    ['Amatrice', 'A258'],
    ['Antrodoco', 'A315'],
    ['Ascrea', 'A464'],
    ['Belmonte in Sabina', 'A765'],
    ['Borbona', 'A981'],
    ['Borgorose', 'B008'],
    ['Borgo Velino', 'A996'],
    ['Cantalice', 'B627'],
    ['Cantalupo in Sabina', 'B631'],
    ['Casaprota', 'B934'],
    ['Casperia', 'A472'],
    ['Castel di Tora', 'C098'],
    ['Castelnuovo di Farfa', 'C224'],
    ["Castel Sant'Angelo", 'C268'],
    ['Cittaducale', 'C746'],
    ['Cittareale', 'C749'],
    ['Collalto Sabino', 'C841'],
    ['Colle di Tora', 'C857'],
    ['Collegiove', 'C859'],
    ['Collevecchio', 'C876'],
    ['Colli sul Velino', 'C880'],
    ['Concerviano', 'C946'],
    ['Configni', 'C959'],
    ['Contigliano', 'C969'],
    ['Cottanello', 'D124'],
    ['Fara in Sabina', 'D493'],
    ['Fiamignano', 'D560'],
    ['Forano', 'D689'],
    ['Frasso Sabino', 'D785'],
    ['Greccio', 'E160'],
    ['Labro', 'E393'],
    ['Leonessa', 'E535'],
    ['Longone Sabino', 'E681'],
    ['Magliano Sabina', 'E812'],
    ['Marcetelli', 'E927'],
    ['Micigliano', 'F193'],
    ['Mompeo', 'F319'],
    ['Montasola', 'F430'],
    ['Montebuono', 'F446'],
    ['Monteleone Sabino', 'F541'],
    ['Montenero Sabino', 'F579'],
    ['Monte San Giovanni in Sabina', 'F619'],
    ['Montopoli di Sabina', 'F687'],
    ['Morro Reatino', 'F746'],
    ['Nespolo', 'F876'],
    ['Orvinio', 'B595'],
    ['Paganico Sabino', 'G232'],
    ['Pescorocchiano', 'G498'],
    ['Petrella Salto', 'G513'],
    ['Poggio Bustone', 'G756'],
    ['Poggio Catino', 'G757'],
    ['Poggio Mirteto', 'G763'],
    ['Poggio Moiano', 'G764'],
    ['Poggio Nativo', 'G765'],
    ['Poggio San Lorenzo', 'G770'],
    ['Posta', 'G934'],
    ['Pozzaglia Sabina', 'G951'],
    ['Rieti', 'H282'],
    ['Rivodutri', 'H354'],
    ['Roccantica', 'H427'],
    ['Rocca Sinibalda', 'H446'],
    ['Salisano', 'H713'],
    ['Scandriglia', 'I499'],
    ['Selci', 'I581'],
    ['Stimigliano', 'I959'],
    ['Tarano', 'L046'],
    ['Toffia', 'L189'],
    ['Torricella in Sabina', 'L293'],
    ['Torri in Sabina', 'L286'],
    ['Turania', 'G507'],
    ['Vacone', 'L525'],
    ['Varco Sabino', 'L676'],
  ],
  RM: [
    ['Affile', 'A062'],
    ['Agosta', 'A084'],
    ['Albano Laziale', 'A132'],
    ['Allumiere', 'A210'],
    ['Anguillara Sabazia', 'A297'],
    ['Anticoli Corrado', 'A309'],
    ['Anzio', 'A323'],
    ['Arcinazzo Romano', 'A370'],
    ['Ariccia', 'A401'],
    ['Arsoli', 'A446'],
    ['Artena', 'A449'],
    ['Bellegra', 'A749'],
    ['Bracciano', 'B114'],
    ['Camerata Nuova', 'B472'],
    ['Campagnano di Roma', 'B496'],
    ['Canale Monterano', 'B576'],
    ['Canterano', 'B635'],
    ['Capena', 'B649'],
    ['Capranica Prenestina', 'B687'],
    ['Carpineto Romano', 'B828'],
    ['Casape', 'B932'],
    ['Castel Gandolfo', 'C116'],
    ['Castel Madama', 'C203'],
    ['Castelnuovo di Porto', 'C237'],
    ['Castel San Pietro Romano', 'C266'],
    ['Cave', 'C390'],
    ['Cerreto Laziale', 'C518'],
    ['Cervara di Roma', 'C543'],
    ['Cerveteri', 'C552'],
    ['Ciciliano', 'C677'],
    ['Cineto Romano', 'C702'],
    ['Civitavecchia', 'C773'],
    ['Civitella San Paolo', 'C784'],
    ['Colleferro', 'C858'],
    ['Colonna', 'C900'],
    ['Fiano Romano', 'D561'],
    ['Filacciano', 'D586'],
    ['Formello', 'D707'],
    ['Frascati', 'D773'],
    ['Gallicano nel Lazio', 'D875'],
    ['Gavignano', 'D945'],
    ['Genazzano', 'D964'],
    ['Genzano di Roma', 'D972'],
    ['Gerano', 'D978'],
    ['Gorga', 'E091'],
    ['Grottaferrata', 'E204'],
    ['Guidonia Montecelio', 'E263'],
    ['Jenne', 'E382'],
    ['Labico', 'E392'],
    ['Lanuvio', 'C767'],
    ['Licenza', 'E576'],
    ['Magliano Romano', 'E813'],
    ['Mandela', 'B632'],
    ['Manziana', 'E900'],
    ['Marano Equo', 'E908'],
    ['Marcellina', 'E924'],
    ['Marino', 'E958'],
    ['Mazzano Romano', 'F064'],
    ['Mentana', 'F127'],
    ['Monte Compatri', 'F477'],
    ['Monteflavio', 'F504'],
    ['Montelanico', 'F534'],
    ['Montelibretti', 'F545'],
    ['Monte Porzio Catone', 'F590'],
    ['Monterotondo', 'F611'],
    ['Montorio Romano', 'F692'],
    ['Moricone', 'F730'],
    ['Morlupo', 'F734'],
    ['Nazzano', 'F857'],
    ['Nemi', 'F865'],
    ['Nerola', 'F871'],
    ['Nettuno', 'F880'],
    ['Olevano Romano', 'G022'],
    ['Palestrina', 'G274'],
    ['Palombara Sabina', 'G293'],
    ['Percile', 'G444'],
    ['Pisoniano', 'G704'],
    ['Poli', 'G784'],
    ['Pomezia', 'G811'],
    ['Ponzano Romano', 'G874'],
    ['Riano', 'H267'],
    ['Rignano Flaminio', 'H288'],
    ['Riofreddo', 'H300'],
    ['Rocca Canterano', 'H387'],
    ['Rocca di Cave', 'H401'],
    ['Rocca di Papa', 'H404'],
    ['Roccagiovine', 'H411'],
    ['Rocca Priora', 'H432'],
    ['Rocca Santo Stefano', 'H441'],
    ['Roiate', 'H494'],
    ['Roma', 'H501'],
    ['Roviano', 'H618'],
    ['Sacrofano', 'H658'],
    ['Sambuci', 'H745'],
    ['San Gregorio da Sassola', 'H942'],
    ['San Polo dei Cavalieri', 'I125'],
    ['Santa Marinella', 'I255'],
    ["Sant'Angelo Romano", 'I284'],
    ["Sant'Oreste", 'I352'],
    ['San Vito Romano', 'I400'],
    ['Saracinesco', 'I424'],
    ['Segni', 'I573'],
    ['Subiaco', 'I992'],
    ['Tivoli', 'L182'],
    ['Tolfa', 'L192'],
    ['Torrita Tiberina', 'L302'],
    ['Trevignano Romano', 'L401'],
    ['Vallepietra', 'L611'],
    ['Vallinfreda', 'L625'],
    ['Valmontone', 'L639'],
    ['Velletri', 'L719'],
    ['Vicovaro', 'L851'],
    ['Vivaro Romano', 'M095'],
    ['Zagarolo', 'M141'],
    ['Lariano', 'M207'],
    ['Ladispoli', 'M212'],
    ['Ardea', 'M213'],
    ['Ciampino', 'M272'],
    ['San Cesareo', 'M295'],
    ['Fiumicino', 'M297'],
    ['Fonte Nuova', 'M309'],
  ],
  LT: [
    ['Aprilia', 'A341'],
    ['Bassiano', 'A707'],
    ['Campodimele', 'B527'],
    ['Castelforte', 'C104'],
    ['Cisterna di Latina', 'C740'],
    ['Cori', 'D003'],
    ['Fondi', 'D662'],
    ['Formia', 'D708'],
    ['Gaeta', 'D843'],
    ['Itri', 'E375'],
    ['Latina', 'E472'],
    ['Lenola', 'E527'],
    ['Maenza', 'E798'],
    ['Minturno', 'F224'],
    ['Monte San Biagio', 'F616'],
    ['Norma', 'F937'],
    ['Pontinia', 'G865'],
    ['Ponza', 'G871'],
    ['Priverno', 'G698'],
    ['Prossedi', 'H076'],
    ['Roccagorga', 'H413'],
    ['Rocca Massima', 'H421'],
    ['Roccasecca dei Volsci', 'H444'],
    ['Sabaudia', 'H647'],
    ['San Felice Circeo', 'H836'],
    ['Santi Cosma e Damiano', 'I339'],
    ['Sermoneta', 'I634'],
    ['Sezze', 'I712'],
    ['Sonnino', 'I832'],
    ['Sperlonga', 'I892'],
    ['Spigno Saturnia', 'I902'],
    ['Terracina', 'L120'],
    ['Ventotene', 'L742'],
  ],
  FR: [
    ['Acquafondata', 'A032'],
    ['Acuto', 'A054'],
    ['Alatri', 'A123'],
    ['Alvito', 'A244'],
    ['Amaseno', 'A256'],
    ['Anagni', 'A269'],
    ['Aquino', 'A348'],
    ['Arce', 'A363'],
    ['Arnara', 'A421'],
    ['Arpino', 'A433'],
    ['Atina', 'A486'],
    ['Ausonia', 'A502'],
    ['Belmonte Castello', 'A763'],
    ['Boville Ernica', 'A720'],
    ['Broccostella', 'B195'],
    ['Campoli Appennino', 'B543'],
    ['Casalattico', 'B862'],
    ['Casalvieri', 'B919'],
    ['Cassino', 'C034'],
    ['Castelliri', 'C177'],
    ['Castelnuovo Parano', 'C223'],
    ['Castrocielo', 'C340'],
    ['Castro dei Volsci', 'C338'],
    ['Ceccano', 'C413'],
    ['Ceprano', 'C479'],
    ['Cervaro', 'C545'],
    ['Colfelice', 'C836'],
    ['Collepardo', 'C864'],
    ['Colle San Magno', 'C870'],
    ['Coreno Ausonio', 'C998'],
    ['Esperia', 'D440'],
    ['Falvaterra', 'D483'],
    ['Ferentino', 'D539'],
    ['Filettino', 'D591'],
    ['Fiuggi', 'A310'],
    ['Fontana Liri', 'D667'],
    ['Fontechiari', 'D682'],
    ['Frosinone', 'D810'],
    ['Fumone', 'D819'],
    ['Gallinaro', 'D881'],
    ['Giuliano di Roma', 'E057'],
    ['Guarcino', 'E236'],
    ['Isola del Liri', 'E340'],
    ['Monte San Giovanni Campano', 'F620'],
    ['Morolo', 'F740'],
    ['Paliano', 'G276'],
    ['Pastena', 'G362'],
    ['Patrica', 'G374'],
    ['Pescosolido', 'G500'],
    ['Picinisco', 'G591'],
    ['Pico', 'G592'],
    ['Piedimonte San Germano', 'G598'],
    ['Piglio', 'G659'],
    ['Pignataro Interamna', 'G662'],
    ['Pofi', 'G749'],
    ['Pontecorvo', 'G838'],
    ['Posta Fibreno', 'G935'],
    ['Ripi', 'H324'],
    ["Rocca d'Arce", 'H393'],
    ['Roccasecca', 'H443'],
    ['San Biagio Saracinisco', 'H779'],
    ['San Donato Val di Comino', 'H824'],
    ['San Giorgio a Liri', 'H880'],
    ['San Giovanni Incarico', 'H917'],
    ["Sant'Ambrogio sul Garigliano", 'I256'],
    ["Sant'Andrea del Garigliano", 'I265'],
    ["Sant'Apollinare", 'I302'],
    ["Sant'Elia Fiumerapido", 'I321'],
    ['Santopadre', 'I351'],
    ['San Vittore del Lazio', 'I408'],
    ['Serrone', 'I669'],
    ['Settefrati', 'I697'],
    ['Sgurgola', 'I716'],
    ['Sora', 'I838'],
    ['Strangolagalli', 'I973'],
    ['Supino', 'L009'],
    ['Terelle', 'L105'],
    ['Torre Cajetani', 'L243'],
    ['Torrice', 'L290'],
    ['Trevi nel Lazio', 'L398'],
    ['Trivigliano', 'L437'],
    ['Vallecorsa', 'L598'],
    ['Vallemaio', 'L605'],
    ['Vallerotonda', 'L614'],
    ['Veroli', 'L780'],
    ['Vicalvi', 'L836'],
    ['Vico nel Lazio', 'L843'],
    ['Villa Latina', 'A081'],
    ['Villa Santa Lucia', 'L905'],
    ['Villa Santo Stefano', 'I364'],
    ['Viticuso', 'M083'],
  ],
  AQ: [
    ['Acciano', 'A018'],
    ['Aielli', 'A100'],
    ['Alfedena', 'A187'],
    ['Anversa degli Abruzzi', 'A318'],
    ['Ateleta', 'A481'],
    ['Avezzano', 'A515'],
    ['Balsorano', 'A603'],
    ['Barete', 'A656'],
    ['Barisciano', 'A667'],
    ['Barrea', 'A678'],
    ['Bisegna', 'A884'],
    ['Bugnara', 'B256'],
    ['Cagnano Amiterno', 'B358'],
    ['Calascio', 'B382'],
    ['Campo di Giove', 'B526'],
    ['Campotosto', 'B569'],
    ['Canistro', 'B606'],
    ['Cansano', 'B624'],
    ['Capestrano', 'B651'],
    ['Capistrello', 'B656'],
    ['Capitignano', 'B658'],
    ['Caporciano', 'B672'],
    ['Cappadocia', 'B677'],
    ['Carapelle Calvisio', 'B725'],
    ['Carsoli', 'B842'],
    ['Castel del Monte', 'C083'],
    ['Castel di Ieri', 'C090'],
    ['Castel di Sangro', 'C096'],
    ['Castellafiume', 'C126'],
    ['Castelvecchio Calvisio', 'C278'],
    ['Castelvecchio Subequo', 'C279'],
    ['Celano', 'C426'],
    ['Cerchio', 'C492'],
    ["Civita d'Antino", 'C766'],
    ['Civitella Alfedena', 'C778'],
    ['Civitella Roveto', 'C783'],
    ['Cocullo', 'C811'],
    ['Collarmele', 'C844'],
    ['Collelongo', 'C862'],
    ['Collepietro', 'C866'],
    ['Corfinio', 'C999'],
    ['Fagnano Alto', 'D465'],
    ['Fontecchio', 'D681'],
    ['Fossa', 'D736'],
    ['Gagliano Aterno', 'D850'],
    ['Gioia dei Marsi', 'E040'],
    ['Goriano Sicoli', 'E096'],
    ['Introdacqua', 'E307'],
    ["L'Aquila", 'A345'],
    ['Lecce nei Marsi', 'E505'],
    ['Luco dei Marsi', 'E723'],
    ['Lucoli', 'E724'],
    ["Magliano de' Marsi", 'E811'],
    ["Massa d'Albe", 'F022'],
    ['Molina Aterno', 'M255'],
    ['Montereale', 'F595'],
    ['Morino', 'F732'],
    ['Navelli', 'F852'],
    ['Ocre', 'F996'],
    ['Ofena', 'G002'],
    ['Opi', 'G079'],
    ['Oricola', 'G102'],
    ['Ortona dei Marsi', 'G142'],
    ['Ortucchio', 'G145'],
    ['Ovindoli', 'G200'],
    ['Pacentro', 'G210'],
    ['Pereto', 'G449'],
    ['Pescasseroli', 'G484'],
    ['Pescina', 'G492'],
    ['Pescocostanzo', 'G493'],
    ['Pettorano sul Gizio', 'G524'],
    ['Pizzoli', 'G726'],
    ['Poggio Picenze', 'G766'],
    ["Prata d'Ansidonia", 'G992'],
    ['Pratola Peligna', 'H007'],
    ['Prezza', 'H056'],
    ['Raiano', 'H166'],
    ['Rivisondoli', 'H353'],
    ['Roccacasale', 'H389'],
    ['Rocca di Botte', 'H399'],
    ['Rocca di Cambio', 'H400'],
    ['Rocca di Mezzo', 'H402'],
    ['Rocca Pia', 'H429'],
    ['Roccaraso', 'H434'],
    ['San Benedetto dei Marsi', 'H772'],
    ['San Benedetto in Perillis', 'H773'],
    ["San Demetrio ne' Vestini", 'H819'],
    ['San Pio delle Camere', 'I121'],
    ['Sante Marie', 'I326'],
    ["Sant'Eusanio Forconese", 'I336'],
    ['Santo Stefano di Sessanio', 'I360'],
    ['San Vincenzo Valle Roveto', 'I389'],
    ['Scanno', 'I501'],
    ['Scontrone', 'I543'],
    ['Scoppito', 'I546'],
    ['Scurcola Marsicana', 'I553'],
    ['Secinaro', 'I558'],
    ['Sulmona', 'I804'],
    ['Tagliacozzo', 'L025'],
    ['Tione degli Abruzzi', 'L173'],
    ['Tornimparte', 'L227'],
    ['Trasacco', 'L334'],
    ['Villalago', 'L958'],
    ['Villa Santa Lucia degli Abruzzi', 'M021'],
    ["Villa Sant'Angelo", 'M023'],
    ['Villavallelonga', 'M031'],
    ['Villetta Barrea', 'M041'],
    ['Vittorito', 'M090'],
  ],
  TE: [
    ['Alba Adriatica', 'A125'],
    ['Ancarano', 'A270'],
    ['Arsita', 'A445'],
    ['Atri', 'A488'],
    ['Basciano', 'A692'],
    ['Bellante', 'A746'],
    ['Bisenti', 'A885'],
    ['Campli', 'B515'],
    ['Canzano', 'B640'],
    ['Castel Castagna', 'C040'],
    ['Castellalto', 'C128'],
    ['Castelli', 'C169'],
    ['Castiglione Messer Raimondo', 'C316'],
    ['Castilenti', 'C322'],
    ['Cellino Attanasio', 'C449'],
    ['Cermignano', 'C517'],
    ['Civitella del Tronto', 'C781'],
    ['Colledara', 'C311'],
    ['Colonnella', 'C901'],
    ['Controguerra', 'C972'],
    ['Corropoli', 'D043'],
    ['Cortino', 'D076'],
    ['Crognaleto', 'D179'],
    ['Fano Adriano', 'D489'],
    ['Giulianova', 'E058'],
    ["Isola del Gran Sasso d'Italia", 'E343'],
    ['Montefino', 'F500'],
    ['Montorio al Vomano', 'F690'],
    ["Morro d'Oro", 'F747'],
    ["Mosciano Sant'Angelo", 'F764'],
    ['Nereto', 'F870'],
    ['Notaresco', 'F942'],
    ["Penna Sant'Andrea", 'G437'],
    ['Pietracamela', 'G608'],
    ['Pineto', 'F831'],
    ['Rocca Santa Maria', 'H440'],
    ['Roseto degli Abruzzi', 'F585'],
    ["Sant'Egidio alla Vibrata", 'I318'],
    ["Sant'Omero", 'I348'],
    ['Silvi', 'I741'],
    ['Teramo', 'L103'],
    ['Torano Nuovo', 'L207'],
    ['Torricella Sicura', 'L295'],
    ['Tortoreto', 'L307'],
    ['Tossicia', 'L314'],
    ['Valle Castellana', 'L597'],
    ['Martinsicuro', 'E989'],
  ],
  PE: [
    ['Abbateggio', 'A008'],
    ['Alanno', 'A120'],
    ['Bolognano', 'A945'],
    ['Brittoli', 'B193'],
    ['Bussi sul Tirino', 'B294'],
    ['Cappelle sul Tavo', 'B681'],
    ['Caramanico Terme', 'B722'],
    ['Carpineto della Nora', 'B827'],
    ['Castiglione a Casauria', 'C308'],
    ['Catignano', 'C354'],
    ['Cepagatti', 'C474'],
    ["Città Sant'Angelo", 'C750'],
    ['Civitaquana', 'C771'],
    ['Civitella Casanova', 'C779'],
    ['Collecorvino', 'C853'],
    ['Corvara', 'D078'],
    ['Cugnoli', 'D201'],
    ['Elice', 'D394'],
    ['Farindola', 'D501'],
    ['Lettomanoppello', 'E558'],
    ['Loreto Aprutino', 'E691'],
    ['Manoppello', 'E892'],
    ['Montebello di Bertona', 'F441'],
    ['Montesilvano', 'F646'],
    ['Moscufo', 'F765'],
    ['Nocciano', 'F908'],
    ['Penne', 'G438'],
    ['Pescara', 'G482'],
    ['Pescosansonesco', 'G499'],
    ['Pianella', 'G555'],
    ['Picciano', 'G589'],
    ['Pietranico', 'G621'],
    ['Popoli', 'G878'],
    ['Roccamorice', 'H425'],
    ['Rosciano', 'H562'],
    ['Salle', 'H715'],
    ["Sant'Eufemia a Maiella", 'I332'],
    ['San Valentino in Abruzzo Citeriore', 'I376'],
    ['Scafa', 'I482'],
    ['Serramonacesca', 'I649'],
    ['Spoltore', 'I922'],
    ['Tocco da Casauria', 'L186'],
    ["Torre de' Passeri", 'L263'],
    ['Turrivalignani', 'L475'],
    ['Vicoli', 'L846'],
    ['Villa Celiera', 'L922'],
  ],
  CH: [
    ['Altino', 'A235'],
    ['Archi', 'A367'],
    ['Ari', 'A398'],
    ['Arielli', 'A402'],
    ['Atessa', 'A485'],
    ['Bomba', 'A956'],
    ['Borrello', 'B057'],
    ['Bucchianico', 'B238'],
    ['Montebello sul Sangro', 'B268'],
    ['Canosa Sannita', 'B620'],
    ['Carpineto Sinello', 'B826'],
    ['Carunchio', 'B853'],
    ['Casacanditella', 'B859'],
    ['Casalanguida', 'B861'],
    ['Casalbordino', 'B865'],
    ['Casalincontrada', 'B896'],
    ['Casoli', 'B985'],
    ['Castel Frentano', 'C114'],
    ['Castelguidone', 'C123'],
    ['Castiglione Messer Marino', 'C298'],
    ['Celenza sul Trigno', 'C428'],
    ['Chieti', 'C632'],
    ['Civitaluparella', 'C768'],
    ['Civitella Messer Raimondo', 'C776'],
    ['Colledimacine', 'C855'],
    ['Colledimezzo', 'C856'],
    ['Crecchio', 'D137'],
    ['Cupello', 'D209'],
    ['Dogliola', 'D315'],
    ['Fara Filiorum Petri', 'D494'],
    ['Fara San Martino', 'D495'],
    ['Filetto', 'D592'],
    ['Fossacesia', 'D738'],
    ['Fraine', 'D757'],
    ['Francavilla al Mare', 'D763'],
    ['Fresagrandinaria', 'D796'],
    ['Frisa', 'D803'],
    ['Furci', 'D823'],
    ['Gamberale', 'D898'],
    ['Gessopalena', 'D996'],
    ['Gissi', 'E052'],
    ['Giuliano Teatino', 'E056'],
    ['Guardiagrele', 'E243'],
    ['Guilmi', 'E266'],
    ['Lama dei Peligni', 'E424'],
    ['Lanciano', 'E435'],
    ['Lentella', 'E531'],
    ['Lettopalena', 'E559'],
    ['Liscia', 'E611'],
    ['Miglianico', 'F196'],
    ['Montazzoli', 'F433'],
    ['Monteferrante', 'F498'],
    ['Montelapiano', 'F535'],
    ['Montenerodomo', 'F578'],
    ['Monteodorisio', 'F582'],
    ['Mozzagrogna', 'F785'],
    ['Orsogna', 'G128'],
    ['Ortona', 'G141'],
    ['Paglieta', 'G237'],
    ['Palena', 'G271'],
    ['Palmoli', 'G290'],
    ['Palombaro', 'G294'],
    ['Pennadomo', 'G434'],
    ['Pennapiedimonte', 'G435'],
    ['Perano', 'G441'],
    ['Pizzoferrato', 'G724'],
    ['Poggiofiorito', 'G760'],
    ['Pollutri', 'G799'],
    ['Pretoro', 'H052'],
    ['Quadri', 'H098'],
    ['Rapino', 'H184'],
    ['Ripa Teatina', 'H320'],
    ['Roccamontepiano', 'H424'],
    ['Rocca San Giovanni', 'H439'],
    ['Roccascalegna', 'H442'],
    ['Roccaspinalveti', 'H448'],
    ['Roio del Sangro', 'H495'],
    ['Rosello', 'H566'],
    ['San Buono', 'H784'],
    ['San Giovanni Lipioni', 'H923'],
    ['San Giovanni Teatino', 'D690'],
    ['San Martino sulla Marrucina', 'H991'],
    ['San Salvo', 'I148'],
    ['Santa Maria Imbaro', 'I244'],
    ["Sant'Eusanio del Sangro", 'I335'],
    ['San Vito Chietino', 'I394'],
    ['Scerni', 'I520'],
    ['Schiavi di Abruzzo', 'I526'],
    ['Taranta Peligna', 'L047'],
    ['Tollo', 'L194'],
    ['Torino di Sangro', 'L218'],
    ['Tornareccio', 'L224'],
    ['Torrebruna', 'L253'],
    ['Torrevecchia Teatina', 'L284'],
    ['Torricella Peligna', 'L291'],
    ['Treglio', 'L363'],
    ['Tufillo', 'L459'],
    ['Vacri', 'L526'],
    ['Vasto', 'E372'],
    ['Villalfonsina', 'L961'],
    ['Villamagna', 'L964'],
    ['Villa Santa Maria', 'M022'],
    ['Pietraferrazzana', 'G613'],
    ['Fallo', 'D480'],
  ],
  CB: [
    ['Acquaviva Collecroce', 'A050'],
    ['Baranello', 'A616'],
    ['Bojano', 'A930'],
    ['Bonefro', 'A971'],
    ['Busso', 'B295'],
    ['Campobasso', 'B519'],
    ['Campochiaro', 'B522'],
    ['Campodipietra', 'B528'],
    ['Campolieto', 'B544'],
    ['Campomarino', 'B550'],
    ['Casacalenda', 'B858'],
    ['Casalciprano', 'B871'],
    ['Castelbottaccio', 'C066'],
    ['Castellino del Biferno', 'C175'],
    ['Castelmauro', 'C197'],
    ['Castropignano', 'C346'],
    ['Cercemaggiore', 'C486'],
    ['Cercepiccola', 'C488'],
    ['Civitacampomarano', 'C764'],
    ["Colle d'Anchise", 'C854'],
    ['Colletorto', 'C875'],
    ['Duronia', 'C772'],
    ['Ferrazzano', 'D550'],
    ['Fossalto', 'D737'],
    ['Gambatesa', 'D896'],
    ['Gildone', 'E030'],
    ['Guardialfiera', 'E244'],
    ['Guardiaregia', 'E248'],
    ['Guglionesi', 'E259'],
    ['Jelsi', 'E381'],
    ['Larino', 'E456'],
    ['Limosano', 'E599'],
    ['Lucito', 'E722'],
    ['Lupara', 'E748'],
    ['Macchia Valfortore', 'E780'],
    ['Mafalda', 'E799'],
    ['Matrice', 'F055'],
    ['Mirabello Sannitico', 'F233'],
    ['Molise', 'F294'],
    ['Monacilioni', 'F322'],
    ['Montagano', 'F391'],
    ['Montecilfone', 'F475'],
    ['Montefalcone nel Sannio', 'F495'],
    ['Montelongo', 'F548'],
    ['Montemitro', 'F569'],
    ['Montenero di Bisaccia', 'F576'],
    ['Montorio nei Frentani', 'F689'],
    ['Morrone del Sannio', 'F748'],
    ['Oratino', 'G086'],
    ['Palata', 'G257'],
    ['Petacciato', 'G506'],
    ['Petrella Tifernina', 'G512'],
    ['Pietracatella', 'G609'],
    ['Pietracupa', 'G610'],
    ['Portocannone', 'G910'],
    ['Provvidenti', 'H083'],
    ['Riccia', 'H273'],
    ['Ripabottoni', 'H311'],
    ['Ripalimosani', 'H313'],
    ['Roccavivara', 'H454'],
    ['Rotello', 'H589'],
    ['Salcito', 'H693'],
    ['San Biase', 'H782'],
    ['San Felice del Molise', 'H833'],
    ['San Giacomo degli Schiavoni', 'H867'],
    ['San Giovanni in Galdo', 'H920'],
    ['San Giuliano del Sannio', 'H928'],
    ['San Giuliano di Puglia', 'H929'],
    ['San Martino in Pensilis', 'H990'],
    ['San Massimo', 'I023'],
    ['San Polo Matese', 'I122'],
    ['Santa Croce di Magliano', 'I181'],
    ["Sant'Angelo Limosano", 'I289'],
    ["Sant'Elia a Pianisi", 'I320'],
    ['Sepino', 'I618'],
    ['Spinete', 'I910'],
    ['Tavenna', 'L069'],
    ['Termoli', 'L113'],
    ['Torella del Sannio', 'L215'],
    ['Toro', 'L230'],
    ['Trivento', 'L435'],
    ['Tufara', 'L458'],
    ['Ururi', 'L505'],
    ['Vinchiaturo', 'M057'],
  ],
  IS: [
    ["Acquaviva d'Isernia", 'A051'],
    ['Agnone', 'A080'],
    ['Bagnoli del Trigno', 'A567'],
    ['Belmonte del Sannio', 'A761'],
    ['Cantalupo nel Sannio', 'B630'],
    ['Capracotta', 'B682'],
    ['Carovilli', 'B810'],
    ['Carpinone', 'B830'],
    ['Castel del Giudice', 'C082'],
    ['Castelpetroso', 'C246'],
    ['Castelpizzuto', 'C247'],
    ['Castel San Vincenzo', 'C270'],
    ['Castelverrino', 'C200'],
    ['Cerro al Volturno', 'C534'],
    ['Chiauci', 'C620'],
    ['Civitanova del Sannio', 'C769'],
    ['Colli a Volturno', 'C878'],
    ['Conca Casale', 'C941'],
    ['Filignano', 'D595'],
    ['Forlì del Sannio', 'D703'],
    ['Fornelli', 'D715'],
    ['Frosolone', 'D811'],
    ['Isernia', 'E335'],
    ['Longano', 'E669'],
    ["Macchia d'Isernia", 'E778'],
    ['Macchiagodena', 'E779'],
    ['Miranda', 'F239'],
    ['Montaquila', 'F429'],
    ['Montenero Val Cocchiara', 'F580'],
    ['Monteroduni', 'F601'],
    ['Pesche', 'G486'],
    ['Pescolanciano', 'G495'],
    ['Pescopennataro', 'G497'],
    ['Pettoranello del Molise', 'G523'],
    ['Pietrabbondante', 'G606'],
    ['Pizzone', 'G727'],
    ['Poggio Sannita', 'B317'],
    ['Pozzilli', 'G954'],
    ['Rionero Sannitico', 'H308'],
    ['Roccamandolfi', 'H420'],
    ['Roccasicura', 'H445'],
    ['Rocchetta a Volturno', 'H458'],
    ['San Pietro Avellana', 'I096'],
    ["Sant'Agapito", 'I189'],
    ['Santa Maria del Molise', 'I238'],
    ["Sant'Angelo del Pesco", 'I282'],
    ["Sant'Elena Sannita", 'B466'],
    ['Scapoli', 'I507'],
    ['Sessano del Molise', 'I679'],
    ['Sesto Campano', 'I682'],
    ['Vastogirardi', 'L696'],
    ['Venafro', 'L725'],
  ],
  CE: [
    ['Ailano', 'A106'],
    ['Alife', 'A200'],
    ['Alvignano', 'A243'],
    ['Arienzo', 'A403'],
    ['Aversa', 'A512'],
    ['Baia e Latina', 'A579'],
    ['Bellona', 'A755'],
    ['Caianello', 'B361'],
    ['Caiazzo', 'B362'],
    ['Calvi Risorta', 'B445'],
    ['Camigliano', 'B477'],
    ['Cancello ed Arnone', 'B581'],
    ['Capodrise', 'B667'],
    ['Capriati a Volturno', 'B704'],
    ['Capua', 'B715'],
    ['Carinaro', 'B779'],
    ['Carinola', 'B781'],
    ['Casagiove', 'B860'],
    ['Casal di Principe', 'B872'],
    ['Casaluce', 'B916'],
    ['Casapulla', 'B935'],
    ['Caserta', 'B963'],
    ['Castel Campagnano', 'B494'],
    ['Castel di Sasso', 'C097'],
    ['Castello del Matese', 'C178'],
    ['Castel Morrone', 'C211'],
    ['Castel Volturno', 'C291'],
    ['Cervino', 'C558'],
    ['Cesa', 'C561'],
    ['Ciorlano', 'C716'],
    ['Conca della Campania', 'C939'],
    ['Curti', 'D228'],
    ['Dragoni', 'D361'],
    ['Fontegreca', 'D683'],
    ['Formicola', 'D709'],
    ['Francolise', 'D769'],
    ['Frignano', 'D799'],
    ['Gallo Matese', 'D884'],
    ['Galluccio', 'D886'],
    ['Giano Vetusto', 'E011'],
    ['Gioia Sannitica', 'E039'],
    ['Grazzanise', 'E158'],
    ['Gricignano di Aversa', 'E173'],
    ['Letino', 'E554'],
    ['Liberi', 'E570'],
    ['Lusciano', 'E754'],
    ['Macerata Campania', 'E784'],
    ['Maddaloni', 'E791'],
    ['Marcianise', 'E932'],
    ['Marzano Appio', 'E998'],
    ['Mignano Monte Lungo', 'F203'],
    ['Mondragone', 'F352'],
    ['Orta di Atella', 'G130'],
    ['Parete', 'G333'],
    ['Pastorano', 'G364'],
    ['Piana di Monte Verna', 'G541'],
    ['Piedimonte Matese', 'G596'],
    ['Pietramelara', 'G620'],
    ['Pietravairano', 'G630'],
    ['Pignataro Maggiore', 'G661'],
    ['Pontelatone', 'G849'],
    ['Portico di Caserta', 'G903'],
    ['Prata Sannita', 'G991'],
    ['Pratella', 'G995'],
    ['Presenzano', 'H045'],
    ['Raviscanina', 'H202'],
    ['Recale', 'H210'],
    ['Riardo', 'H268'],
    ["Rocca d'Evandro", 'H398'],
    ['Roccamonfina', 'H423'],
    ['Roccaromana', 'H436'],
    ['Rocchetta e Croce', 'H459'],
    ['Ruviano', 'H165'],
    ["San Cipriano d'Aversa", 'H798'],
    ['San Felice a Cancello', 'H834'],
    ['San Gregorio Matese', 'H939'],
    ['San Marcellino', 'H978'],
    ['San Nicola la Strada', 'I056'],
    ['San Pietro Infine', 'I113'],
    ['San Potito Sannitico', 'I130'],
    ['San Prisco', 'I131'],
    ['Santa Maria a Vico', 'I233'],
    ['Santa Maria Capua Vetere', 'I234'],
    ['Santa Maria la Fossa', 'I247'],
    ['San Tammaro', 'I261'],
    ["Sant'Angelo d'Alife", 'I273'],
    ["Sant'Arpino", 'I306'],
    ['Sessa Aurunca', 'I676'],
    ['Sparanise', 'I885'],
    ['Succivo', 'I993'],
    ['Teano', 'L083'],
    ['Teverola', 'L155'],
    ['Tora e Piccilli', 'L205'],
    ['Trentola Ducenta', 'L379'],
    ['Vairano Patenora', 'L540'],
    ['Valle Agricola', 'L594'],
    ['Valle di Maddaloni', 'L591'],
    ['Villa di Briano', 'D801'],
    ['Villa Literno', 'L844'],
    ['Vitulazio', 'M092'],
    ['Falciano del Massico', 'D471'],
    ['Cellole', 'M262'],
    ['Casapesenna', 'M260'],
    ['San Marco Evangelista', 'F043'],
  ],
  BN: [
    ['Airola', 'A110'],
    ['Amorosi', 'A265'],
    ['Apice', 'A328'],
    ['Apollosa', 'A330'],
    ['Arpaia', 'A431'],
    ['Arpaise', 'A432'],
    ['Baselice', 'A696'],
    ['Benevento', 'A783'],
    ['Bonea', 'A970'],
    ['Bucciano', 'B239'],
    ['Buonalbergo', 'B267'],
    ['Calvi', 'B444'],
    ['Campolattaro', 'B541'],
    ['Campoli del Monte Taburno', 'B542'],
    ['Casalduni', 'B873'],
    ['Castelfranco in Miscano', 'C106'],
    ['Castelpagano', 'C245'],
    ['Castelpoto', 'C250'],
    ['Castelvenere', 'C280'],
    ['Castelvetere in Val Fortore', 'C284'],
    ['Cautano', 'C359'],
    ['Ceppaloni', 'C476'],
    ['Cerreto Sannita', 'C525'],
    ['Circello', 'C719'],
    ['Colle Sannita', 'C846'],
    ['Cusano Mutri', 'D230'],
    ['Dugenta', 'D380'],
    ['Durazzano', 'D386'],
    ['Faicchio', 'D469'],
    ['Foglianise', 'D644'],
    ['Foiano di Val Fortore', 'D650'],
    ['Forchia', 'D693'],
    ["Fragneto l'Abate", 'D755'],
    ['Fragneto Monforte', 'D756'],
    ['Frasso Telesino', 'D784'],
    ['Ginestra degli Schiavoni', 'E034'],
    ['Guardia Sanframondi', 'E249'],
    ['Limatola', 'E589'],
    ['Melizzano', 'F113'],
    ['Moiano', 'F274'],
    ['Molinara', 'F287'],
    ['Montefalcone di Val Fortore', 'F494'],
    ['Montesarchio', 'F636'],
    ['Morcone', 'F717'],
    ['Paduli', 'G227'],
    ['Pago Veiano', 'G243'],
    ['Pannarano', 'G311'],
    ['Paolisi', 'G318'],
    ['Paupisi', 'G386'],
    ['Pesco Sannita', 'G494'],
    ['Pietraroja', 'G626'],
    ['Pietrelcina', 'G631'],
    ['Ponte', 'G827'],
    ['Pontelandolfo', 'G848'],
    ['Puglianello', 'H087'],
    ['Reino', 'H227'],
    ['San Bartolomeo in Galdo', 'H764'],
    ['San Giorgio del Sannio', 'H894'],
    ['San Giorgio La Molara', 'H898'],
    ['San Leucio del Sannio', 'H953'],
    ['San Lorenzello', 'H955'],
    ['San Lorenzo Maggiore', 'H967'],
    ['San Lupo', 'H973'],
    ['San Marco dei Cavoti', 'H984'],
    ['San Martino Sannita', 'I002'],
    ['San Nazzaro', 'I049'],
    ['San Nicola Manfredi', 'I062'],
    ['San Salvatore Telesino', 'I145'],
    ['Santa Croce del Sannio', 'I179'],
    ["Sant'Agata de' Goti", 'I197'],
    ["Sant'Angelo a Cupolo", 'I277'],
    ['Sassinoro', 'I455'],
    ['Solopaca', 'I809'],
    ['Telese Terme', 'L086'],
    ['Tocco Caudio', 'L185'],
    ['Torrecuso', 'L254'],
    ['Vitulano', 'M093'],
    ["Sant'Arcangelo Trimonte", 'F557'],
  ],
  NA: [
    ['Acerra', 'A024'],
    ['Afragola', 'A064'],
    ['Agerola', 'A068'],
    ['Anacapri', 'A268'],
    ['Arzano', 'A455'],
    ['Bacoli', 'A535'],
    ["Barano d'Ischia", 'A617'],
    ['Boscoreale', 'B076'],
    ['Boscotrecase', 'B077'],
    ['Brusciano', 'B227'],
    ['Caivano', 'B371'],
    ['Calvizzano', 'B452'],
    ['Camposano', 'B565'],
    ['Capri', 'B696'],
    ['Carbonara di Nola', 'B740'],
    ['Cardito', 'B759'],
    ['Casalnuovo di Napoli', 'B905'],
    ['Casamarciano', 'B922'],
    ['Casamicciola Terme', 'B924'],
    ['Casandrino', 'B925'],
    ['Casavatore', 'B946'],
    ['Casola di Napoli', 'B980'],
    ['Casoria', 'B990'],
    ['Castellammare di Stabia', 'C129'],
    ['Castello di Cisterna', 'C188'],
    ['Cercola', 'C495'],
    ['Cicciano', 'C675'],
    ['Cimitile', 'C697'],
    ['Comiziano', 'C929'],
    ['Crispano', 'D170'],
    ['Forio', 'D702'],
    ['Frattamaggiore', 'D789'],
    ['Frattaminore', 'D790'],
    ['Giugliano in Campania', 'E054'],
    ['Gragnano', 'E131'],
    ['Grumo Nevano', 'E224'],
    ['Ischia', 'E329'],
    ['Lacco Ameno', 'E396'],
    ['Lettere', 'E557'],
    ['Liveri', 'E620'],
    ['Marano di Napoli', 'E906'],
    ['Mariglianella', 'E954'],
    ['Marigliano', 'E955'],
    ['Massa Lubrense', 'F030'],
    ['Melito di Napoli', 'F111'],
    ['Meta', 'F162'],
    ['Monte di Procida', 'F488'],
    ['Mugnano di Napoli', 'F799'],
    ['Napoli', 'F839'],
    ['Nola', 'F924'],
    ['Ottaviano', 'G190'],
    ['Palma Campania', 'G283'],
    ['Piano di Sorrento', 'G568'],
    ['Pimonte', 'G670'],
    ['Poggiomarino', 'G762'],
    ['Pollena Trocchia', 'G795'],
    ["Pomigliano d'Arco", 'G812'],
    ['Pompei', 'G813'],
    ['Portici', 'G902'],
    ['Pozzuoli', 'G964'],
    ['Procida', 'H072'],
    ['Qualiano', 'H101'],
    ['Quarto', 'H114'],
    ['Ercolano', 'H243'],
    ['Roccarainola', 'H433'],
    ['San Gennaro Vesuviano', 'H860'],
    ['San Giorgio a Cremano', 'H892'],
    ['San Giuseppe Vesuviano', 'H931'],
    ['San Paolo Bel Sito', 'I073'],
    ['San Sebastiano al Vesuvio', 'I151'],
    ["Sant'Agnello", 'I208'],
    ["Sant'Anastasia", 'I262'],
    ["Sant'Antimo", 'I293'],
    ["Sant'Antonio Abate", 'I300'],
    ['San Vitaliano', 'I391'],
    ['Saviano', 'I469'],
    ['Scisciano', 'I540'],
    ['Serrara Fontana', 'I652'],
    ['Somma Vesuviana', 'I820'],
    ['Sorrento', 'I862'],
    ['Striano', 'I978'],
    ['Terzigno', 'L142'],
    ['Torre Annunziata', 'L245'],
    ['Torre del Greco', 'L259'],
    ['Tufino', 'L460'],
    ['Vico Equense', 'L845'],
    ['Villaricca', 'G309'],
    ['Visciano', 'M072'],
    ['Volla', 'M115'],
    ['Santa Maria la Carità', 'M273'],
    ['Trecase', 'M280'],
    ['Massa di Somma', 'M289'],
  ],
  AV: [
    ['Aiello del Sabato', 'A101'],
    ['Altavilla Irpina', 'A228'],
    ['Andretta', 'A284'],
    ['Aquilonia', 'A347'],
    ['Ariano Irpino', 'A399'],
    ['Atripalda', 'A489'],
    ['Avella', 'A508'],
    ['Avellino', 'A509'],
    ['Bagnoli Irpino', 'A566'],
    ['Baiano', 'A580'],
    ['Bisaccia', 'A881'],
    ['Bonito', 'A975'],
    ['Cairano', 'B367'],
    ['Calabritto', 'B374'],
    ['Calitri', 'B415'],
    ['Candida', 'B590'],
    ['Caposele', 'B674'],
    ['Capriglia Irpina', 'B706'],
    ['Carife', 'B776'],
    ['Casalbore', 'B866'],
    ['Cassano Irpino', 'B997'],
    ['Castel Baronia', 'C058'],
    ['Castelfranci', 'C105'],
    ['Castelvetere sul Calore', 'C283'],
    ['Cervinara', 'C557'],
    ['Cesinali', 'C576'],
    ['Chianche', 'C606'],
    ['Chiusano di San Domenico', 'C659'],
    ['Contrada', 'C971'],
    ['Conza della Campania', 'C976'],
    ['Domicella', 'D331'],
    ['Flumeri', 'D638'],
    ['Fontanarosa', 'D671'],
    ['Forino', 'D701'],
    ['Frigento', 'D798'],
    ['Gesualdo', 'D998'],
    ['Greci', 'E161'],
    ['Grottaminarda', 'E206'],
    ['Grottolella', 'E214'],
    ['Guardia Lombardi', 'E245'],
    ['Lacedonia', 'E397'],
    ['Lapio', 'E448'],
    ['Lauro', 'E487'],
    ['Lioni', 'E605'],
    ['Luogosano', 'E746'],
    ['Manocalzati', 'E891'],
    ['Marzano di Nola', 'E997'],
    ['Melito Irpino', 'F110'],
    ['Mercogliano', 'F141'],
    ['Mirabella Eclano', 'F230'],
    ['Montaguto', 'F397'],
    ['Montecalvo Irpino', 'F448'],
    ['Montefalcione', 'F491'],
    ['Monteforte Irpino', 'F506'],
    ['Montefredane', 'F511'],
    ['Montefusco', 'F512'],
    ['Montella', 'F546'],
    ['Montemarano', 'F559'],
    ['Montemiletto', 'F566'],
    ['Monteverde', 'F660'],
    ['Morra De Sanctis', 'F744'],
    ['Moschiano', 'F762'],
    ['Mugnano del Cardinale', 'F798'],
    ['Nusco', 'F988'],
    ["Ospedaletto d'Alpinolo", 'G165'],
    ['Pago del Vallo di Lauro', 'G242'],
    ['Parolise', 'G340'],
    ['Paternopoli', 'G370'],
    ['Petruro Irpino', 'G519'],
    ['Pietradefusi', 'G611'],
    ['Pietrastornina', 'G629'],
    ['Prata di Principato Ultra', 'G990'],
    ['Pratola Serra', 'H006'],
    ['Quadrelle', 'H097'],
    ['Quindici', 'H128'],
    ['Roccabascerana', 'H382'],
    ['Rocca San Felice', 'H438'],
    ['Rotondi', 'H592'],
    ['Salza Irpina', 'H733'],
    ['San Mango sul Calore', 'H975'],
    ['San Martino Valle Caudina', 'I016'],
    ['San Michele di Serino', 'I034'],
    ['San Nicola Baronia', 'I061'],
    ['San Potito Ultra', 'I129'],
    ['San Sossio Baronia', 'I163'],
    ['Santa Lucia di Serino', 'I219'],
    ["Sant'Andrea di Conza", 'I264'],
    ["Sant'Angelo all'Esca", 'I279'],
    ["Sant'Angelo a Scala", 'I280'],
    ["Sant'Angelo dei Lombardi", 'I281'],
    ['Santa Paolina', 'I301'],
    ['Santo Stefano del Sole', 'I357'],
    ['Savignano Irpino', 'I471'],
    ['Scampitella', 'I493'],
    ['Senerchia', 'I606'],
    ['Serino', 'I630'],
    ['Sirignano', 'I756'],
    ['Solofra', 'I805'],
    ['Sorbo Serpico', 'I843'],
    ['Sperone', 'I893'],
    ['Sturno', 'I990'],
    ['Summonte', 'L004'],
    ['Taurano', 'L061'],
    ['Taurasi', 'L062'],
    ['Teora', 'L102'],
    ['Torella dei Lombardi', 'L214'],
    ['Torre Le Nocelle', 'L272'],
    ['Torrioni', 'L301'],
    ['Trevico', 'L399'],
    ['Tufo', 'L461'],
    ['Vallata', 'L589'],
    ['Vallesaccarda', 'L616'],
    ['Venticano', 'L739'],
    ['Villamaina', 'L965'],
    ['Villanova del Battista', 'L973'],
    ['Volturara Irpina', 'M130'],
    ['Zungoli', 'M203'],
    ['Montoro', 'M330'],
  ],
  SA: [
    ['Acerno', 'A023'],
    ['Agropoli', 'A091'],
    ['Albanella', 'A128'],
    ['Alfano', 'A186'],
    ['Altavilla Silentina', 'A230'],
    ['Amalfi', 'A251'],
    ['Angri', 'A294'],
    ['Aquara', 'A343'],
    ['Ascea', 'A460'],
    ['Atena Lucana', 'A484'],
    ['Atrani', 'A487'],
    ['Auletta', 'A495'],
    ['Baronissi', 'A674'],
    ['Battipaglia', 'A717'],
    ['Bellosguardo', 'A756'],
    ['Bracigliano', 'B115'],
    ['Buccino', 'B242'],
    ['Buonabitacolo', 'B266'],
    ['Caggiano', 'B351'],
    ['Calvanico', 'B437'],
    ['Camerota', 'B476'],
    ['Campagna', 'B492'],
    ['Campora', 'B555'],
    ['Cannalonga', 'B608'],
    ['Capaccio Paestum', 'B644'],
    ['Casalbuono', 'B868'],
    ['Casaletto Spartano', 'B888'],
    ['Casal Velino', 'B895'],
    ['Caselle in Pittari', 'B959'],
    ['Castelcivita', 'C069'],
    ['Castellabate', 'C125'],
    ['Castelnuovo Cilento', 'C231'],
    ['Castelnuovo di Conza', 'C235'],
    ['Castel San Giorgio', 'C259'],
    ['Castel San Lorenzo', 'C262'],
    ['Castiglione del Genovesi', 'C306'],
    ["Cava de' Tirreni", 'C361'],
    ['Celle di Bulgheria', 'C444'],
    ['Centola', 'C470'],
    ['Ceraso', 'C485'],
    ['Cetara', 'C584'],
    ['Cicerale', 'C676'],
    ['Colliano', 'C879'],
    ['Conca dei Marini', 'C940'],
    ['Controne', 'C973'],
    ['Contursi Terme', 'C974'],
    ['Corbara', 'C984'],
    ['Corleto Monforte', 'D011'],
    ['Cuccaro Vetere', 'D195'],
    ['Eboli', 'D390'],
    ['Felitto', 'D527'],
    ['Fisciano', 'D615'],
    ['Furore', 'D826'],
    ['Futani', 'D832'],
    ['Giffoni Sei Casali', 'E026'],
    ['Giffoni Valle Piana', 'E027'],
    ['Gioi', 'E037'],
    ['Giungano', 'E060'],
    ['Ispani', 'E365'],
    ['Laureana Cilento', 'E480'],
    ['Laurino', 'E485'],
    ['Laurito', 'E486'],
    ['Laviano', 'E498'],
    ['Lustra', 'E767'],
    ['Magliano Vetere', 'E814'],
    ['Maiori', 'E839'],
    ['Mercato San Severino', 'F138'],
    ['Minori', 'F223'],
    ['Moio della Civitella', 'F278'],
    ['Montano Antilia', 'F426'],
    ['Montecorice', 'F479'],
    ['Montecorvino Pugliano', 'F480'],
    ['Montecorvino Rovella', 'F481'],
    ['Monteforte Cilento', 'F507'],
    ['Monte San Giacomo', 'F618'],
    ['Montesano sulla Marcellana', 'F625'],
    ['Morigerati', 'F731'],
    ['Nocera Inferiore', 'F912'],
    ['Nocera Superiore', 'F913'],
    ['Novi Velia', 'F967'],
    ['Ogliastro Cilento', 'G011'],
    ['Olevano sul Tusciano', 'G023'],
    ['Oliveto Citra', 'G039'],
    ['Omignano', 'G063'],
    ['Orria', 'G121'],
    ['Ottati', 'G192'],
    ['Padula', 'G226'],
    ['Pagani', 'G230'],
    ['Palomonte', 'G292'],
    ['Pellezzano', 'G426'],
    ['Perdifumo', 'G447'],
    ['Perito', 'G455'],
    ['Pertosa', 'G476'],
    ['Petina', 'G509'],
    ['Piaggine', 'G538'],
    ['Pisciotta', 'G707'],
    ['Polla', 'G793'],
    ['Pollica', 'G796'],
    ['Pontecagnano Faiano', 'G834'],
    ['Positano', 'G932'],
    ['Postiglione', 'G939'],
    ['Praiano', 'G976'],
    ['Prignano Cilento', 'H062'],
    ['Ravello', 'H198'],
    ['Ricigliano', 'H277'],
    ['Roccadaspide', 'H394'],
    ['Roccagloriosa', 'H412'],
    ['Roccapiemonte', 'H431'],
    ['Rofrano', 'H485'],
    ['Romagnano al Monte', 'H503'],
    ['Roscigno', 'H564'],
    ['Rutino', 'H644'],
    ['Sacco', 'H654'],
    ['Sala Consilina', 'H683'],
    ['Salento', 'H686'],
    ['Salerno', 'H703'],
    ['Salvitelle', 'H732'],
    ['San Cipriano Picentino', 'H800'],
    ['San Giovanni a Piro', 'H907'],
    ['San Gregorio Magno', 'H943'],
    ['San Mango Piemonte', 'H977'],
    ['San Marzano sul Sarno', 'I019'],
    ['San Mauro Cilento', 'I031'],
    ['San Mauro la Bruca', 'I032'],
    ['San Pietro al Tanagro', 'I089'],
    ['San Rufo', 'I143'],
    ['Santa Marina', 'I253'],
    ["Sant'Angelo a Fasanella", 'I278'],
    ["Sant'Arsenio", 'I307'],
    ["Sant'Egidio del Monte Albino", 'I317'],
    ['Santomenna', 'I260'],
    ['San Valentino Torio', 'I377'],
    ['Sanza', 'I410'],
    ['Sapri', 'I422'],
    ['Sarno', 'I438'],
    ['Sassano', 'I451'],
    ['Scafati', 'I483'],
    ['Scala', 'I486'],
    ['Serramezzana', 'I648'],
    ['Serre', 'I666'],
    ['Sessa Cilento', 'I677'],
    ['Siano', 'I720'],
    ['Sicignano degli Alburni', 'M253'],
    ['Stella Cilento', 'G887'],
    ['Stio', 'I960'],
    ['Teggiano', 'D292'],
    ['Torchiara', 'L212'],
    ['Torraca', 'L233'],
    ['Torre Orsaia', 'L274'],
    ['Tortorella', 'L306'],
    ['Tramonti', 'L323'],
    ['Trentinara', 'L377'],
    ["Valle dell'Angelo", 'G540'],
    ['Vallo della Lucania', 'L628'],
    ['Valva', 'L656'],
    ['Vibonati', 'L835'],
    ['Vietri sul Mare', 'L860'],
    ['Bellizzi', 'M294'],
  ],
  FG: [
    ['Accadia', 'A015'],
    ['Alberona', 'A150'],
    ['Anzano di Puglia', 'A320'],
    ['Apricena', 'A339'],
    ['Ascoli Satriano', 'A463'],
    ['Biccari', 'A854'],
    ['Bovino', 'B104'],
    ['Cagnano Varano', 'B357'],
    ['Candela', 'B584'],
    ['Carapelle', 'B724'],
    ['Carlantino', 'B784'],
    ['Carpino', 'B829'],
    ['Casalnuovo Monterotaro', 'B904'],
    ['Casalvecchio di Puglia', 'B917'],
    ['Castelluccio dei Sauri', 'C198'],
    ['Castelluccio Valmaggiore', 'C202'],
    ['Castelnuovo della Daunia', 'C222'],
    ['Celenza Valfortore', 'C429'],
    ['Celle di San Vito', 'C442'],
    ['Cerignola', 'C514'],
    ['Chieuti', 'C633'],
    ['Deliceto', 'D269'],
    ['Faeto', 'D459'],
    ['Foggia', 'D643'],
    ['Ischitella', 'E332'],
    ['Isole Tremiti', 'E363'],
    ['Lesina', 'E549'],
    ['Lucera', 'E716'],
    ['Manfredonia', 'E885'],
    ['Mattinata', 'F059'],
    ['Monteleone di Puglia', 'F538'],
    ["Monte Sant'Angelo", 'F631'],
    ['Motta Montecorvino', 'F777'],
    ['Orsara di Puglia', 'G125'],
    ['Orta Nova', 'G131'],
    ['Panni', 'G312'],
    ['Peschici', 'G487'],
    ['Pietramontecorvino', 'G604'],
    ['Poggio Imperiale', 'G761'],
    ['Rignano Garganico', 'H287'],
    ["Rocchetta Sant'Antonio", 'H467'],
    ['Rodi Garganico', 'H480'],
    ['Roseto Valfortore', 'H568'],
    ['San Giovanni Rotondo', 'H926'],
    ['San Marco in Lamis', 'H985'],
    ['San Marco la Catola', 'H986'],
    ['San Nicandro Garganico', 'I054'],
    ['San Paolo di Civitate', 'I072'],
    ['San Severo', 'I158'],
    ["Sant'Agata di Puglia", 'I193'],
    ['Serracapriola', 'I641'],
    ['Stornara', 'I962'],
    ['Stornarella', 'I963'],
    ['Torremaggiore', 'L273'],
    ['Troia', 'L447'],
    ['Vico del Gargano', 'L842'],
    ['Vieste', 'L858'],
    ['Volturara Appula', 'M131'],
    ['Volturino', 'M132'],
    ['Ordona', 'M266'],
    ['Zapponeta', 'M267'],
  ],
  BA: [
    ['Acquaviva delle Fonti', 'A048'],
    ['Adelfia', 'A055'],
    ['Alberobello', 'A149'],
    ['Altamura', 'A225'],
    ['Bari', 'A662'],
    ['Binetto', 'A874'],
    ['Bitetto', 'A892'],
    ['Bitonto', 'A893'],
    ['Bitritto', 'A894'],
    ['Capurso', 'B716'],
    ['Casamassima', 'B923'],
    ['Cassano delle Murge', 'B998'],
    ['Castellana Grotte', 'C134'],
    ['Cellamare', 'C436'],
    ['Conversano', 'C975'],
    ['Corato', 'C983'],
    ['Gioia del Colle', 'E038'],
    ['Giovinazzo', 'E047'],
    ['Gravina in Puglia', 'E155'],
    ['Grumo Appula', 'E223'],
    ['Locorotondo', 'E645'],
    ['Modugno', 'F262'],
    ['Mola di Bari', 'F280'],
    ['Molfetta', 'F284'],
    ['Monopoli', 'F376'],
    ['Noci', 'F915'],
    ['Noicattaro', 'F923'],
    ['Palo del Colle', 'G291'],
    ['Poggiorsini', 'G769'],
    ['Polignano a Mare', 'G787'],
    ['Putignano', 'H096'],
    ['Rutigliano', 'H643'],
    ['Ruvo di Puglia', 'H645'],
    ['Sammichele di Bari', 'H749'],
    ['Sannicandro di Bari', 'I053'],
    ['Santeramo in Colle', 'I330'],
    ['Terlizzi', 'L109'],
    ['Toritto', 'L220'],
    ['Triggiano', 'L425'],
    ['Turi', 'L472'],
    ['Valenzano', 'L571'],
  ],
  TA: [
    ['Avetrana', 'A514'],
    ['Carosino', 'B808'],
    ['Castellaneta', 'C136'],
    ['Crispiano', 'D171'],
    ['Faggiano', 'D463'],
    ['Fragagnano', 'D754'],
    ['Ginosa', 'E036'],
    ['Grottaglie', 'E205'],
    ['Laterza', 'E469'],
    ['Leporano', 'E537'],
    ['Lizzano', 'E630'],
    ['Manduria', 'E882'],
    ['Martina Franca', 'E986'],
    ['Maruggio', 'E995'],
    ['Massafra', 'F027'],
    ['Monteiasi', 'F531'],
    ['Montemesola', 'F563'],
    ['Monteparano', 'F587'],
    ['Mottola', 'F784'],
    ['Palagianello', 'G251'],
    ['Palagiano', 'G252'],
    ['Pulsano', 'H090'],
    ['Roccaforzata', 'H409'],
    ['San Giorgio Ionico', 'H882'],
    ['San Marzano di San Giuseppe', 'I018'],
    ['Sava', 'I467'],
    ['Taranto', 'L049'],
    ['Torricella', 'L294'],
    ['Statte', 'M298'],
  ],
  BR: [
    ['Brindisi', 'B180'],
    ['Carovigno', 'B809'],
    ['Ceglie Messapica', 'C424'],
    ['Cellino San Marco', 'C448'],
    ['Cisternino', 'C741'],
    ['Erchie', 'D422'],
    ['Fasano', 'D508'],
    ['Francavilla Fontana', 'D761'],
    ['Latiano', 'E471'],
    ['Mesagne', 'F152'],
    ['Oria', 'G098'],
    ['Ostuni', 'G187'],
    ['San Donaci', 'H822'],
    ['San Michele Salentino', 'I045'],
    ['San Pancrazio Salentino', 'I066'],
    ['San Pietro Vernotico', 'I119'],
    ['San Vito dei Normanni', 'I396'],
    ['Torchiarolo', 'L213'],
    ['Torre Santa Susanna', 'L280'],
    ['Villa Castelli', 'L920'],
  ],
  LE: [
    ['Alessano', 'A184'],
    ['Alezio', 'A185'],
    ['Alliste', 'A208'],
    ['Andrano', 'A281'],
    ['Aradeo', 'A350'],
    ['Arnesano', 'A425'],
    ['Bagnolo del Salento', 'A572'],
    ['Botrugno', 'B086'],
    ['Calimera', 'B413'],
    ['Campi Salentina', 'B506'],
    ['Cannole', 'B616'],
    ['Caprarica di Lecce', 'B690'],
    ['Carmiano', 'B792'],
    ['Carpignano Salentino', 'B822'],
    ['Casarano', 'B936'],
    ['Castri di Lecce', 'C334'],
    ["Castrignano de' Greci", 'C335'],
    ['Castrignano del Capo', 'C336'],
    ['Cavallino', 'C377'],
    ['Collepasso', 'C865'],
    ['Copertino', 'C978'],
    ["Corigliano d'Otranto", 'D006'],
    ['Corsano', 'D044'],
    ['Cursi', 'D223'],
    ['Cutrofiano', 'D237'],
    ['Diso', 'D305'],
    ['Gagliano del Capo', 'D851'],
    ['Galatina', 'D862'],
    ['Galatone', 'D863'],
    ['Gallipoli', 'D883'],
    ['Giuggianello', 'E053'],
    ['Giurdignano', 'E061'],
    ['Guagnano', 'E227'],
    ['Lecce', 'E506'],
    ['Lequile', 'E538'],
    ['Leverano', 'E563'],
    ['Lizzanello', 'E629'],
    ['Maglie', 'E815'],
    ['Martano', 'E979'],
    ['Martignano', 'E984'],
    ['Matino', 'F054'],
    ['Melendugno', 'F101'],
    ['Melissano', 'F109'],
    ['Melpignano', 'F117'],
    ['Miggiano', 'F194'],
    ['Minervino di Lecce', 'F221'],
    ['Monteroni di Lecce', 'F604'],
    ['Montesano Salentino', 'F623'],
    ['Morciano di Leuca', 'F716'],
    ['Muro Leccese', 'F816'],
    ['Nardò', 'F842'],
    ['Neviano', 'F881'],
    ['Nociglia', 'F916'],
    ['Novoli', 'F970'],
    ['Ortelle', 'G136'],
    ['Otranto', 'G188'],
    ['Palmariggi', 'G285'],
    ['Parabita', 'G325'],
    ['Patù', 'G378'],
    ['Poggiardo', 'G751'],
    ['Racale', 'H147'],
    ['Ruffano', 'H632'],
    ['Salice Salentino', 'H708'],
    ['Salve', 'H729'],
    ['Sanarica', 'H757'],
    ['San Cesario di Lecce', 'H793'],
    ['San Donato di Lecce', 'H826'],
    ['Sannicola', 'I059'],
    ['San Pietro in Lama', 'I115'],
    ['Santa Cesarea Terme', 'I172'],
    ['Scorrano', 'I549'],
    ['Seclì', 'I559'],
    ['Sogliano Cavour', 'I780'],
    ['Soleto', 'I800'],
    ['Specchia', 'I887'],
    ['Spongano', 'I923'],
    ['Squinzano', 'I930'],
    ['Sternatia', 'I950'],
    ['Supersano', 'L008'],
    ['Surano', 'L010'],
    ['Surbo', 'L011'],
    ['Taurisano', 'L064'],
    ['Taviano', 'L074'],
    ['Tiggiano', 'L166'],
    ['Trepuzzi', 'L383'],
    ['Tricase', 'L419'],
    ['Tuglie', 'L462'],
    ['Ugento', 'L484'],
    ['Uggiano la Chiesa', 'L485'],
    ['Veglie', 'L711'],
    ['Vernole', 'L776'],
    ['Zollino', 'M187'],
    ['San Cassiano', 'M264'],
    ['Castro', 'M261'],
    ['Porto Cesareo', 'M263'],
    ['Presicce-Acquarica', 'M428'],
  ],
  BT: [
    ['Andria', 'A285'],
    ['Barletta', 'A669'],
    ['Bisceglie', 'A883'],
    ['Canosa di Puglia', 'B619'],
    ['Margherita di Savoia', 'E946'],
    ['Minervino Murge', 'F220'],
    ['San Ferdinando di Puglia', 'H839'],
    ['Spinazzola', 'I907'],
    ['Trani', 'L328'],
    ['Trinitapoli', 'B915'],
  ],
  PZ: [
    ['Abriola', 'A013'],
    ['Acerenza', 'A020'],
    ['Albano di Lucania', 'A131'],
    ['Anzi', 'A321'],
    ['Armento', 'A415'],
    ['Atella', 'A482'],
    ['Avigliano', 'A519'],
    ['Balvano', 'A604'],
    ['Banzi', 'A612'],
    ['Baragiano', 'A615'],
    ['Barile', 'A666'],
    ['Bella', 'A743'],
    ['Brienza', 'B173'],
    ['Brindisi Montagna', 'B181'],
    ['Calvello', 'B440'],
    ['Calvera', 'B443'],
    ['Campomaggiore', 'B549'],
    ['Cancellara', 'B580'],
    ['Carbone', 'B743'],
    ['San Paolo Albanese', 'B906'],
    ['Castelgrande', 'C120'],
    ['Castelluccio Inferiore', 'C199'],
    ['Castelluccio Superiore', 'C201'],
    ['Castelmezzano', 'C209'],
    ['Castelsaraceno', 'C271'],
    ["Castronuovo di Sant'Andrea", 'C345'],
    ['Cersosimo', 'C539'],
    ['Chiaromonte', 'C619'],
    ['Corleto Perticara', 'D010'],
    ['Episcopia', 'D414'],
    ['Fardella', 'D497'],
    ['Filiano', 'D593'],
    ['Forenza', 'D696'],
    ['Francavilla in Sinni', 'D766'],
    ['Gallicchio', 'D876'],
    ['Genzano di Lucania', 'D971'],
    ['Grumento Nova', 'E221'],
    ['Guardia Perticara', 'E246'],
    ['Lagonegro', 'E409'],
    ['Latronico', 'E474'],
    ['Laurenzana', 'E482'],
    ['Lauria', 'E483'],
    ['Lavello', 'E493'],
    ['Maratea', 'E919'],
    ['Marsico Nuovo', 'E976'],
    ['Marsicovetere', 'E977'],
    ['Maschito', 'F006'],
    ['Melfi', 'F104'],
    ['Missanello', 'F249'],
    ['Moliterno', 'F295'],
    ['Montemilone', 'F568'],
    ['Montemurro', 'F573'],
    ['Muro Lucano', 'F817'],
    ['Nemoli', 'F866'],
    ['Noepoli', 'F917'],
    ['Oppido Lucano', 'G081'],
    ['Palazzo San Gervasio', 'G261'],
    ['Pescopagano', 'G496'],
    ['Picerno', 'G590'],
    ['Pietragalla', 'G616'],
    ['Pietrapertosa', 'G623'],
    ['Pignola', 'G663'],
    ['Potenza', 'G942'],
    ['Rapolla', 'H186'],
    ['Rapone', 'H187'],
    ['Rionero in Vulture', 'H307'],
    ['Ripacandida', 'H312'],
    ['Rivello', 'H348'],
    ['Roccanova', 'H426'],
    ['Rotonda', 'H590'],
    ['Ruoti', 'H641'],
    ['Ruvo del Monte', 'H646'],
    ['San Chirico Nuovo', 'H795'],
    ['San Chirico Raparo', 'H796'],
    ['San Costantino Albanese', 'H808'],
    ['San Fele', 'H831'],
    ["San Martino d'Agri", 'H994'],
    ['San Severino Lucano', 'I157'],
    ["Sant'Angelo Le Fratte", 'I288'],
    ["Sant'Arcangelo", 'I305'],
    ['Sarconi', 'I426'],
    ['Sasso di Castalda', 'I457'],
    ['Satriano di Lucania', 'G614'],
    ['Savoia di Lucania', 'H730'],
    ['Senise', 'I610'],
    ['Spinoso', 'I917'],
    ['Teana', 'L082'],
    ['Terranova di Pollino', 'L126'],
    ['Tito', 'L181'],
    ['Tolve', 'L197'],
    ['Tramutola', 'L326'],
    ['Trecchina', 'L357'],
    ['Trivigno', 'L439'],
    ['Vaglio Basilicata', 'L532'],
    ['Venosa', 'L738'],
    ['Vietri di Potenza', 'L859'],
    ['Viggianello', 'L873'],
    ['Viggiano', 'L874'],
    ['Ginestra', 'E033'],
    ['Paterno', 'M269'],
  ],
  MT: [
    ['Accettura', 'A017'],
    ['Aliano', 'A196'],
    ['Bernalda', 'A801'],
    ['Calciano', 'B391'],
    ['Cirigliano', 'C723'],
    ['Colobraro', 'C888'],
    ['Craco', 'D128'],
    ['Ferrandina', 'D547'],
    ['Garaguso', 'D909'],
    ['Gorgoglione', 'E093'],
    ['Grassano', 'E147'],
    ['Grottole', 'E213'],
    ['Irsina', 'E326'],
    ['Matera', 'F052'],
    ['Miglionico', 'F201'],
    ['Montalbano Jonico', 'F399'],
    ['Montescaglioso', 'F637'],
    ['Nova Siri', 'A942'],
    ['Oliveto Lucano', 'G037'],
    ['Pisticci', 'G712'],
    ['Policoro', 'G786'],
    ['Pomarico', 'G806'],
    ['Rotondella', 'H591'],
    ['Salandra', 'H687'],
    ['San Giorgio Lucano', 'H888'],
    ['San Mauro Forte', 'I029'],
    ['Stigliano', 'I954'],
    ['Tricarico', 'L418'],
    ['Tursi', 'L477'],
    ['Valsinni', 'D513'],
    ['Scanzano Jonico', 'M256'],
  ],
  CS: [
    ['Acquaformosa', 'A033'],
    ['Acquappesa', 'A041'],
    ['Acri', 'A053'],
    ['Aiello Calabro', 'A102'],
    ['Aieta', 'A105'],
    ['Albidona', 'A160'],
    ['Alessandria del Carretto', 'A183'],
    ['Altilia', 'A234'],
    ['Altomonte', 'A240'],
    ['Amantea', 'A253'],
    ['Amendolara', 'A263'],
    ['Aprigliano', 'A340'],
    ['Belmonte Calabro', 'A762'],
    ['Belsito', 'A768'],
    ['Belvedere Marittimo', 'A773'],
    ['Bianchi', 'A842'],
    ['Bisignano', 'A887'],
    ['Bocchigliero', 'A912'],
    ['Bonifati', 'A973'],
    ['Buonvicino', 'B270'],
    ['Calopezzati', 'B424'],
    ['Caloveto', 'B426'],
    ['Campana', 'B500'],
    ['Canna', 'B607'],
    ['Cariati', 'B774'],
    ['Carolei', 'B802'],
    ['Carpanzano', 'B813'],
    ["Cassano all'Ionio", 'C002'],
    ['Castiglione Cosentino', 'C301'],
    ['Castrolibero', 'C108'],
    ['Castroregio', 'C348'],
    ['Castrovillari', 'C349'],
    ['Celico', 'C430'],
    ['Cellara', 'C437'],
    ['Cerchiara di Calabria', 'C489'],
    ['Cerisano', 'C515'],
    ['Cervicati', 'C554'],
    ['Cerzeto', 'C560'],
    ['Cetraro', 'C588'],
    ['Civita', 'C763'],
    ['Cleto', 'C795'],
    ['Colosimi', 'C905'],
    ['Cosenza', 'D086'],
    ['Cropalati', 'D180'],
    ['Crosia', 'D184'],
    ['Diamante', 'D289'],
    ['Dipignano', 'D304'],
    ['Domanico', 'D328'],
    ['Fagnano Castello', 'D464'],
    ['Falconara Albanese', 'D473'],
    ['Figline Vegliaturo', 'D582'],
    ['Firmo', 'D614'],
    ['Fiumefreddo Bruzio', 'D624'],
    ['Francavilla Marittima', 'D764'],
    ['Frascineto', 'D774'],
    ['Fuscaldo', 'D828'],
    ['Grimaldi', 'E180'],
    ['Grisolia', 'E185'],
    ['Guardia Piemontese', 'E242'],
    ['Lago', 'E407'],
    ['Laino Borgo', 'E417'],
    ['Laino Castello', 'E419'],
    ['Lappano', 'E450'],
    ['Lattarico', 'E475'],
    ['Longobardi', 'E677'],
    ['Longobucco', 'E678'],
    ['Lungro', 'E745'],
    ['Luzzi', 'E773'],
    ['Maierà', 'E835'],
    ['Malito', 'E859'],
    ['Malvito', 'E872'],
    ['Mandatoriccio', 'E878'],
    ['Mangone', 'E888'],
    ['Marano Marchesato', 'E914'],
    ['Marano Principato', 'E915'],
    ['Marzi', 'F001'],
    ['Mendicino', 'F125'],
    ['Mongrassano', 'F370'],
    ['Montalto Uffugo', 'F416'],
    ['Montegiordano', 'F519'],
    ['Morano Calabro', 'F708'],
    ['Mormanno', 'F735'],
    ['Mottafollone', 'F775'],
    ['Nocara', 'F907'],
    ['Oriolo', 'G110'],
    ['Orsomarso', 'G129'],
    ['Paludi', 'G298'],
    ['Panettieri', 'G307'],
    ['Paola', 'G317'],
    ['Papasidero', 'G320'],
    ['Parenti', 'G331'],
    ['Paterno Calabro', 'G372'],
    ['Pedivigliano', 'G411'],
    ['Piane Crati', 'G553'],
    ['Pietrafitta', 'G615'],
    ['Pietrapaola', 'G622'],
    ['Plataci', 'G733'],
    ['Praia a Mare', 'G975'],
    ['Rende', 'H235'],
    ['Rocca Imperiale', 'H416'],
    ['Roggiano Gravina', 'H488'],
    ['Rogliano', 'H490'],
    ['Rose', 'H565'],
    ['Roseto Capo Spulico', 'H572'],
    ['Rota Greca', 'H585'],
    ['Rovito', 'H621'],
    ['San Basile', 'H765'],
    ['San Benedetto Ullano', 'H774'],
    ['San Cosmo Albanese', 'H806'],
    ['San Demetrio Corone', 'H818'],
    ['San Donato di Ninea', 'H825'],
    ['San Fili', 'H841'],
    ['Sangineto', 'H877'],
    ['San Giorgio Albanese', 'H881'],
    ['San Giovanni in Fiore', 'H919'],
    ['San Lorenzo Bellizzi', 'H961'],
    ['San Lorenzo del Vallo', 'H962'],
    ['San Lucido', 'H971'],
    ['San Marco Argentano', 'H981'],
    ['San Martino di Finita', 'H992'],
    ['San Nicola Arcella', 'I060'],
    ['San Pietro in Amantea', 'I108'],
    ['San Pietro in Guarano', 'I114'],
    ['San Sosti', 'I165'],
    ['Santa Caterina Albanese', 'I171'],
    ['Santa Domenica Talao', 'I183'],
    ["Sant'Agata di Esaro", 'I192'],
    ['Santa Maria del Cedro', 'C717'],
    ["Santa Sofia d'Epiro", 'I309'],
    ['Santo Stefano di Rogliano', 'I359'],
    ['San Vincenzo La Costa', 'I388'],
    ['Saracena', 'I423'],
    ['Scala Coeli', 'I485'],
    ['Scalea', 'I489'],
    ['Scigliano', 'D290'],
    ["Serra d'Aiello", 'I642'],
    ['Spezzano Albanese', 'I895'],
    ['Spezzano della Sila', 'I896'],
    ['Tarsia', 'L055'],
    ['Terranova da Sibari', 'L124'],
    ['Terravecchia', 'L134'],
    ['Torano Castello', 'L206'],
    ['Tortora', 'L305'],
    ['Trebisacce', 'L353'],
    ['Vaccarizzo Albanese', 'L524'],
    ['Verbicaro', 'L747'],
    ['Villapiana', 'B903'],
    ['Zumpano', 'M202'],
    ['Casali del Manco', 'M385'],
    ['Corigliano-Rossano', 'M403'],
  ],
  CZ: [
    ['Albi', 'A155'],
    ['Amaroni', 'A255'],
    ['Amato', 'A257'],
    ['Andali', 'A272'],
    ['Argusto', 'A397'],
    ['Badolato', 'A542'],
    ['Belcastro', 'A736'],
    ['Borgia', 'B002'],
    ['Botricello', 'B085'],
    ['Caraffa di Catanzaro', 'B717'],
    ['Cardinale', 'B758'],
    ['Carlopoli', 'B790'],
    ['Catanzaro', 'C352'],
    ['Cenadi', 'C453'],
    ['Centrache', 'C472'],
    ['Cerva', 'C542'],
    ['Chiaravalle Centrale', 'C616'],
    ['Cicala', 'C674'],
    ['Conflenti', 'C960'],
    ['Cortale', 'D049'],
    ['Cropani', 'D181'],
    ['Curinga', 'D218'],
    ['Davoli', 'D257'],
    ['Decollatura', 'D261'],
    ['Falerna', 'D476'],
    ['Feroleto Antico', 'D544'],
    ['Fossato Serralta', 'D744'],
    ['Gagliato', 'D852'],
    ['Gasperina', 'D932'],
    ['Gimigliano', 'E031'],
    ['Girifalco', 'E050'],
    ['Gizzeria', 'E068'],
    ['Guardavalle', 'E239'],
    ['Isca sullo Ionio', 'E328'],
    ['Jacurso', 'E274'],
    ['Magisano', 'E806'],
    ['Maida', 'E834'],
    ['Marcedusa', 'E923'],
    ['Marcellinara', 'E925'],
    ['Martirano', 'E990'],
    ['Martirano Lombardo', 'E991'],
    ['Miglierina', 'F200'],
    ['Montauro', 'F432'],
    ['Montepaone', 'F586'],
    ['Motta Santa Lucia', 'F780'],
    ['Nocera Terinese', 'F910'],
    ['Olivadi', 'G034'],
    ['Palermiti', 'G272'],
    ['Pentone', 'G439'],
    ['Petrizzi', 'G517'],
    ['Petronà', 'G518'],
    ['Pianopoli', 'D546'],
    ['Platania', 'G734'],
    ['San Floro', 'H846'],
    ["San Mango d'Aquino", 'H976'],
    ['San Pietro a Maida', 'I093'],
    ['San Pietro Apostolo', 'I095'],
    ['San Sostene', 'I164'],
    ['Santa Caterina dello Ionio', 'I170'],
    ["Sant'Andrea Apostolo dello Ionio", 'I266'],
    ['San Vito sullo Ionio', 'I393'],
    ['Satriano', 'I463'],
    ['Sellia', 'I589'],
    ['Sellia Marina', 'I590'],
    ['Serrastretta', 'I655'],
    ['Sersale', 'I671'],
    ['Settingiano', 'I704'],
    ['Simeri Crichi', 'I745'],
    ['Sorbo San Basile', 'I844'],
    ['Soverato', 'I872'],
    ['Soveria Mannelli', 'I874'],
    ['Soveria Simeri', 'I875'],
    ['Squillace', 'I929'],
    ['Stalettì', 'I937'],
    ['Taverna', 'L070'],
    ['Tiriolo', 'L177'],
    ['Torre di Ruggiero', 'L240'],
    ['Vallefiorita', 'I322'],
    ['Zagarise', 'M140'],
    ['Lamezia Terme', 'M208'],
  ],
  RC: [
    ['Africo', 'A065'],
    ['Agnana Calabra', 'A077'],
    ['Anoia', 'A303'],
    ['Antonimina', 'A314'],
    ['Ardore', 'A385'],
    ['Bagaladi', 'A544'],
    ['Bagnara Calabra', 'A552'],
    ['Benestare', 'A780'],
    ['Bianco', 'A843'],
    ['Bivongi', 'A897'],
    ['Bova', 'B097'],
    ['Bovalino', 'B098'],
    ['Bova Marina', 'B099'],
    ['Brancaleone', 'B118'],
    ['Bruzzano Zeffirio', 'B234'],
    ['Calanna', 'B379'],
    ['Camini', 'B481'],
    ['Campo Calabro', 'B516'],
    ['Candidoni', 'B591'],
    ['Canolo', 'B617'],
    ['Caraffa del Bianco', 'B718'],
    ['Cardeto', 'B756'],
    ['Careri', 'B766'],
    ['Casignana', 'B966'],
    ['Caulonia', 'C285'],
    ['Ciminà', 'C695'],
    ['Cinquefrondi', 'C710'],
    ['Cittanova', 'C747'],
    ['Condofuri', 'C954'],
    ['Cosoleto', 'D089'],
    ['Delianuova', 'D268'],
    ['Feroleto della Chiesa', 'D545'],
    ['Ferruzzano', 'D557'],
    ['Fiumara', 'D619'],
    ['Galatro', 'D864'],
    ['Gerace', 'D975'],
    ['Giffone', 'E025'],
    ['Gioia Tauro', 'E041'],
    ['Gioiosa Ionica', 'E044'],
    ['Grotteria', 'E212'],
    ['Laganadi', 'E402'],
    ['Laureana di Borrello', 'E479'],
    ['Locri', 'D976'],
    ['Mammola', 'E873'],
    ['Marina di Gioiosa Ionica', 'E956'],
    ['Maropati', 'E968'],
    ['Martone', 'E993'],
    ['Melicuccà', 'F105'],
    ['Melicucco', 'F106'],
    ['Melito di Porto Salvo', 'F112'],
    ['Molochio', 'F301'],
    ['Monasterace', 'F324'],
    ['Montebello Jonico', 'D746'],
    ['Motta San Giovanni', 'F779'],
    ['Oppido Mamertina', 'G082'],
    ['Palizzi', 'G277'],
    ['Palmi', 'G288'],
    ['Pazzano', 'G394'],
    ['Placanica', 'G729'],
    ['Platì', 'G735'],
    ['Polistena', 'G791'],
    ['Portigliola', 'G905'],
    ['Reggio di Calabria', 'H224'],
    ['Riace', 'H265'],
    ['Rizziconi', 'H359'],
    ['Roccaforte del Greco', 'H408'],
    ['Roccella Ionica', 'H456'],
    ['Roghudi', 'H489'],
    ['Rosarno', 'H558'],
    ['Samo', 'H013'],
    ['San Giorgio Morgeto', 'H889'],
    ['San Giovanni di Gerace', 'H903'],
    ['San Lorenzo', 'H959'],
    ['San Luca', 'H970'],
    ['San Pietro di Caridà', 'I102'],
    ['San Procopio', 'I132'],
    ['San Roberto', 'I139'],
    ["Santa Cristina d'Aspromonte", 'I176'],
    ["Sant'Agata del Bianco", 'I198'],
    ["Sant'Alessio in Aspromonte", 'I214'],
    ["Sant'Eufemia d'Aspromonte", 'I333'],
    ["Sant'Ilario dello Ionio", 'I341'],
    ['Santo Stefano in Aspromonte', 'I371'],
    ['Scido', 'I536'],
    ['Scilla', 'I537'],
    ['Seminara', 'I600'],
    ['Serrata', 'I656'],
    ['Siderno', 'I725'],
    ['Sinopoli', 'I753'],
    ['Staiti', 'I936'],
    ['Stignano', 'I955'],
    ['Stilo', 'I956'],
    ['Taurianova', 'L063'],
    ['Terranova Sappo Minulio', 'L127'],
    ['Varapodio', 'L673'],
    ['Villa San Giovanni', 'M018'],
    ['San Ferdinando', 'M277'],
  ],
  KR: [
    ['Belvedere di Spinello', 'A772'],
    ['Caccuri', 'B319'],
    ['Carfizzi', 'B771'],
    ['Casabona', 'B857'],
    ['Castelsilano', 'B968'],
    ['Cerenzia', 'C501'],
    ['Cirò', 'C725'],
    ['Cirò Marina', 'C726'],
    ['Cotronei', 'D123'],
    ['Crotone', 'D122'],
    ['Crucoli', 'D189'],
    ['Cutro', 'D236'],
    ['Isola di Capo Rizzuto', 'E339'],
    ['Melissa', 'F108'],
    ['Mesoraca', 'F157'],
    ['Pallagorio', 'G278'],
    ['Petilia Policastro', 'G508'],
    ['Roccabernarda', 'H383'],
    ['Rocca di Neto', 'H403'],
    ['San Mauro Marchesato', 'I026'],
    ["San Nicola dell'Alto", 'I057'],
    ['Santa Severina', 'I308'],
    ['Savelli', 'I468'],
    ['Scandale', 'I494'],
    ['Strongoli', 'I982'],
    ['Umbriatico', 'L492'],
    ['Verzino', 'L802'],
  ],
  VV: [
    ['Acquaro', 'A043'],
    ['Arena', 'A386'],
    ['Briatico', 'B169'],
    ['Brognaturo', 'B197'],
    ['Capistrano', 'B655'],
    ['Cessaniti', 'C581'],
    ['Dasà', 'D253'],
    ['Dinami', 'D303'],
    ['Drapia', 'D364'],
    ['Fabrizia', 'D453'],
    ['Filadelfia', 'D587'],
    ['Filandari', 'D589'],
    ['Filogaso', 'D596'],
    ['Francavilla Angitola', 'D762'],
    ['Francica', 'D767'],
    ['Gerocarne', 'D988'],
    ['Ionadi', 'E321'],
    ['Joppolo', 'E389'],
    ['Limbadi', 'E590'],
    ['Maierato', 'E836'],
    ['Mileto', 'F207'],
    ['Mongiana', 'F364'],
    ['Monterosso Calabro', 'F607'],
    ['Nardodipace', 'F843'],
    ['Nicotera', 'F893'],
    ['Parghelia', 'G335'],
    ['Pizzo', 'G722'],
    ['Pizzoni', 'G728'],
    ['Polia', 'G785'],
    ['Ricadi', 'H271'],
    ['Rombiolo', 'H516'],
    ['San Calogero', 'H785'],
    ['San Costantino Calabro', 'H807'],
    ["San Gregorio d'Ippona", 'H941'],
    ['San Nicola da Crissa', 'I058'],
    ["Sant'Onofrio", 'I350'],
    ['Serra San Bruno', 'I639'],
    ['Simbario', 'I744'],
    ['Sorianello', 'I853'],
    ['Soriano Calabro', 'I854'],
    ['Spadola', 'I884'],
    ['Spilinga', 'I905'],
    ['Stefanaconi', 'I945'],
    ['Tropea', 'L452'],
    ['Vallelonga', 'L607'],
    ['Vazzano', 'L699'],
    ['Vibo Valentia', 'F537'],
    ['Zaccanopoli', 'M138'],
    ['Zambrone', 'M143'],
    ['Zungri', 'M204'],
  ],
  TP: [
    ['Alcamo', 'A176'],
    ['Buseto Palizzolo', 'B288'],
    ['Calatafimi-Segesta', 'B385'],
    ['Campobello di Mazara', 'B521'],
    ['Castellammare del Golfo', 'C130'],
    ['Castelvetrano', 'C286'],
    ['Custonaci', 'D234'],
    ['Erice', 'D423'],
    ['Favignana', 'D518'],
    ['Gibellina', 'E023'],
    ['Marsala', 'E974'],
    ['Mazara del Vallo', 'F061'],
    ['Paceco', 'G208'],
    ['Pantelleria', 'G315'],
    ['Partanna', 'G347'],
    ['Poggioreale', 'G767'],
    ['Salaparuta', 'H688'],
    ['Salemi', 'H700'],
    ['Santa Ninfa', 'I291'],
    ['San Vito Lo Capo', 'I407'],
    ['Trapani', 'L331'],
    ['Valderice', 'G319'],
    ['Vita', 'M081'],
    ['Petrosino', 'M281'],
  ],
  PA: [
    ['Alia', 'A195'],
    ['Alimena', 'A202'],
    ['Aliminusa', 'A203'],
    ['Altavilla Milicia', 'A229'],
    ['Altofonte', 'A239'],
    ['Bagheria', 'A546'],
    ['Balestrate', 'A592'],
    ['Baucina', 'A719'],
    ['Belmonte Mezzagno', 'A764'],
    ['Bisacquino', 'A882'],
    ['Bolognetta', 'A946'],
    ['Bompietro', 'A958'],
    ['Borgetto', 'A991'],
    ['Caccamo', 'B315'],
    ['Caltavuturo', 'B430'],
    ['Campofelice di Fitalia', 'B533'],
    ['Campofelice di Roccella', 'B532'],
    ['Campofiorito', 'B535'],
    ['Camporeale', 'B556'],
    ['Capaci', 'B645'],
    ['Carini', 'B780'],
    ['Castelbuono', 'C067'],
    ['Casteldaccia', 'C074'],
    ['Castellana Sicula', 'C135'],
    ['Castronovo di Sicilia', 'C344'],
    ['Cefalà Diana', 'C420'],
    ['Cefalù', 'C421'],
    ['Cerda', 'C496'],
    ['Chiusa Sclafani', 'C654'],
    ['Ciminna', 'C696'],
    ['Cinisi', 'C708'],
    ['Collesano', 'C871'],
    ['Contessa Entellina', 'C968'],
    ['Corleone', 'D009'],
    ['Ficarazzi', 'D567'],
    ['Gangi', 'D907'],
    ['Geraci Siculo', 'D977'],
    ['Giardinello', 'E013'],
    ['Giuliana', 'E055'],
    ['Godrano', 'E074'],
    ['Gratteri', 'E149'],
    ['Isnello', 'E337'],
    ['Isola delle Femmine', 'E350'],
    ['Lascari', 'E459'],
    ['Lercara Friddi', 'E541'],
    ['Marineo', 'E957'],
    ['Mezzojuso', 'F184'],
    ['Misilmeri', 'F246'],
    ['Monreale', 'F377'],
    ['Montelepre', 'F544'],
    ['Montemaggiore Belsito', 'F553'],
    ['Palazzo Adriano', 'G263'],
    ['Palermo', 'G273'],
    ['Partinico', 'G348'],
    ['Petralia Soprana', 'G510'],
    ['Petralia Sottana', 'G511'],
    ['Piana degli Albanesi', 'G543'],
    ['Polizzi Generosa', 'G792'],
    ['Pollina', 'G797'],
    ['Prizzi', 'H070'],
    ['Roccamena', 'H422'],
    ['Roccapalumba', 'H428'],
    ['San Cipirello', 'H797'],
    ['San Giuseppe Jato', 'H933'],
    ['San Mauro Castelverde', 'I028'],
    ['Santa Cristina Gela', 'I174'],
    ['Santa Flavia', 'I188'],
    ['Sciara', 'I534'],
    ['Sclafani Bagni', 'I541'],
    ['Termini Imerese', 'L112'],
    ['Terrasini', 'L131'],
    ['Torretta', 'L282'],
    ['Trabia', 'L317'],
    ['Trappeto', 'L332'],
    ['Ustica', 'L519'],
    ['Valledolmo', 'L603'],
    ['Ventimiglia di Sicilia', 'L740'],
    ['Vicari', 'L837'],
    ['Villabate', 'L916'],
    ['Villafrati', 'L951'],
    ['Scillato', 'I538'],
    ['Blufi', 'M268'],
  ],
  ME: [
    ['Alcara li Fusi', 'A177'],
    ['Alì', 'A194'],
    ['Alì Terme', 'A201'],
    ['Antillo', 'A313'],
    ['Barcellona Pozzo di Gotto', 'A638'],
    ['Basicò', 'A698'],
    ['Brolo', 'B198'],
    ['Capizzi', 'B660'],
    ["Capo d'Orlando", 'B666'],
    ['Capri Leone', 'B695'],
    ['Caronia', 'B804'],
    ['Casalvecchio Siculo', 'B918'],
    ['Castel di Lucio', 'C094'],
    ["Castell'Umberto", 'C051'],
    ['Castelmola', 'C210'],
    ['Castroreale', 'C347'],
    ['Cesarò', 'C568'],
    ['Condrò', 'C956'],
    ['Falcone', 'D474'],
    ['Ficarra', 'D569'],
    ['Fiumedinisi', 'D622'],
    ['Floresta', 'D635'],
    ['Fondachelli-Fantina', 'D661'],
    ["Forza d'Agrò", 'D733'],
    ['Francavilla di Sicilia', 'D765'],
    ['Frazzanò', 'D793'],
    ['Furci Siculo', 'D824'],
    ['Furnari', 'D825'],
    ['Gaggi', 'D844'],
    ['Galati Mamertino', 'D861'],
    ['Gallodoro', 'D885'],
    ['Giardini-Naxos', 'E014'],
    ['Gioiosa Marea', 'E043'],
    ['Graniti', 'E142'],
    ['Gualtieri Sicaminò', 'E233'],
    ['Itala', 'E374'],
    ['Leni', 'E523'],
    ['Letojanni', 'E555'],
    ['Librizzi', 'E571'],
    ['Limina', 'E594'],
    ['Lipari', 'E606'],
    ['Longi', 'E674'],
    ['Malfa', 'E855'],
    ['Malvagna', 'E869'],
    ['Mandanici', 'E876'],
    ["Mazzarrà Sant'Andrea", 'F066'],
    ['Merì', 'F147'],
    ['Messina', 'F158'],
    ['Milazzo', 'F206'],
    ['Militello Rosmarino', 'F210'],
    ['Mirto', 'F242'],
    ['Mistretta', 'F251'],
    ['Moio Alcantara', 'F277'],
    ['Monforte San Giorgio', 'F359'],
    ['Mongiuffi Melia', 'F368'],
    ['Montagnareale', 'F395'],
    ['Montalbano Elicona', 'F400'],
    ['Motta Camastra', 'F772'],
    ["Motta d'Affermo", 'F773'],
    ['Naso', 'F848'],
    ['Nizza di Sicilia', 'F901'],
    ['Novara di Sicilia', 'F951'],
    ['Oliveri', 'G036'],
    ['Pace del Mela', 'G209'],
    ['Pagliara', 'G234'],
    ['Patti', 'G377'],
    ['Pettineo', 'G522'],
    ['Piraino', 'G699'],
    ['Raccuja', 'H151'],
    ['Reitano', 'H228'],
    ['Roccafiorita', 'H405'],
    ['Roccalumera', 'H418'],
    ['Roccavaldina', 'H380'],
    ['Roccella Valdemone', 'H455'],
    ['Rodì Milici', 'H479'],
    ['Rometta', 'H519'],
    ['San Filippo del Mela', 'H842'],
    ['San Fratello', 'H850'],
    ["San Marco d'Alunzio", 'H982'],
    ['San Pier Niceto', 'I084'],
    ['San Piero Patti', 'I086'],
    ['San Salvatore di Fitalia', 'I147'],
    ['Santa Domenica Vittoria', 'I184'],
    ["Sant'Agata di Militello", 'I199'],
    ["Sant'Alessio Siculo", 'I215'],
    ['Santa Lucia del Mela', 'I220'],
    ['Santa Marina Salina', 'I254'],
    ["Sant'Angelo di Brolo", 'I283'],
    ['Santa Teresa di Riva', 'I311'],
    ['San Teodoro', 'I328'],
    ['Santo Stefano di Camastra', 'I370'],
    ['Saponara', 'I420'],
    ['Savoca', 'I477'],
    ['Scaletta Zanclea', 'I492'],
    ['Sinagra', 'I747'],
    ['Spadafora', 'I881'],
    ['Taormina', 'L042'],
    ['Torregrotta', 'L271'],
    ['Tortorici', 'L308'],
    ['Tripi', 'L431'],
    ['Tusa', 'L478'],
    ['Ucria', 'L482'],
    ['Valdina', 'L561'],
    ['Venetico', 'L735'],
    ['Villafranca Tirrena', 'L950'],
    ['Terme Vigliatore', 'M210'],
    ['Acquedolci', 'M211'],
    ['Torrenova', 'M286'],
  ],
  AG: [
    ['Agrigento', 'A089'],
    ['Alessandria della Rocca', 'A181'],
    ['Aragona', 'A351'],
    ['Bivona', 'A896'],
    ['Burgio', 'B275'],
    ['Calamonaci', 'B377'],
    ['Caltabellotta', 'B427'],
    ['Camastra', 'B460'],
    ['Cammarata', 'B486'],
    ['Campobello di Licata', 'B520'],
    ['Canicattì', 'B602'],
    ['Casteltermini', 'C275'],
    ['Castrofilippo', 'C341'],
    ['Cattolica Eraclea', 'C356'],
    ['Cianciana', 'C668'],
    ['Comitini', 'C928'],
    ['Favara', 'D514'],
    ['Grotte', 'E209'],
    ['Joppolo Giancaxio', 'E390'],
    ['Lampedusa e Linosa', 'E431'],
    ['Licata', 'E573'],
    ['Lucca Sicula', 'E714'],
    ['Menfi', 'F126'],
    ['Montallegro', 'F414'],
    ['Montevago', 'F655'],
    ['Naro', 'F845'],
    ['Palma di Montechiaro', 'G282'],
    ['Porto Empedocle', 'F299'],
    ['Racalmuto', 'H148'],
    ['Raffadali', 'H159'],
    ['Ravanusa', 'H194'],
    ['Realmonte', 'H205'],
    ['Ribera', 'H269'],
    ['Sambuca di Sicilia', 'H743'],
    ['San Biagio Platani', 'H778'],
    ['San Giovanni Gemini', 'H914'],
    ['Santa Elisabetta', 'I185'],
    ['Santa Margherita di Belice', 'I224'],
    ["Sant'Angelo Muxaro", 'I290'],
    ['Santo Stefano Quisquina', 'I356'],
    ['Sciacca', 'I533'],
    ['Siculiana', 'I723'],
    ['Villafranca Sicula', 'L944'],
  ],
  CL: [
    ['Acquaviva Platani', 'A049'],
    ['Bompensiere', 'A957'],
    ['Butera', 'B302'],
    ['Caltanissetta', 'B429'],
    ['Campofranco', 'B537'],
    ['Delia', 'D267'],
    ['Gela', 'D960'],
    ['Marianopoli', 'E953'],
    ['Mazzarino', 'F065'],
    ['Milena', 'E618'],
    ['Montedoro', 'F489'],
    ['Mussomeli', 'F830'],
    ['Niscemi', 'F899'],
    ['Resuttano', 'H245'],
    ['Riesi', 'H281'],
    ['San Cataldo', 'H792'],
    ['Santa Caterina Villarmosa', 'I169'],
    ['Serradifalco', 'I644'],
    ['Sommatino', 'I824'],
    ['Sutera', 'L016'],
    ['Vallelunga Pratameno', 'L609'],
    ['Villalba', 'L959'],
  ],
  EN: [
    ['Agira', 'A070'],
    ['Aidone', 'A098'],
    ['Assoro', 'A478'],
    ['Barrafranca', 'A676'],
    ['Calascibetta', 'B381'],
    ['Catenanuova', 'C353'],
    ['Centuripe', 'C471'],
    ['Cerami', 'C480'],
    ['Enna', 'C342'],
    ['Gagliano Castelferrato', 'D849'],
    ['Leonforte', 'E536'],
    ['Nicosia', 'F892'],
    ['Nissoria', 'F900'],
    ['Piazza Armerina', 'G580'],
    ['Pietraperzia', 'G624'],
    ['Regalbuto', 'H221'],
    ['Sperlinga', 'I891'],
    ['Troina', 'L448'],
    ['Valguarnera Caropepe', 'L583'],
    ['Villarosa', 'M011'],
  ],
  CT: [
    ['Aci Bonaccorsi', 'A025'],
    ['Aci Castello', 'A026'],
    ['Aci Catena', 'A027'],
    ['Acireale', 'A028'],
    ["Aci Sant'Antonio", 'A029'],
    ['Adrano', 'A056'],
    ['Belpasso', 'A766'],
    ['Biancavilla', 'A841'],
    ['Bronte', 'B202'],
    ['Calatabiano', 'B384'],
    ['Caltagirone', 'B428'],
    ['Camporotondo Etneo', 'B561'],
    ['Castel di Iudica', 'C091'],
    ['Castiglione di Sicilia', 'C297'],
    ['Catania', 'C351'],
    ['Fiumefreddo di Sicilia', 'D623'],
    ['Giarre', 'E017'],
    ['Grammichele', 'E133'],
    ['Gravina di Catania', 'E156'],
    ['Licodia Eubea', 'E578'],
    ['Linguaglossa', 'E602'],
    ['Maletto', 'E854'],
    ['Mascali', 'F004'],
    ['Mascalucia', 'F005'],
    ['Militello in Val di Catania', 'F209'],
    ['Milo', 'F214'],
    ['Mineo', 'F217'],
    ['Mirabella Imbaccari', 'F231'],
    ['Misterbianco', 'F250'],
    ["Motta Sant'Anastasia", 'F781'],
    ['Nicolosi', 'F890'],
    ['Palagonia', 'G253'],
    ['Paternò', 'G371'],
    ['Pedara', 'G402'],
    ['Piedimonte Etneo', 'G597'],
    ['Raddusa', 'H154'],
    ['Ramacca', 'H168'],
    ['Randazzo', 'H175'],
    ['Riposto', 'H325'],
    ['San Cono', 'H805'],
    ['San Giovanni la Punta', 'H922'],
    ['San Gregorio di Catania', 'H940'],
    ['San Michele di Ganzaria', 'I035'],
    ['San Pietro Clarenza', 'I098'],
    ["Sant'Agata li Battiati", 'I202'],
    ["Sant'Alfio", 'I216'],
    ['Santa Maria di Licodia', 'I240'],
    ['Santa Venerina', 'I314'],
    ['Scordia', 'I548'],
    ['Trecastagni', 'L355'],
    ['Tremestieri Etneo', 'L369'],
    ['Valverde', 'L658'],
    ['Viagrande', 'L828'],
    ['Vizzini', 'M100'],
    ['Zafferana Etnea', 'M139'],
    ['Mazzarrone', 'M271'],
    ['Maniace', 'M283'],
    ['Ragalna', 'M287'],
  ],
  RG: [
    ['Acate', 'A014'],
    ['Chiaramonte Gulfi', 'C612'],
    ['Comiso', 'C927'],
    ['Giarratana', 'E016'],
    ['Ispica', 'E366'],
    ['Modica', 'F258'],
    ['Monterosso Almo', 'F610'],
    ['Pozzallo', 'G953'],
    ['Ragusa', 'H163'],
    ['Santa Croce Camerina', 'I178'],
    ['Scicli', 'I535'],
    ['Vittoria', 'M088'],
  ],
  SR: [
    ['Augusta', 'A494'],
    ['Avola', 'A522'],
    ['Buccheri', 'B237'],
    ['Buscemi', 'B287'],
    ['Canicattini Bagni', 'B603'],
    ['Carlentini', 'B787'],
    ['Cassaro', 'C006'],
    ['Ferla', 'D540'],
    ['Floridia', 'D636'],
    ['Francofonte', 'D768'],
    ['Lentini', 'E532'],
    ['Melilli', 'F107'],
    ['Noto', 'F943'],
    ['Pachino', 'G211'],
    ['Palazzolo Acreide', 'G267'],
    ['Rosolini', 'H574'],
    ['Siracusa', 'I754'],
    ['Solarino', 'I785'],
    ['Sortino', 'I864'],
    ['Portopalo di Capo Passero', 'M257'],
    ['Priolo Gargallo', 'M279'],
  ],
  SS: [
    ['Aggius', 'A069'],
    ['Alà dei Sardi', 'A115'],
    ['Alghero', 'A192'],
    ['Anela', 'A287'],
    ['Ardara', 'A379'],
    ['Arzachena', 'A453'],
    ['Banari', 'A606'],
    ['Benetutti', 'A781'],
    ['Berchidda', 'A789'],
    ['Bessude', 'A827'],
    ['Bonnanaro', 'A976'],
    ['Bono', 'A977'],
    ['Bonorva', 'A978'],
    ['Bortigiadas', 'B063'],
    ['Borutta', 'B064'],
    ['Bottidda', 'B094'],
    ['Buddusò', 'B246'],
    ['Bultei', 'B264'],
    ['Bulzi', 'B265'],
    ['Burgos', 'B276'],
    ['Calangianus', 'B378'],
    ['Cargeghe', 'B772'],
    ['Castelsardo', 'C272'],
    ['Cheremule', 'C600'],
    ['Chiaramonti', 'C613'],
    ['Codrongianos', 'C818'],
    ['Cossoine', 'D100'],
    ['Esporlatu', 'D441'],
    ['Florinas', 'D637'],
    ['Giave', 'E019'],
    ['Illorai', 'E285'],
    ['Ittireddu', 'E376'],
    ['Ittiri', 'E377'],
    ['Laerru', 'E401'],
    ['La Maddalena', 'E425'],
    ['Luogosanto', 'E747'],
    ['Luras', 'E752'],
    ['Mara', 'E902'],
    ['Martis', 'E992'],
    ['Monteleone Rocca Doria', 'F542'],
    ['Monti', 'F667'],
    ['Mores', 'F721'],
    ['Muros', 'F818'],
    ['Nughedu San Nicolò', 'F975'],
    ['Nule', 'F976'],
    ['Nulvi', 'F977'],
    ['Olbia', 'G015'],
    ['Olmedo', 'G046'],
    ['Oschiri', 'G153'],
    ['Osilo', 'G156'],
    ['Ossi', 'G178'],
    ['Ozieri', 'G203'],
    ['Padria', 'G225'],
    ['Palau', 'G258'],
    ['Pattada', 'G376'],
    ['Perfugas', 'G450'],
    ['Ploaghe', 'G740'],
    ['Porto Torres', 'G924'],
    ['Pozzomaggiore', 'G962'],
    ['Putifigari', 'H095'],
    ['Romana', 'H507'],
    ['Aglientu', 'H848'],
    ['Santa Teresa Gallura', 'I312'],
    ['Sassari', 'I452'],
    ['Sedini', 'I565'],
    ['Semestene', 'I598'],
    ['Sennori', 'I614'],
    ['Siligo', 'I732'],
    ['Sorso', 'I863'],
    ['Tempio Pausania', 'L093'],
    ['Thiesi', 'L158'],
    ['Tissi', 'L180'],
    ['Torralba', 'L235'],
    ["Trinità d'Agultu e Vignola", 'L428'],
    ['Tula', 'L464'],
    ['Uri', 'L503'],
    ['Usini', 'L509'],
    ['Villanova Monteleone', 'L989'],
    ['Valledoria', 'L604'],
    ['Telti', 'L088'],
    ['Badesi', 'M214'],
    ['Viddalba', 'M259'],
    ['Golfo Aranci', 'M274'],
    ['Loiri Porto San Paolo', 'M275'],
    ["Sant'Antonio di Gallura", 'M276'],
    ['Tergu', 'M282'],
    ['Santa Maria Coghinas', 'M284'],
    ['Erula', 'M292'],
    ['Stintino', 'M290'],
    ['Padru', 'M301'],
    ['Budoni', 'B248'],
    ['San Teodoro', 'I329'],
  ],
  NU: [
    ['Aritzo', 'A407'],
    ['Arzana', 'A454'],
    ['Atzara', 'A492'],
    ['Austis', 'A503'],
    ['Bari Sardo', 'A663'],
    ['Baunei', 'A722'],
    ['Belvì', 'A776'],
    ['Birori', 'A880'],
    ['Bitti', 'A895'],
    ['Bolotana', 'A948'],
    ['Borore', 'B056'],
    ['Bortigali', 'B062'],
    ['Desulo', 'D287'],
    ['Dorgali', 'D345'],
    ['Dualchi', 'D376'],
    ['Elini', 'D395'],
    ['Fonni', 'D665'],
    ['Gadoni', 'D842'],
    ['Gairo', 'D859'],
    ['Galtellì', 'D888'],
    ['Gavoi', 'D947'],
    ['Girasole', 'E049'],
    ['Ilbono', 'E283'],
    ['Irgoli', 'E323'],
    ['Jerzu', 'E387'],
    ['Lanusei', 'E441'],
    ['Lei', 'E517'],
    ['Loceri', 'E644'],
    ['Loculi', 'E646'],
    ['Lodè', 'E647'],
    ['Lotzorai', 'E700'],
    ['Lula', 'E736'],
    ['Macomer', 'E788'],
    ['Mamoiada', 'E874'],
    ['Meana Sardo', 'F073'],
    ['Noragugume', 'F933'],
    ['Nuoro', 'F979'],
    ['Oliena', 'G031'],
    ['Ollolai', 'G044'],
    ['Olzai', 'G058'],
    ['Onanì', 'G064'],
    ['Onifai', 'G070'],
    ['Oniferi', 'G071'],
    ['Orani', 'G084'],
    ['Orgosolo', 'G097'],
    ['Orosei', 'G119'],
    ['Orotelli', 'G120'],
    ['Ortueri', 'G146'],
    ['Orune', 'G147'],
    ['Osidda', 'G154'],
    ['Osini', 'G158'],
    ['Ottana', 'G191'],
    ['Ovodda', 'G201'],
    ['Perdasdefogu', 'G445'],
    ['Posada', 'G929'],
    ['Sarule', 'I448'],
    ['Silanus', 'I730'],
    ['Sindia', 'I748'],
    ['Siniscola', 'I751'],
    ['Sorgono', 'I851'],
    ['Talana', 'L036'],
    ['Tertenia', 'L140'],
    ['Teti', 'L153'],
    ['Tiana', 'L160'],
    ['Tonara', 'L202'],
    ['Torpè', 'L231'],
    ['Tortolì', 'A355'],
    ['Triei', 'L423'],
    ['Ulassai', 'L489'],
    ['Urzulei', 'L506'],
    ['Ussassai', 'L514'],
    ['Villagrande Strisaili', 'L953'],
    ['Cardedu', 'M285'],
    ['Lodine', 'E649'],
  ],
  CA: [
    ['Assemini', 'A474'],
    ['Cagliari', 'B354'],
    ['Capoterra', 'B675'],
    ['Decimomannu', 'D259'],
    ['Maracalagonis', 'E903'],
    ['Pula', 'H088'],
    ["Quartu Sant'Elena", 'H118'],
    ['Sarroch', 'I443'],
    ['Selargius', 'I580'],
    ['Sestu', 'I695'],
    ['Settimo San Pietro', 'I699'],
    ['Sinnai', 'I752'],
    ['Uta', 'L521'],
    ['Villa San Pietro', 'I118'],
    ['Quartucciu', 'H119'],
    ['Elmas', 'D399'],
    ['Monserrato', 'F383'],
  ],
  OR: [
    ['Abbasanta', 'A007'],
    ['Aidomaggiore', 'A097'],
    ['Albagiara', 'A126'],
    ['Ales', 'A180'],
    ['Allai', 'A204'],
    ['Arborea', 'A357'],
    ['Ardauli', 'A380'],
    ['Assolo', 'A477'],
    ['Asuni', 'A480'],
    ['Baradili', 'A614'],
    ['Baratili San Pietro', 'A621'],
    ['Baressa', 'A655'],
    ['Bauladu', 'A721'],
    ['Bidonì', 'A856'],
    ['Bonarcado', 'A960'],
    ['Boroneddu', 'B055'],
    ['Busachi', 'B281'],
    ['Cabras', 'B314'],
    ['Cuglieri', 'D200'],
    ['Fordongianus', 'D695'],
    ['Ghilarza', 'E004'],
    ['Gonnoscodina', 'E087'],
    ['Gonnosnò', 'D585'],
    ['Gonnostramatza', 'E088'],
    ['Marrubiu', 'E972'],
    ['Masullas', 'F050'],
    ['Milis', 'F208'],
    ['Mogorella', 'F270'],
    ['Mogoro', 'F272'],
    ['Morgongiori', 'F727'],
    ['Narbolia', 'F840'],
    ['Neoneli', 'F867'],
    ['Norbello', 'F934'],
    ['Nughedu Santa Vittoria', 'F974'],
    ['Nurachi', 'F980'],
    ['Nureci', 'F985'],
    ['Ollastra', 'G043'],
    ['Oristano', 'G113'],
    ['Palmas Arborea', 'G286'],
    ['Pau', 'G379'],
    ['Paulilatino', 'G384'],
    ['Pompu', 'G817'],
    ['Riola Sardo', 'H301'],
    ['Ruinas', 'F271'],
    ['Samugheo', 'H756'],
    ["San Nicolò d'Arcidano", 'A368'],
    ['Santa Giusta', 'I205'],
    ["Villa Sant'Antonio", 'I298'],
    ['Santu Lussurgiu', 'I374'],
    ['San Vero Milis', 'I384'],
    ['Scano di Montiferro', 'I503'],
    ['Sedilo', 'I564'],
    ['Seneghe', 'I605'],
    ['Senis', 'I609'],
    ['Sennariolo', 'I613'],
    ['Siamaggiore', 'I717'],
    ['Siamanna', 'I718'],
    ['Simala', 'I742'],
    ['Simaxis', 'I743'],
    ['Sini', 'I749'],
    ['Siris', 'I757'],
    ['Solarussa', 'I791'],
    ['Sorradile', 'I861'],
    ['Tadasuni', 'L023'],
    ['Terralba', 'L122'],
    ['Tramatza', 'L321'],
    ['Tresnuraghes', 'L393'],
    ['Ulà Tirso', 'L488'],
    ['Uras', 'L496'],
    ['Usellus', 'L508'],
    ['Villanova Truschedu', 'L991'],
    ['Villaurbana', 'M030'],
    ['Villa Verde', 'A609'],
    ['Zeddiani', 'M153'],
    ['Zerfaliu', 'M168'],
    ['Siapiccia', 'I721'],
    ['Curcuris', 'D214'],
    ['Soddì', 'I778'],
    ['Bosa', 'B068'],
    ['Flussio', 'D640'],
    ['Laconi', 'E400'],
    ['Magomadas', 'E825'],
    ['Modolo', 'F261'],
    ['Montresta', 'F698'],
    ['Sagama', 'H661'],
    ['Suni', 'L006'],
    ['Tinnura', 'L172'],
  ],
  SU: [
    ['Arbus', 'A359'],
    ['Armungia', 'A419'],
    ['Ballao', 'A597'],
    ['Barrali', 'A677'],
    ['Barumini', 'A681'],
    ['Buggerru', 'B250'],
    ['Burcei', 'B274'],
    ['Calasetta', 'B383'],
    ['Carbonia', 'B745'],
    ['Carloforte', 'B789'],
    ['Castiadas', 'M288'],
    ['Collinas', 'C882'],
    ['Decimoputzu', 'D260'],
    ['Dolianova', 'D323'],
    ['Domus de Maria', 'D333'],
    ['Domusnovas', 'D334'],
    ['Donori', 'D344'],
    ['Escalaplano', 'D430'],
    ['Escolca', 'D431'],
    ['Esterzili', 'D443'],
    ['Fluminimaggiore', 'D639'],
    ['Furtei', 'D827'],
    ['Genoni', 'D968'],
    ['Genuri', 'D970'],
    ['Gergei', 'D982'],
    ['Gesico', 'D994'],
    ['Gesturi', 'D997'],
    ['Giba', 'E022'],
    ['Goni', 'E084'],
    ['Gonnesa', 'E086'],
    ['Gonnosfanadiga', 'E085'],
    ['Guamaggiore', 'E234'],
    ['Guasila', 'E252'],
    ['Guspini', 'E270'],
    ['Iglesias', 'E281'],
    ['Isili', 'E336'],
    ['Las Plassas', 'E464'],
    ['Lunamatrona', 'E742'],
    ['Mandas', 'E877'],
    ['Masainas', 'M270'],
    ['Monastir', 'F333'],
    ['Muravera', 'F808'],
    ['Musei', 'F822'],
    ['Narcao', 'F841'],
    ['Nuragus', 'F981'],
    ['Nurallao', 'F982'],
    ['Nuraminis', 'F983'],
    ['Nurri', 'F986'],
    ['Nuxis', 'F991'],
    ['Orroli', 'G122'],
    ['Ortacesus', 'G133'],
    ['Pabillonis', 'G207'],
    ['Pauli Arbarei', 'G382'],
    ['Perdaxius', 'G446'],
    ['Pimentel', 'G669'],
    ['Piscinas', 'M291'],
    ['Portoscuso', 'G922'],
    ['Sadali', 'H659'],
    ['Samassi', 'H738'],
    ['Samatzai', 'H739'],
    ['San Basilio', 'H766'],
    ['San Gavino Monreale', 'H856'],
    ['San Giovanni Suergiu', 'G287'],
    ['San Nicolò Gerrei', 'G383'],
    ['San Sperate', 'I166'],
    ['San Vito', 'I402'],
    ['Sanluri', 'H974'],
    ['Santadi', 'I182'],
    ["Sant'Andrea Frius", 'I271'],
    ["Sant'Anna Arresi", 'M209'],
    ["Sant'Antioco", 'I294'],
    ['Sardara', 'I428'],
    ['Segariu', 'I570'],
    ['Selegas', 'I582'],
    ['Senorbì', 'I615'],
    ['Serdiana', 'I624'],
    ['Serramanna', 'I647'],
    ['Serrenti', 'I667'],
    ['Serri', 'I668'],
    ['Setzu', 'I705'],
    ['Seui', 'I706'],
    ['Seulo', 'I707'],
    ['Siddi', 'I724'],
    ['Siliqua', 'I734'],
    ['Silius', 'I735'],
    ['Siurgus Donigala', 'I765'],
    ['Soleminis', 'I797'],
    ['Suelli', 'I995'],
    ['Teulada', 'L154'],
    ['Tratalias', 'L337'],
    ['Tuili', 'L463'],
    ['Turri', 'L473'],
    ['Ussana', 'L512'],
    ['Ussaramanna', 'L513'],
    ['Vallermosa', 'L613'],
    ['Villacidro', 'L924'],
    ['Villamar', 'L966'],
    ['Villamassargia', 'L968'],
    ['Villanova Tulo', 'L992'],
    ['Villanovaforru', 'L986'],
    ['Villanovafranca', 'L987'],
    ['Villaperuccio', 'M278'],
    ['Villaputzu', 'L998'],
    ['Villasalto', 'M016'],
    ['Villasimius', 'B738'],
    ['Villasor', 'M025'],
    ['Villaspeciosa', 'M026'],
  ],
}
